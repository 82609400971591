import {analytics} from "../api/firebase";
import mixpanel from "mixpanel-browser";
export const initMixpanel = () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true, track_pageview: true, persistence: 'localStorage' });
}

export const identifyMixpanel = (id, email, house, organization) => {
    mixpanel.identify(id);
    mixpanel.people.set({email});
    mixpanel.people.set_once('First Login Date', new Date());

    //console.log("identifyMixpanel", id, email, house, organization)
    if(house) {
        mixpanel.set_group("house", [house["name"]])
    }else if(organization) {
        mixpanel.set_group("organization", [organization["name"]])
    }

}

export const logEvent = (name, metadata) => {
    name = `DASHBOARD_${name}`
    analytics.logEvent(name, metadata);
}

