import { createAsyncThunk } from '@reduxjs/toolkit';
import {getHouseConfig, tableOverTimeData} from "../../api"
import {useTempConfig} from "solo-common/src/emotions-recognition-utils";

export const setHouseConfig = createAsyncThunk('house/async-get-config', async (data) => {
    const { houseId } = data
    const config = await getHouseConfig(houseId)
    useTempConfig(config.config)
    return config
})
