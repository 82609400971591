import styled from 'styled-components';

export const OverTimeButton = styled.button`
            background: #909090;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding-left: 13px;
            padding-right: 13px;

            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            width: 98px;
            height: 35px;

            color: #FFFFFF;
            cursor: pointer;
            border: 0;
            outline: 0;
            `
