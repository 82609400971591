import styled from "styled-components";
import { getTrend } from "../../../utils/charts";
import { getAsset } from "../../../utils";

const Minutes = ({ value, item }) => {
    const { happiness, calmness, emotional, seconds, sessions, trend, sad, angry } = item;
    const trendRes = getTrend({ calmness, emotional, happiness, seconds, sessions, sad, angry }, trend)

    return (
        <SimpleValue>
            <div>{value}</div>
            {(value === 0 && trendRes.seconds > 0) || trendRes.seconds === 0 ? (null)
                : trendRes.seconds > 0 ? <Trend src={getAsset("icons/trend_up.svg")} />
                    : <Trend src={getAsset("icons/trend_down.svg")} />}
        </SimpleValue>
    );
}

const SimpleValue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.375em;
  line-height: 1.375em;
  /* identical to box height, or 100% */

  text-align: center;
  color: #2E384D;
  position: relative;
`

const Trend = styled.img`
  width: 0.625em;
  height: 0.625em;
`

export default Minutes