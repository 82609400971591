import moment from "moment"
import { getString } from "../../../utils/translations";
import colors from "../../../styles/colors";
import { get, findIndex } from "lodash";
import {OVER_TIME_BUTTONS, TABLE_COLUMNS} from "../../../constants"
import {
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    WELLBEING_HIGH,
    WELLBEING_MEDIUM,
    MOOD_HIGH,
    MOOD_MEDIUM,
    STRESS_HIGH,
    STRESS_MEDIUM,
    STRESS_LOW,
    YAW_ANGLE_MAX_ALERT_THRESHOLD,
    YAW_ANGLE_MIN_ALERT_THRESHOLD,
    PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,
    YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD
} from "../../../api/remoteConfig"

export const formatMinutes = (duration) => {
    const hrs = Math.floor(duration / 3600)
    const mins = Math.floor((duration % 3600) / 60)
    const secs = Math.floor(duration % 60)
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

export const calcLastSeen = (lastSeenDate, value) => {
    let isToday = false
    const today = new Date();
    if (moment(today).isSame(lastSeenDate, 'day')) {
        isToday = true
    }
    if (value === 1 || !value && !isToday) {
        return `${1} ${getString("day")}`
    } else {
        if (value) {
            return `${value} ${getString("days")}`
        } else {
            return `${getString("today")}`
        }
    }
}

export const normalizedPercentage = (value) => {
    if (value > 1) {
        value = 1
    } else if (value < 0) {
        value = 0
    }
    return Math.round(value * 100)
}

export const calcArrayOfTags = (resTagsObj) => {
    let tagsArr = resTagsObj.map(r => { return { label: get(r, "tag", null), value: get(r, "ResidentTag.TagId", null) } })
    return tagsArr
}

export const getEmotionKey = (emotion) => {
    return `${emotion}`
}

export const isCheckup = (row) => {
    return get(row, "contentType", "") === "checkup"
}

export const getColor = (columnType, value, divided, extraData) => {
    const color = (value, high, medium, reverse, low) => {
        if (value >= high) {
            if (reverse) {
                return colors.tomato
            }
            return colors.interest
        } else if (value < high && value >= medium) {
            return colors.mild
        } else {
            if (low) {
                if (value < medium && value >= low) {
                    if (reverse) {
                        return colors.interest
                    }
                    return colors.tomato
                }
                return colors.warm_grey
            } else {
                if (reverse) {
                    return colors.interest
                }
                return colors.tomato
            }
        }
    }

    const colorInRange = (value, min, max) => {
       // console.log("colorInRange", value, min, max)
        if (value >= max) {
            return colors.tomato
        } else if (value < max && value >= min) {
            return colors.interest
        } else {
            return colors.tomato
        }
    }
    const val = divided ? value : value / 100
 //   console.log("columnType", columnType,  OVER_TIME_BUTTONS.STRESS, val)
    switch (columnType) {
        case OVER_TIME_BUTTONS.ENGAGEMENT:
            return color(val, ENGAGEMENT_HIGH, ENGAGEMENT_MEDIUM)
        case OVER_TIME_BUTTONS.INTEREST:
            return color(val, INTEREST_HIGH, INTEREST_MEDIUM)
        case OVER_TIME_BUTTONS.MOOD:
            return color(val, MOOD_HIGH, MOOD_MEDIUM)
        case OVER_TIME_BUTTONS.WELLBEING:
            return color(val, WELLBEING_HIGH, WELLBEING_MEDIUM)
        case OVER_TIME_BUTTONS.STRESS:
            return color(val, STRESS_HIGH, STRESS_MEDIUM, true, STRESS_LOW)
      //  case OVER_TIME_BUTTONS.YAW_ANGLE:
          //  return colorInRange(val, YAW_ANGLE_MIN_EMOTION_FILTER_THRESHOLD,  YAW_ANGLE_MAX_EMOTION_FILTER_THRESHOLD)
      //  case OVER_TIME_BUTTONS.PITCH_ANGLE:
         //   return colorInRange(val, PITCH_ANGLE_MIN_EMOTION_FILTER_THRESHOLD, PITCH_ANGLE_MAX_EMOTION_FILTER_THRESHOLD)
        case "pricingUnits":

            let date = moment(extraData.date)
            const today = moment()
            if(extraData.type === "month") {
                let dateA = extraData.realDate
                date = moment(dateA)

                if (date.isSame(today, 'month')) {
                    console.log("same", date, today)
                    return colors.red
                }
            }
            else if (date.isSame(today, 'day')) {
                console.log("same day", date, today)
                return colors.red
            }
                // check if extraData.date is current month


        default:
            return colors.blue;
    }
}

export const format = (s) => {
    let m = Math.floor(s / 60);
    m = (m >= 10) ? m : "" + m;
    let ss = Math.floor(s % 60);
    ss = (ss >= 10) ? ss : "0" + ss;
    return m + ":" + ss;
}

export function formatSecondsToTimeString(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    if(hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return `${formattedMinutes}:${formattedSeconds}`;
}

// Example usage

