import styled from "styled-components";
import { getString } from "../../../utils/translations";

const Insufficient = ({ width }) => {
    return (
        <div
            style={{
                position: "absolute",
                width,
                height: 57,
                marginTop: -22,
            }}
        >
            <InsufficientData>{getString("dashboard_insufficient_data")}</InsufficientData>
        </div>
    );
}

const InsufficientData = styled.span`
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #909090;
    margin-top: 5px;
`

export default Insufficient