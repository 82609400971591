import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { getAsset } from "../../utils";

import loader from '../../lotties/robot-meditates.json'
import Lottie from 'react-lottie';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid'
    }
};

class Splash extends Component {
    render() {
        return (
            <Container>
                <Logo src={getAsset("images/solo_logo.svg")} />

                <Lottie
                    options={defaultOptions}
                    width={403}
                    height={"auto"}
                />

                <Slogen>{"PLEASE WAIT\n" +
                    "WHILE I LOAD MYSELF"}</Slogen>
            </Container>
        );
    }
}

const Container = styled.div`
  background: linear-gradient(180deg, #516BE6 19.79%, #BECAFF 100%);
  border-radius: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled.img`
  width: 6em;
  height: 6em;
  position: absolute;
  right: 1.9375em;
  top: 2.75em;
`

const Slogen = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 2.0158em;
  line-height: 1.25em;

  color: #000000;
  white-space:pre-wrap;
  text-align: center;
  margin-top: 1.25em;
`

Splash.propTypes = {};

export default Splash;
