import { SCREENS } from "../constants"

class userTableItem {
    constructor({ userId, name, alerts, tags, lastSeen, sessions, type, seconds, trend,
        wellbeing, energy, valence, energy_ps, valence_ps, stress, engagement, tagContext,
        happiness, calmness, sad, angry, fearful, surprised, disgusted, interest }) {
        this.userId = userId
        this.name = name
        this.alerts = alerts
        this.tags = tags
        this.lastSeen = lastSeen
        this.sessions = sessions
        this.type = type
        this.seconds = seconds
        this.trend = trend
        this.wellbeing = wellbeing
        this.energy = energy
        this.valence = valence
        this.energy_ps = energy_ps
        this.valence_ps = valence_ps
        this.stress = stress
        this.engagement = engagement
        this.happiness = happiness
        this.calmness = calmness
        this.sad = sad
        this.angry = angry
        this.fearful = fearful
        this.surprised = surprised
        this.disgusted = disgusted
        this.interest = interest
        this.tagContext = tagContext
    }
}

class sessionTableItem {
    constructor({ sessionId, userId, comment, seconds, contentType, createdAt,updatedAt, tags,
        wellbeing, stress, engagement, energy, valence, energy_ps, valence_ps, userName,
        happiness, calmness, sad, angry, fearful, surprised, disgusted, interest, unitCount }) {
        this.userName = userName
        this.sessionId = sessionId
        this.tags = tags
        this.userId = userId
        this.createdAt = createdAt
        this.updatedAt = updatedAt
        this.comment = comment
        this.seconds = seconds
        this.contentType = contentType
        this.wellbeing = wellbeing
        this.energy = energy
        this.valence = valence
        this.energy_ps = energy_ps
        this.valence_ps = valence_ps
        this.stress = stress
        this.engagement = engagement
        this.happiness = happiness
        this.calmness = calmness
        this.sad = sad
        this.angry = angry
        this.fearful = fearful
        this.surprised = surprised
        this.disgusted = disgusted
        this.interest = interest
        this.unitCount = unitCount
    }
}

class contentTableItem {
    constructor({ contentId, MediaResourceId, author, createdAt, duration, title, seconds, sessionId, contentType,
        calmness, angry, disgusted, fearful, happiness, sad, surprised, interest,
        energy, valence, stress, wellbeing, engagement,
        japanese, spanish, chinese, malay, arabic, hebrew, russian }) {
        this.contentId = contentId
        this.MediaResourceId = MediaResourceId
        this.angry = angry
        this.author = author
        this.calmness = calmness
        this.createdAt = createdAt
        this.disgusted = disgusted
        this.duration = duration
        this.energy = energy
        this.fearful = fearful
        this.happiness = happiness
        this.title = title
        this.contentType = contentType
        this.sad = sad
        this.seconds = seconds
        this.sessionId = sessionId
        this.stress = stress
        this.surprised = surprised
        this.valence = valence
        this.wellbeing = wellbeing
        this.japanese = japanese
        this.spanish = spanish
        this.chinese = chinese
        this.malay = malay
        this.arabic = arabic
        this.hebrew = hebrew
        this.russian = russian
        this.interest = interest
        this.engagement = engagement
    }
}

export const getTableItem = (item, context) => {
    switch (context) {
        case SCREENS.USERS:
            const user = new userTableItem(item)
            return user;
        case SCREENS.SESSIONS:
            const session = new sessionTableItem(item)
            return session;
        case SCREENS.CONTENT:
            const content = new contentTableItem(item)
            return content;
    }
}
