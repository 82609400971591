import styled from "styled-components";
import colors from "./colors";
import fonts from "./fonts";

export default {
    error: {
        fontFamily: fonts.OpenSans,
        color: colors.red
    }

}

export const ExportText = styled.span`
    text-transform: capitalize;
    font-weight: 400;
`
