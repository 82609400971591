import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {
    TIME_FILTERS, REQUEST_DATE_FORMAT, RESIDENT_TYPE, TAG_ACTIONS, HOUSE_TYPES, SCREENS,
    TAG_FILTERING_MODES, ANNOTATION_TYPE
} from "../../constants";

import firebase from "../../api/firebase"
import {
    getTags,
    exportSessions,
    getResidentId,
    exportTableVideos,
    exportSessionOvertime,
    getAnnotations, isSdk
} from "../../api"

import features from "../../features";

import colors from '../../styles/colors';

import Header from "./Header"
import TopData, {TOP_DATA_MODULES_OPTIONS} from "./TopData";
import TableData from "./TableData";
// import Notification from './Notifications';
import styled from "styled-components";

import Container from 'react-bootstrap/Container'


import SettingsPopup from "./SettingsPopup";
import Splash from "../Splash"

import {getAsset} from "../../utils";
import {load as loadTranslations, translations, setLocale, getString} from "../../utils/translations";
import _, {get, cloneDeep} from "lodash";

import Select from 'react-select';
import {TableLoader} from "../../components/TableLoader";

import Button from "../../components/Button";

import {connect} from "react-redux";
import {setCurrentLanguage, setTranslationsLoaded} from "../../redux/translations";
import {setStartDate, setEndDate, setSelectedTimeFilter} from "../../redux/date";
import {
    setTableData,
    setUserName,
    setShowInsufficientData,
    setAnnotations,
    setAnnotationRelationType, setSearchPlaceholder
} from "../../redux/table"
import {setCurrentScreen, setSessionId, setUserId} from "../../redux/house"

import {getUsersTopData, getUsersTableData} from "../../redux/users/api"
import {getSessionsTopData, getSessionsTableData} from "../../redux/sessions/api"
import {getVideosTopData, getVideosTableData} from "../../redux/videos/api"
import {getContentTopData, getContentTableData} from "../../redux/content/api"

import {getInsufficientDataMode} from "../../api/AsyncStorage"

import {history} from "../../redux/root-reducer"
import {Fragment} from 'react';
import {tableOverTimeData} from "../../api/sdk";
import MultiToggle from "../../components/MultiToggle";
import {getBillingData} from "../../redux/billing/api";
import Billing from "../Billing";
import GroupsMgmt from "../GroupsMgmt";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: true,
            changeHouseOpen: false,
            // residentData: null,///////////
            tagOptions: [],
            selectedTags: [],
            tagFilteringMode: TAG_FILTERING_MODES.OR,
            selectedModule: TOP_DATA_MODULES_OPTIONS.STRESS,
        }

        this.requestedDates = {
            startDate: props.startDate,
            endDate: props.endDate
        }

        this.lastHistory = null;
    }

    async componentDidMount() {
        const {setCurrentLanguage, setTranslationsLoaded, currentHouse, setCurrentScreen} = this.props
        const initialized = await loadTranslations()

        this.handleInsufficientDataMode()
        const {selectedTimeFilter, startDate, endDate, currentScreen, userId, sessionId} = this.props;

        setCurrentLanguage(translations.selectedLocale)
        setTranslationsLoaded(true)

        if (!initialized) {
            console.error("error initialize translations")
        }
        if (this.props.needToSelectHouse) {
            this.openSettings()
        } else {
            this.setDefaultScreen()
            if (features.tags) {
                this.getTagOptions()
                this.getAnnotations()
            }
        }

        if (isSdk()) {
            document.title = getString("sdk_dashboard_slogan")
        }
    }

    setDefaultScreen = () => {
        const {selectedTimeFilter, startDate, endDate, userId, sessionId, currentHouse, setCurrentScreen} = this.props;
        let screen;
        let houseType = get(currentHouse, "type", null);
        console.log("setDefaultScreen", {currentHouse, houseType})
        if (isSdk()) {
            setCurrentScreen(SCREENS.BILLING)
            screen = SCREENS.BILLING
        } else {
            if (houseType === HOUSE_TYPES.upload || houseType === HOUSE_TYPES.realtime_video) {
                setCurrentScreen(SCREENS.VIDEO)
                screen = SCREENS.VIDEO
                document.title = "Video Dashboard"
            } else {
                setCurrentScreen(SCREENS.USERS)
                screen = SCREENS.USERS
            }
        }
        this.pushToHistory({screen, selectedTimeFilter, startDate, endDate, userId, sessionId})

    }

    getRelationId = () => {
        const {annotationType, currentHouse, userId, sessionId} = this.props
        switch (annotationType) {
            case ANNOTATION_TYPE.HOUSE:
                return get(currentHouse, "id", null)
            case ANNOTATION_TYPE.RESIDENT:
                return userId
            case ANNOTATION_TYPE.SESSION:
                return sessionId
            case ANNOTATION_TYPE.VIDEO: //houseId for video
                return get(currentHouse, "id", null)
            default:
                return null
        }
    }

    getAnnotations = async () => {
        try {
            const {setAnnotations, annotationType, setAnnotationRelationType} = this.props

            const relationId = this.getRelationId()
            setAnnotationRelationType(relationId)

            let annotations = await getAnnotations(annotationType, relationId)
            setAnnotations(annotations.data)
        } catch (e) {
            console.log("getAnnotations error", e)
        }
    }

    handleInsufficientDataMode = async () => {
        const {setShowInsufficientData} = this.props
        let insufficientDataMode = await getInsufficientDataMode()
        setShowInsufficientData(insufficientDataMode)
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            selectedTimeFilter, startDate, endDate, setUserName, tagContext,
            currentScreen, userId, sessionId, topData, userName, annotationType
        } = this.props;

        if (!userName && get(topData, "userName", null)) {
            setUserName(topData.userName)
        }

        if (tagContext !== prevProps.tagContext
            && features.tags) {
            this.getTagOptions()
        }

        if (annotationType !== prevProps.annotationType) {
            this.getAnnotations()
        }

        if (selectedTimeFilter !== prevProps.selectedTimeFilter ||
            startDate !== prevProps.startDate ||
            endDate !== prevProps.endDate ||
            currentScreen !== prevProps.currentScreen) {
            this.pushToHistory({screen: currentScreen, selectedTimeFilter, startDate, endDate, userId, sessionId})
        }
    }

    pushToHistory = ({screen, selectedTimeFilter, startDate, endDate, userId, sessionId}) => {

        if (_.isEqual(this.lastHistory, {screen, selectedTimeFilter, startDate, endDate, userId, sessionId})) {
            console.log("same history")
            return
        }

        this.lastHistory = {screen, selectedTimeFilter, startDate, endDate, userId, sessionId}

        const startDateTimeStamp = startDate.valueOf()
        const endDateTimeStamp = endDate.valueOf()


        switch (screen) {
            case SCREENS.USERS:
                history.push(`/users/${selectedTimeFilter}/${startDateTimeStamp}/${endDateTimeStamp}`)
                break;
            case SCREENS.SESSIONS:
                history.push(`/user-sessions/${userId}/${selectedTimeFilter}/${startDateTimeStamp}/${endDateTimeStamp}`)
                break;
            case SCREENS.CONTENT:
                history.push(`/session/${userId}/${sessionId}`)
                break;
            case SCREENS.VIDEO:
                history.push(`/videos`)
                break;
            case SCREENS.BILLING:
                history.push(`/billing`)
                break;
            case SCREENS.GROUP_MGMT:
                history.push(`/groups`)
                break;
            default:
                history.push('/')
                break;
        }

        this.fetchScreenData()
    }

    translate = async () => {
        const {currentLanguage, setCurrentLanguage} = this.props
        currentLanguage === "en" ? setLocale("ja") : setLocale("en")
        setCurrentLanguage(translations.selectedLocale)
    }

    fetchScreenData = () => {
        const {
            startDate,
            endDate,
            selectedTimeFilter
        } = this.props;

        let formattedStartDate = startDate.format(REQUEST_DATE_FORMAT)
        let formattedEndDate = endDate.format(REQUEST_DATE_FORMAT)

        let compareTrendKey = null;
        if ([TIME_FILTERS.TODAY, TIME_FILTERS.WEEK, TIME_FILTERS.MONTH].includes(selectedTimeFilter)) {
            compareTrendKey = selectedTimeFilter
        }

        this.requestedDates = {
            startDate: formattedStartDate,
            endDate: formattedEndDate
        }

        this.getTopData(formattedStartDate, formattedEndDate, compareTrendKey)
        this.getTableData(formattedStartDate, formattedEndDate, compareTrendKey)
    }

    exportSessions = async () => {
        const {
            selectedTags,
            residentData,
            tagFilteringMode
        } = this.state;

        const {startDate, endDate, currentScreen, userId, sessionId} = this.props

        let formattedStartDate = startDate.format(REQUEST_DATE_FORMAT)
        let formattedEndDate = endDate.format(REQUEST_DATE_FORMAT)

        let tags = selectedTags.map(t => t.value)
        try {
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            switch (currentScreen) {
                case SCREENS.USERS:
                    await exportSessions(formattedStartDate, formattedEndDate, tags, timeZone, tagFilteringMode)
                    break;
                case SCREENS.SESSIONS:
                    await exportSessions(formattedStartDate, formattedEndDate, tags, timeZone, tagFilteringMode, userId)
                    break;
                case SCREENS.VIDEO:
                    await exportTableVideos(formattedStartDate, formattedEndDate, timeZone, tags, tagFilteringMode)
                    break;
                case SCREENS.CONTENT:
                    await exportSessionOvertime(sessionId, timeZone)
                    break;
            }
        } catch (e) {
            console.error(e)
        }
    }

    getTagOptions = async () => {
        const {tagContext} = this.props

        let tagOptions = []

        if (tagContext) {
            let res = await getTags(tagContext)
            let tagsArr = res.data || []
            for (let t of tagsArr) {
                tagOptions.push({label: t.tag, value: t.id})
            }
        }

        this.setState({tagOptions})
    }

    verifyResponseDates = (startDate, endDate) => {
        return startDate === this.requestedDates.startDate && endDate === this.requestedDates.endDate
    }

    getTopData = async (startDate, endDate, compareTrendKey) => {
        const {
            residentData,
            selectedTags,
            tagFilteringMode
        } = this.state;

        const {
            getUsersTopData, getSessionsTopData, getContentTopData, getVideosTopData,
            currentScreen, userId, sessionId,
            getBillingData
        } = this.props

        let tags = selectedTags.map(t => t.value)

        switch (currentScreen) {
            case SCREENS.USERS:
                getUsersTopData({startDate, endDate, compareTrendKey, tags, tagFilteringMode})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.SESSIONS:
                getSessionsTopData({startDate, endDate, compareTrendKey, residentId: userId, tags, tagFilteringMode})// residentData.userId
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.CONTENT:
                getContentTopData({startDate, endDate, compareTrendKey, residentId: userId, sessionId})//residentData.userId
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.VIDEO:
                getVideosTopData({startDate, endDate, compareTrendKey, tags, tagFilteringMode})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.BILLING:
                getBillingData({startDate, endDate})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;


        }
    }

    getTableData = async (startDate, endDate, compareTrendKey) => {
        const {
            residentData,
            selectedTags,
            tagFilteringMode
        } = this.state;

        const {
            getUsersTableData, getSessionsTableData, getContentTableData, getVideosTableData,
            houseType, currentScreen, userId, sessionId
        } = this.props

        let tags = selectedTags.map(t => t.value)
        console.log("currentScreen", currentScreen)
        switch (currentScreen) {
            case SCREENS.USERS:
                getUsersTableData({startDate, endDate, compareTrendKey, tags, houseType, tagFilteringMode})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.SESSIONS:
                getSessionsTableData({startDate, endDate, compareTrendKey, residentId: userId, tags, tagFilteringMode})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.CONTENT:
                getContentTableData({startDate, endDate, compareTrendKey, residentId: userId, sessionId})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
            case SCREENS.VIDEO:
                getVideosTableData({startDate, endDate, tags, tagFilteringMode})
                if (!this.verifyResponseDates(startDate, endDate)) {
                    return;
                }
                break;
        }
    }

    selectTimeFilter = async (timeFilter) => {
        const {setStartDate, setEndDate, setSelectedTimeFilter} = this.props
        setStartDate({timeFilter})
        setEndDate({timeFilter})
        setSelectedTimeFilter(timeFilter)
    }

    onDateRangeChange = ({startDate, endDate}) => {
        const {setStartDate, setEndDate, setSelectedTimeFilter} = this.props
        console.log("onDateRangeChange", {startDate, endDate})
        if (!(moment.isMoment(startDate) && moment.isMoment(endDate))) {
            console.log("invalid date")
            return
        }
        setStartDate(startDate.hour(0).minute(0).second(0).millisecond(0))
        setEndDate(endDate.hour(23).minute(59).second(59).millisecond(0))
        setSelectedTimeFilter(TIME_FILTERS.CUSTOM)
    }

    onScreenChange = (data, screen) => {
        const {
            residentData
        } = this.state;

        console.log("onScreenChange", {data, screen})

        const {
            currentHouse, currentScreen, userId,
            setCurrentScreen, setSessionId, setUserId, setSearchPlaceholder
        } = this.props

        if (data === currentScreen || screen == currentScreen
            || get(currentHouse, "type", "") === HOUSE_TYPES.upload
            || get(currentHouse, "type", "") === HOUSE_TYPES.realtime_video) {
            return
        } else if (data === SCREENS.USERS) {
            this.updateScreenState(null)
            setCurrentScreen(SCREENS.USERS)
            setSessionId(null)
            setUserId(null)
        } else if (data === SCREENS.BILLING) {
            console.log("setting billing screen")
            this.selectTimeFilter(TIME_FILTERS.ALL_TIME)
            setCurrentScreen(SCREENS.BILLING)
            setSessionId(null)
            setUserId(null)
            setSearchPlaceholder(getString("search_date"))
            this.updateScreenState(null)
        }else if(data === SCREENS.GROUP_MGMT){
            setCurrentScreen(SCREENS.GROUP_MGMT)
            setSessionId(null)
            setUserId(null)

        }else {
            if (currentScreen === SCREENS.USERS || screen === SCREENS.SESSIONS) {
                if (get(data, "resident", null)) {
                    data = data.resident
                }
                setCurrentScreen(SCREENS.SESSIONS)
                setSessionId(null)
                setUserId(data.userId)
                this.updateScreenState(data)
            }
            if (currentScreen === SCREENS.SESSIONS) {
                setCurrentScreen(SCREENS.CONTENT)
                setSessionId(data.sessionId)
                setUserId(userId)
                this.updateScreenState(
                    residentData)
            }
        }
    }

    updateScreenState = (residentData) => {
        this.setState({
            residentData
        })
    }

    closeSettings = () => {
        this.setState({changeHouseOpen: false})
    }

    openSettings = () => {
        console.log("openSettings")
        this.setState({changeHouseOpen: true})
    }

    onLogout = () => {
        firebase.auth().signOut()
    }

    updateTableData = (rows) => {
        const {setTableData} = this.props
        setTableData(rows)
    }

    filterTags = () => {
        this.fetchScreenData()
    }

    calcTagsMap = (data) => {
        const {tagContext} = this.props
        let tagsMap = {}
        data.forEach(r => {
            let itemTags = get(r, "tags", [])
            itemTags.forEach(t => {
                let tagId = get(t, `${tagContext}Tag.TagId`)
                if (tagsMap[tagId]) {
                    tagsMap[tagId]++
                } else {
                    tagsMap[tagId] = 1
                }
            })
        })
        return tagsMap;
    }

    updateTags = (id, value, action) => {
        const {contextId, tagContext} = this.props
        const data = cloneDeep(get(this.props, "tableData", []))
        const itemIndex = data.findIndex(r => get(r, contextId, null) === id)

        let itemTags = cloneDeep(get(data[itemIndex], "tags", []))

        let {tagOptions, tagsMap} = this.state
        const {setTableData} = this.props

        let updatedTagOptions
        switch (action) {
            case TAG_ACTIONS.ADD: {
                if (itemTags) {
                    let addedTagItem = {}
                    addedTagItem["tag"] = value.label
                    addedTagItem[`${tagContext}Tag`] = {TagId: value.value}
                    itemTags.push(addedTagItem)
                    data[itemIndex].tags = itemTags
                    setTableData(data)
                }
                updatedTagOptions = tagOptions
                if (!updatedTagOptions.find(tagOption => tagOption.label === value.label)) {
                    updatedTagOptions.push({label: value.label, value: value.value})
                    this.setState({tagOptions: updatedTagOptions})
                }

                const updatedTagsMap = this.calcTagsMap(data)

                this.setState({tagsMap: updatedTagsMap})
                break;
            }
            case TAG_ACTIONS.REMOVE: {
                if (itemTags) {
                    itemTags = itemTags.filter(t => t.tag !== value.label)
                    data[itemIndex].tags = itemTags
                    setTableData(data)
                }

                if (!get(tagsMap, "[value.value]", null)) {
                    updatedTagOptions = tagOptions.filter(t => t.label !== value.label)
                    this.setState({tagOptions: updatedTagOptions})
                } else {
                    tagsMap[value.value]--
                    if (tagsMap[value.value] === 0) {
                        updatedTagOptions = tagOptions.filter(t => t.label !== value.label)
                        this.setState({tagOptions: updatedTagOptions})
                    }
                }

                this.setState({tagsMap})
                break;
            }
        }


    }

    hasOvertimeFilters = () => {
        const {currentScreen} = this.props
        if (currentScreen === SCREENS.CONTENT) {
            return false
        }
        return true
    }

    handleTagFilteringMode = (mode) => {
        this.setState({tagFilteringMode: mode})
    }

    renderTagOptions = (option, text) => {
        const {tagFilteringMode} = this.state
        return (<Fragment>
                {tagFilteringMode === option ? <OptionCircle src={getAsset("icons/full_circle.svg")}/>
                    : <OptionCircle src={getAsset("icons/empty_circle.svg")}
                                    onClick={() => this.handleTagFilteringMode(option)}/>
                }
                {text}
            </Fragment>
        )
    }

    renderBody = () => {

        const {
            loadingTable,
            currentScreen,
            hasTagsInput,
            exportsData,
            startDate,
            endDate
        } = this.props

        const {
            tagOptions,
        } = this.state

        console.log("renderBody", currentScreen)

        switch (currentScreen) {
            case SCREENS.BILLING:
                return (<Billing startDate={startDate} endDate={endDate}/>)
            case SCREENS.GROUP_MGMT:
                console.log("group management")
                return (<GroupsMgmt/>)
            default:
                return (
                    <>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: 60
                        }}>
                            <MultiToggle options={[
                                {label: getString("stress"), value: TOP_DATA_MODULES_OPTIONS.STRESS},
                                {label: getString("wellbeing"), value: TOP_DATA_MODULES_OPTIONS.WELLBEING},
                                {label: getString("mood"), value: TOP_DATA_MODULES_OPTIONS.MOOD},
                                {label: getString("energy"), value: TOP_DATA_MODULES_OPTIONS.ENERGY},
                            ]}
                                         onChange={(value) => {
                                             this.setState({selectedModule: value})
                                             // this.onEmotionClick(value)
                                             // this.onOverTimeFilterClick(value)
                                         }}
                                         value={this.state.selectedModule}

                            />

                            <div
                                style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>

                                {hasTagsInput && <div style={{flexDirection: 'column'}}>
                                    <TagFilterContainer>
                                        <div style={{width: 250}}>
                                            <Select
                                                isMulti
                                                name="colors"
                                                options={tagOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                placeholder={getString("select_filters")}
                                                onChange={(value, action) => {
                                                    this.setState({selectedTags: value})
                                                }}
                                            />
                                        </div>
                                        <ApplyButton onClick={this.filterTags}>
                                            <ApplyText>{getString("apply")}</ApplyText>
                                        </ApplyButton>
                                    </TagFilterContainer>

                                    <TagOptions>
                                        <TagOptionContainer style={{marginLeft: 2}}>
                                            {this.renderTagOptions(TAG_FILTERING_MODES.OR, getString("or_option"))}
                                        </TagOptionContainer>
                                        <TagOptionContainer style={{marginLeft: 7}}>
                                            {this.renderTagOptions(TAG_FILTERING_MODES.AND, getString("and_option"))}
                                        </TagOptionContainer>
                                    </TagOptions>
                                </div>}

                                {features.exportList && exportsData && (
                                    <Button
                                        style={printButtonStyle}
                                        onClick={this.exportSessions}>
                                        {/* <PrintIcon src={getAsset("icons/printer.svg")} /> */}
                                        <span>{getString("export_sessions")}</span>
                                    </Button>
                                )}

                            </div>
                        </div>

                        <div>
                            <TopData
                                context={currentScreen}
                                loadingCharts={loadingTable}
                                hasOvertimeFilters={this.hasOvertimeFilters()}
                                selectedModule={this.state.selectedModule}
                            />
                        </div>
                        <div>
                            {loadingTable ?
                                <TableLoader/> : (
                                    <TableData
                                        onScreenChange={this.onScreenChange}
                                        currentScreen={currentScreen}
                                        updateTableData={this.updateTableData}
                                        updateTags={this.updateTags}
                                    />)}
                        </div>
                    </>
                )
        }


    }

    render() {
        const {
            houseName,
            houses,
            houseChangeEnabled,
            currentHouse,
            translationsLoaded,
            currentOrganization,
            needToSelectHouse,
            currentScreen,
            hasDateFilter,
            sessionName,
            userName,
        } = this.props

        const {
            changeHouseOpen,
            residentData,
        } = this.state

        if (!translationsLoaded) {
            return <Splash/>
        }

        return (
            <div>
                {/* <Notification /> */}
                <Header
                    onDateRangeChange={this.onDateRangeChange}
                    onTimeFilterChange={this.selectTimeFilter}
                    onLogout={this.onLogout}
                    onSettings={this.openSettings}
                    isClickable={hasDateFilter}
                    onScreenChange={this.onScreenChange}
                    translate={this.translate}
                    context={currentScreen}
                />
                <DashboardContainer>

                    <Container fluid={true}>

                        <Title>
                            <ClickableBreadcrumb onClick={() => this.onScreenChange(SCREENS.USERS)}>
                                <HomeIcon src={getAsset("icons/home_gray.svg")}/>
                                {houseName}
                                {currentScreen === SCREENS.BILLING ? ` (${getString("billing")})` : ""}
                            </ClickableBreadcrumb>
                            {userName &&
                                <ClickableBreadcrumb
                                    onClick={() => this.onScreenChange(residentData, SCREENS.SESSIONS)}>
                                    < BreadcrumbsArrow/>
                                    {userName}
                                </ClickableBreadcrumb>}
                            {sessionName && <span>
                                <BreadcrumbsArrow/>
                                {sessionName}</span>}
                        </Title>

                        {this.renderBody()}


                    </Container>
                </DashboardContainer>


                <SettingsPopup
                    isOpen={changeHouseOpen}
                    closeHandler={this.closeSettings}
                    onLogout={this.onLogout}
                    user={this.props.user}
                    houses={houses}
                    currentHouse={currentHouse}
                    translate={this.translate}
                    currentOrganization={currentOrganization}
                    needToSelectHouse={needToSelectHouse}
                    houseChangeEnabled={houseChangeEnabled}
                    changeScreen={this.onScreenChange}
                    currentScreen={currentScreen}
                    setDefaultScreen={this.setDefaultScreen}
                />


            </div>
        );
    }
}

const mapStateToProps = ({translations, date, table, top, house}) => ({
    exportsData: table.exportsData,
    currentHouse: house.currentHouse,
    currentScreen: house.currentScreen,
    userId: house.userId,
    userName: table.userName,
    hasDateFilter: table.hasDateFilter,
    sessionId: house.sessionId,
    sessionName: table.sessionName,
    currentLanguage: translations.currentLanguage,
    translationsLoaded: translations.translationsLoaded,
    selectedTimeFilter: date.selectedTimeFilter,
    startDate: date.startDate,
    endDate: date.endDate,
    loadingTable: table.loadingTable,
    tableData: table.tableData,
    loadingTopData: top.loadingTopData,
    topData: top.topData,
    hasTagsInput: table.hasTagsInput,
    tagContext: table.tagContext,
    contextId: table.contextId,
    annotationType: table.annotationType,
    relationId: table.relationId
});

const mapDispatchToProps = {
    setAnnotations,
    setAnnotationRelationType,
    setCurrentLanguage,
    setTranslationsLoaded,
    setStartDate,
    setEndDate,
    setSelectedTimeFilter,
    setTableData,
    getUsersTopData,
    getSessionsTopData,
    getVideosTopData,
    getContentTopData,
    getUsersTableData,
    getSessionsTableData,
    getVideosTableData,
    getContentTableData,
    setCurrentScreen,
    setSessionId,
    setSearchPlaceholder,
    setUserId,
    setUserName,
    setShowInsufficientData,
    getBillingData
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

Dashboard.propTypes = {
    houseName: PropTypes.string,
    houses: PropTypes.array,
    houseChangeEnabled: PropTypes.bool,
    currentHouse: PropTypes.object
};

const printButtonStyle = {
    marginLeft: 11,
    background: colors.lipstick,
    paddingLeft: 15,
    paddingRight: 15,
    height: 35,
    borderRadius: 10,
    textAlign: 'left',
    fontWeight: 'bold',
}

const DashboardContainer = styled.div`
    background-color: #f4f6fc;
    padding: 2.4375em 3.625em;
    min-height: calc(100vh - 10em);
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    margin-top: 5em;

    @media print {
        padding: 12px;
        margin-top: 0;
    }

    @media (max-width: 1200px) {
        padding -left: 1.25em;
        padding-right: 1.25em;
    }

    @media (max-width: 1086px) {
        padding -left: 0.625em;
        padding-right: 0.625em;
    }
`

const Title = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 2.125em;
    line-height: 2em;
    margin-top: 0.15em;
    /* identical to box height, or 94% */


    color: #2E384D;
    margin-bottom: 15px;

    @media print {
        margin -top: 10px;
        margin-bottom: 12px;
    }
`

const AppContainer = styled.div`
    height: 100%;
    display: flex;
    position: relative;
`

const OptionCircle = styled.img`
    margin-right: 3px;
    cursor: pointer;
`

const TagOptionContainer = styled.div`
    display: flex;
    justify-content: center;
`

const TagOptions = styled.div`
    position: absolute;
    display: flex;
    margin-top: 5px;
    color: #8798AD;
`

const PrintIcon = styled.img`
    width: 1.0475em;
    height: 1em;
    margin-left: -0.3125em;
    margin-right: 0.75em;
    //margin-top: 0.32em;
`

const BreadcrumbsArrow = styled.img.attrs({
    src: getAsset("icons/arrow_orange.svg")
})`
    margin-left: 0.5em;
    margin-right: 0.7em;
`

const HomeIcon = styled.img`
    margin-right: 0.25em;
`

const ClickableBreadcrumb = styled.span`
    cursor: pointer;
`

const TagFilterContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const ApplyButton = styled.div`
    background: #536EEC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 75px;
    height: 35px;
    margin-left: 10px;
    cursor: pointer;
`

const ApplyText = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
`
