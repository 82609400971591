import { createSlice } from '@reduxjs/toolkit';
import { getBillingData } from "./api";
import {BILLING_UNIT_TYPES} from "../../constants";

export const billingSlice = createSlice({
    name: 'billing',
    initialState: {
        loading: false,
        monthlyBilling: null,
        dailyBilling: null,
        totalBilling: {
            avg: 0,
            sessions: 0,
            seconds: 0,
        },
        unitType: BILLING_UNIT_TYPES.AI_UNIT,
    },
    reducers: {

    },
    extraReducers: {
        [getBillingData.pending]: (state, { payload }) => {
            console.log("saveVideoData.pending")
            state.loading = true;
        },
        [getBillingData.rejected]: (state, { payload }) => {
            console.log("saveVideoData.rejected")
            state.loading = false;
        },
        [getBillingData.fulfilled]: (state, { payload }) => {
            console.log("getBillingData.fulfilled")
            state.loading = false;
            state.monthlyBilling = payload.month.map(item => {
                let rowASplit = item.date.split(".")
                let dateA = new Date()
                dateA.setDate(1)
                dateA.setMonth(Number(rowASplit[0]) - 1)
                dateA.setFullYear(2000 + Number(rowASplit[1]))
                dateA.setHours(0, 0, 0, 0)
                return {...item,
                    type: "month",
                    realDate: dateA,
                }
            })
            state.dailyBilling = payload.day
            state.totalBilling = payload.total
            state.unitType = payload.unitType
        },
    }
});

const { actions, reducer } = billingSlice;

export default reducer;
