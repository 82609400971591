import React, { Component } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';
import colors from "../../../styles/colors"
import { getString } from "../../../utils/translations";

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={colors.black}
                font-weight="900" font-size="xx-large">
                {getString(payload.name)}
            </text>
            <text x={cx} y={cy} dy={23} textAnchor="middle" fill={colors.black} font-size="x-large">
                {`${payload.value}%`}
            </text>
            <text x={cx} y={cy} dy={53} textAnchor="middle" fill={colors.black} font-size="large">
                {`(${payload.total})`}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const RADIAN = Math.PI / 180;


export default class PieMeter extends Component {
    state = {
        activeIndex: 0,
        label: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentLanguage !== this.props.currentLanguage && this.props.useFullPie) {

        }
    }

    onPieEnter = (_, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        const title = getString(this.props.data[index].name);
        const value = `${(percent * 100).toFixed(0)}%`;

        // function to estimate element height based on font size
        const estimateHeight = (fontSize) => {
            return fontSize * 1.2;
        }

        let fontSize = 15;
        let height = estimateHeight(fontSize) * 2;
        let centeredY = y - height / 2 ;

        let style = {fontSize, fontWeight: "bold"};
        return (

            <text x={x} y={centeredY} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                <tspan style={style} x={x} textAnchor='middle' dy="0em">{value}</tspan>
                <tspan style={style} x={x} textAnchor='middle' dy="1em">{title}</tspan>
            </text>
        );
    }


    render() {
        const { data, useFullPie, currentLanguage } = this.props;
        console.log("pie render", currentLanguage)
        return (
            data && data.length ? <ResponsiveContainer key={currentLanguage} width="100%" height="100%">
                <PieChart width={361} height={361}>
                    {useFullPie ? (
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={130}
                            labelLine={false}
                            fill={colors.blue}
                            dataKey="value"
                            label={this.renderCustomizedLabel}
                            paddingAngle={0}
                        >
                            {data.map((entry, index) => {
                                return < Cell key={`cell-${index}`} fill={entry.color} />
                            })}
                        </Pie>
                    ) : (
                        <Pie
                            activeIndex={this.state.activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={95}
                            outerRadius={130}
                            fill={colors.blue}
                            dataKey="value"
                            onMouseEnter={this.onPieEnter}
                            paddingAngle={3}
                        >
                            {data.map((entry, index) => {
                                return < Cell key={`cell-${index}`} fill={entry.color} />
                            })}
                        </Pie>
                    )}

                </PieChart>
            </ResponsiveContainer> :
                <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                    {getString("empty_overtime_chart")}</div>
        );
    }
}
