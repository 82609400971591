import { getString } from "../../utils/translations";
import colors from "../../styles/colors";
import { RESIDENT_TYPE, HOUSE_TYPES, COLUMN_VALUE_OPTION } from "../../constants";
import _, { get, findIndex } from "lodash";
import { OVER_TIME_MIN_SUFFICIENT_PERCENT } from "../../api/remoteConfig";

const mockData = [{ //session data
    name: "John - Interview",//not in session data,
    videoId: 1,
    angry: 0.00239623,
    calmness: 0.051644,
    comment: null,
    contentType: "smile",
    createdAt: "2021-11-16T18:33:32+02:00",
    disgusted: 0.00159598,
    energy: 0.445747,
    energy_ps: 0.445747,
    fearful: 0.000798325,
    happiness: 0.0590838,
    sad: 0.0959398,
    seconds: 2477,
    sessionId: 43031,
    stress: 0.04220883350000001,
    sufficientData: true,
    surprised: 0,
    userId: 112,
    valence: 0.501223,
    valence_ps: 0.501223,
    wellbeing: 0.0018461359999999843,
    engagement: 0.501223, //not in session data
    interest: 0.501223,//not in session data
}]

export const modifyTableData = (payload, columnValue, currentHouse, activeHouse) => {

    let modified = JSON.parse(JSON.stringify(payload));
    modified = handleInsufficientData(modified,  currentHouse === HOUSE_TYPES.realtime_video ? "video" : columnValue)
    modified = handleUserTypes(modified)

    if (activeHouse && currentHouse && (currentHouse === HOUSE_TYPES.upload || currentHouse === HOUSE_TYPES.realtime_video)) {
        modified = handleUploadButton(modified)
    }

    return modified
}

export const handleInsufficientData = (payload, columnValue) => {
    payload.forEach((item) => {
        let sufficient = calcSufficientData(item, columnValue)
        item.sufficientData = sufficient
        return item
    })
    return payload
}

export const filterInsufficientData = (payload) => {
    let data = handleInsufficientData(payload)
    return data.filter(item => item.sufficientData)
}

export const calcSufficientData = (item, context) => {
    let sufficientData

    if(context === "video"){
      //  console.log("calcSufficientData", context, {item})
        return true
    }

    if (context === COLUMN_VALUE_OPTION.METER) {

        const currentStress = normalizedPercentage(get(item, "stress", 0))
        const currentWellbeingt = normalizedPercentage(get(item, "wellbeing", 0))
        const currentMood = normalizedPercentage(get(item, "mood", get(item, "valence", 0)))
        const currentEnergy = normalizedPercentage(get(item, "energy", 0))

        sufficientData = currentStress + currentWellbeingt + currentMood + currentEnergy < OVER_TIME_MIN_SUFFICIENT_PERCENT ? false : true
       // console.log("calcSufficientData", {item, currentStress, currentWellbeingt, currentMood, currentEnergy, OVER_TIME_MIN_SUFFICIENT_PERCENT})
    } else {

        let happinessKey = getEmotionKey("happiness");
        let calmnessKey = getEmotionKey("calmness");
        let sadKey = getEmotionKey("sad");
        let angryKey = getEmotionKey("angry");

        const currentHappy = normalizedPercentage(get(item, happinessKey, 0))
        const currentCalm = normalizedPercentage(get(item, calmnessKey, 0))
        const currentSad = normalizedPercentage(get(item, sadKey, 0))
        const currentAngry = normalizedPercentage(get(item, angryKey, 0))

        //console.log("calcSufficientData", {item, currentHappy, currentCalm, currentSad, currentAngry})

        sufficientData = currentHappy + currentCalm + currentSad + currentAngry < OVER_TIME_MIN_SUFFICIENT_PERCENT ? false : true
    }

    return sufficientData
}

const getEmotionKey = (emotion) => {
    return `${emotion}`//${"_ps"}
}

const normalizedPercentage = (value) => {
    if (value > 1) {
        value = 1
    } else if (value < 0) {
        value = 0
    }
    return Math.round(value * 100)
}

const handleUserTypes = (payload) => {
    let groupIndex = findIndex(payload, (item) => { return get(item, "type", "") === RESIDENT_TYPE.GROUP });
    let careIndex = findIndex(payload, (item) => { return get(item, "type", "") === RESIDENT_TYPE.CARE });

    if (groupIndex !== -1) {
        payload[groupIndex] = groupActivityAttributes(payload[groupIndex])
    }

    if (careIndex !== -1) {
        payload[careIndex] = caregiverAttributes(payload[careIndex])
    }

    return payload
}

const handleUploadButton = (payload) => {
    const updated = _.cloneDeep(payload)

    updated.push({
        id: "btn",
        name: getString("upload_file"),
        bgcolor: colors.lipstick,
        icon: "icons/upload_icon.svg",
    })

    updated.push({
        id: "btn-stream",
        name: getString("stream_video"),
        bgcolor: colors.lipstick,
        icon: "icons/box-camera.svg",
    })

    return updated
}

const caregiverAttributes = (caregiver) => {
    caregiver.name = "caregiver"
    caregiver.bgcolor = colors.yellowish
    caregiver.icon = "icons/caregiver.svg"
    caregiver.pinIndex = 1
    return caregiver
}

const groupActivityAttributes = (groupActivity) => {
    groupActivity.name = "group_activity"
    groupActivity.bgcolor = colors.lipstick
    groupActivity.icon = "icons/group_icon.svg"
    groupActivity.pinIndex = 0
    return groupActivity
}

