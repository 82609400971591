import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {CLIENT_APP_TYPES} from "../../constants";
import {getString} from "../../utils/translations";
import Popup from "../Popup";
import loader from "../../lotties/loaderhouse.json";
import {ValueBoxSecondary, ValueBoxSecondaryInput, ValueBoxMain} from "./styledComponents";
import {generateAPIKey} from "../../api";
import {addClientApp} from "../../redux/organization"

const CREATE_APP_STEPS = {
    SELECT_APP_TYPE: "SELECT_APP_TYPE",
    SET_NAME: "SET_NAME",
    REVIEW: "REVIEW",
}

export default function CreateApp({isOpen, closeHandler, onCancel, appLevelUnitsLimitEnabled}) {
    const [appType, setAppType] = React.useState(null);
    const [name, setName] = React.useState("");
    const [step, setStep] = React.useState(CREATE_APP_STEPS.SELECT_APP_TYPE);
    const [loading, setLoading] = React.useState(false);
    const [description, setDescription] = React.useState("create_sdk_app_description");
    const [error, setError] = React.useState(null);
    const [apiKey, setApiKey] = React.useState(null);
    const [appId, setAppId] = React.useState(null);
    const [title, setTitle] = React.useState("create_sdk_app");
    const [unitsLimit, setUnitsLimit] = React.useState(null);

    const dispatch = useDispatch();


    useEffect(() => {
        if(appType){
            setDescription("set_sdk_app_name")
            setName(`${appType} App`)
            setStep(CREATE_APP_STEPS.SET_NAME)
        }
    }, [appType]);
    const handleCreateApp = async () => {
        try {
            setLoading(true);
            setError(null);
            let res = await generateAPIKey({clientAppType: appType.toLowerCase(), appName: name, unitsLimit})
            setLoading(false)

            if (res && res.data) {
                let clientApp = {...res.data}
                clientApp.appClient = appType
                clientApp.name = name
                if(unitsLimit){
                    clientApp.AIUnitsLimits = [{unitsLimit}]
                }

                dispatch(addClientApp(clientApp))
                setAppId(res.data.appId)
                setApiKey(res.data.apiKey)
                setTitle("api_key")
                setDescription("api_key_description")
                setStep(CREATE_APP_STEPS.REVIEW);
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
            setError("failed to generate api key for app")
        }

    };


    const toRender = (
        <>
            {!appType && (<AppTypes onSelectAppType={setAppType}/>)}

            {appType && (
                <ValueBoxSecondaryInput autoFocus value={name} onChange={e => setName(e.target.value)}/>
            )}
            {step === CREATE_APP_STEPS.REVIEW && (
                <>
                    <ValueBoxMain>APP ID:</ValueBoxMain>
                    <ValueBoxSecondary>{appId}</ValueBoxSecondary>

                    <ValueBoxMain>API KEY:</ValueBoxMain>
                    <ValueBoxSecondary>{apiKey}</ValueBoxSecondary>
                </>
            )}
            {appType && appLevelUnitsLimitEnabled && (
                <>
                    <ValueBoxMain>{getString("units_limit")}</ValueBoxMain>
                    <ValueBoxSecondaryInput type={"number"} value={unitsLimit} onChange={e => setUnitsLimit(e.target.value)}/>
                </>

            )}
        </>
    )

    const getStepButton = () => {
        switch (step) {
            case CREATE_APP_STEPS.SELECT_APP_TYPE:
                return [{label: getString("close"), onPress: onCancel}]
            case CREATE_APP_STEPS.SET_NAME:
                return [{label: getString("continue"), onPress: handleCreateApp}]
            case CREATE_APP_STEPS.REVIEW:
                return [{label: getString("done"), onPress: closeHandler}]
            default:
                return null;
        }
    }

    return <Popup
        isOpen={isOpen}
        closeHandler={closeHandler}
        title={getString(title)}
        closeOnDocumentClick={false}
        description={getString(description)}
        content={toRender}
        error={error}
        footerButtons={getStepButton()}
        loading={loading}
        loader={loader}
        showAll={false}
    />
}

const AppTypes = ({onSelectAppType}) => {
    return (
        <div>
            {Object.values(CLIENT_APP_TYPES).map(appType => (
                <ValueBoxMain key={appType} onClick={() => onSelectAppType(appType)}>
                    {appType}
                </ValueBoxMain>
            ))}
        </div>
    );
}
