import { createSlice } from '@reduxjs/toolkit';
import { TIME_FILTERS } from "../../constants";
import { DASHBOARD_TIME_FILTER } from "../../api/remoteConfig";
import { getDateRange, getStartDate } from "./utils";
const { requestStartDate, requestEndDate } = getDateRange(TIME_FILTERS[DASHBOARD_TIME_FILTER])

export const dateSlice = createSlice({
    name: 'date',
    initialState: {
        selectedTimeFilter: TIME_FILTERS[DASHBOARD_TIME_FILTER],
        startDate: requestStartDate,
        endDate: requestEndDate,
    },
    reducers: {
        setStartDate: (state, action) => {
            const startDate = getStartDate(action.payload)
            state.startDate = startDate
        },
        setEndDate: (state, action) => {
            let endDate;
            if(action.payload.timeFilter){
                const { requestEndDate } = getDateRange(action.payload.timeFilter)
                endDate = requestEndDate;
            }else{
                endDate = action.payload
            }
            state.endDate = endDate
        },
        setSelectedTimeFilter: (state, action) => {
            state.selectedTimeFilter = action.payload
        }
    },
});

const { actions, reducer } = dateSlice;

export const { setStartDate, setEndDate, setSelectedTimeFilter } = actions;
export default reducer;
