import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBillingSessions } from "../../api"


export const getBillingData = createAsyncThunk('billing/get-billing-data', async (data) => {
    const { startDate, endDate, clientAppId} = data
    const res = await getBillingSessions(startDate, endDate, clientAppId)
    const parsedData = (res.data)
    return {...parsedData,startDate, endDate}
})

