import {routes as sdkRoutes} from "./sdk";
import {routes as soloRoutes} from "./solo";

export let apiRoutes;
let app = process.env.REACT_APP_ENV;
if (app && app.includes("sdk")) {
    apiRoutes = sdkRoutes
}else {
    apiRoutes = soloRoutes
}

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

// export const apiUrl = process.env.REACT_APP_STAGING ? 'https://solo-staging.uc.r.appspot.com' : !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://practical-theme-289410.uc.r.appspot.com';

