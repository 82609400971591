import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import DataBox from "../../components/Charts/DataBox";
import {getString} from "../../utils/translations";
import OverTimeChart from "../../components/Charts/OverTime";
import Table from "./table";
import {exportBillingDailySessions, exportBillingTableSessions} from "../../api/sdk";
import {REQUEST_DATE_FORMAT, TAG_FILTERING_MODES} from "../../constants";
import moment from "moment";
import Select from "react-select";
import features from "../../features";
import Button from "../../components/Button";
import colors from "../../styles/colors";
import {getBillingData} from "../../redux/billing/api";



const Billing = ({startDate, endDate}) => {

    const loading = useSelector(state => state.billing.loading)
    const monthlyBilling = useSelector(state => state.billing.monthlyBilling)
    const dailyBilling = useSelector(state => state.billing.dailyBilling)
    const clientApps = useSelector(state => state.organization.clientApps)
    const unitType = useSelector(state => state.billing.unitType)

    const dispatch = useDispatch()

    const [selectedFilter, setSelectedFilter] = useState(null)

    useEffect(() => {
        if(selectedFilter && selectedFilter.value){
            filter(selectedFilter.value)
        }else {
            filter(null)
        }

    }, [selectedFilter])

    // if start date and end date range is more than 2 month, then show monthly billing
    let monthlyBillingFlag = false
    if(moment(endDate).diff(moment(startDate), 'months') > 2){
        monthlyBillingFlag = true
    }

    const filterOptions = clientApps.map(app => {
        return {
            value: app.id,
            label: app.name || app.appClient
        }
    })

    const filter = (appId) => {
       // console.log("filter", appId)
        let formattedStartDate = startDate.format(REQUEST_DATE_FORMAT)
        let formattedEndDate = endDate.format(REQUEST_DATE_FORMAT)
        dispatch(getBillingData({clientAppId: appId, startDate: formattedStartDate, endDate: formattedEndDate}))
    }

   // console.log("selectedFilter", selectedFilter)

    return (
        <div>
            {loading ? <div>Loading...</div> : (
                <>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: -66, marginBottom:25}}>

                            <FilterContainer>
                                <div style={{width: 250}}>
                                    <Select
                                        isClearable
                                        name="colors"
                                        options={filterOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder={getString("filter_by_app")}
                                        value={selectedFilter}
                                        onChange={(value, action) => {
                                        //    this.setState({selectedTags: value})
                                            //console.log("selectedFilter", value)
                                            setSelectedFilter(value)
                                        }}
                                    />
                                </div>
                            </FilterContainer>
                            <Button
                                style={{
                                    background: colors.lipstick,
                                    paddingLeft: 15,
                                    paddingRight: 15,
                                    height: 35,
                                    borderRadius: 10,
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                    marginLeft: 15
                                }}
                                onClick={()=>{
                                    let formattedStartDate = moment(startDate).format(REQUEST_DATE_FORMAT)
                                    let formattedEndDate = moment(endDate).format(REQUEST_DATE_FORMAT)

                                    exportBillingTableSessions(formattedStartDate, formattedEndDate, selectedFilter? selectedFilter.value : null)}}>
                                <span>{getString("export_sessions")}</span>
                            </Button>


                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 250,
                        marginTop: 15
                    }}>
                        <Card style={{
                            flex: 12,
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                         <ChartTitle>{getString(`${unitType}_last_12_months`)}</ChartTitle>

                                {monthlyBilling ? (
                                    <OverTimeChart
                                        data={monthlyBilling}
                                        x={{key: "date", formatter: (date) => {return date}}}
                                        y={"pricingUnits"}
                                        selectedEmotions={["pricingUnits"]}
                                        tooltipLabelFormatter={()=>{
                                            return null
                                        }}
                                        showBar={true}
                                        showRangePicker={false}
                                        domain={[0, 100]}
                                        showHorizontalLines={true}
                                        topLabel={true}
                                    />
                                ) : null}




                        </Card>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: 250,
                        marginTop: 25
                    }}>
                        <Card style={{
                            flex: 12,
                            display: 'flex',
                            flexDirection: 'column',}}>
                           <ChartTitle>{getString(`${unitType}_daily`)}</ChartTitle>

                            {dailyBilling ? (
                                <OverTimeChart
                                    data={dailyBilling}
                                    x={{key: "date", formatter: (date) => {
                                        return moment(date).format("DD.MM.YY")
                                    }}}
                                    y={"pricingUnits"}
                                    selectedEmotions={["pricingUnits"]}
                                    tooltipLabelFormatter={()=>{
                                        return null
                                    }}
                                    showBar={true}
                                    showRangePicker={false}
                                    domain={[0, 100]}
                                    showHorizontalLines={true}
                                />
                            ) : null}

                        </Card>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 25,
                    }}>
                    {monthlyBillingFlag && monthlyBilling && monthlyBilling.length > 0 ? (
                        <Table
                            type={"month"}
                            unitType={unitType}
                            data={monthlyBilling}
                            headerTitle={getString("sessions")}
                            onExportDailySessions={(date) => {
                                // console.log("onExportDailySessions date", date)

                                // split date string from "m.yyyy" and convert to date
                                let dateArr = date.split(".")

                                let startDate = new Date(dateArr[1], dateArr[0]-1, 1)
                                let endDate = new Date(dateArr[1], dateArr[0], 0)
                                console.log("onExportDailySessions date", date, startDate, endDate)
                                startDate.setHours(0, 0, 0, 0)
                                endDate.setHours(23, 59, 59)
                                // console.log("onExportDailySessions date", date, startDate, endDate)
                                let formattedStartDate = moment(startDate).format(REQUEST_DATE_FORMAT)
                                let formattedEndDate = moment(endDate).format(REQUEST_DATE_FORMAT)
                                const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                console.log("exportBillingDailySessions", formattedStartDate, formattedEndDate, timeZone)
                                exportBillingDailySessions(formattedStartDate, formattedEndDate, timeZone, selectedFilter? selectedFilter.value : null)
                            }}
                        />
                    ) :  dailyBilling && dailyBilling.length > 0 ? (
                            <Table
                                data={dailyBilling}
                                unitType={unitType}
                                headerTitle={getString("sessions")}
                                onExportDailySessions={(date) => {
                                    // console.log("onExportDailySessions date", date)
                                    let startDate = new Date(date)
                                    let endDate = new Date(date)
                                    startDate.setHours(0, 0, 0, 0)
                                    endDate.setHours(23, 59, 59)

                                    // console.log("onExportDailySessions date", date, startDate, endDate)
                                    let formattedStartDate = moment(startDate).format(REQUEST_DATE_FORMAT)
                                    let formattedEndDate = moment(endDate).format(REQUEST_DATE_FORMAT)
                                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                    console.log("exportBillingDailySessions", formattedStartDate, formattedEndDate, timeZone)
                                    exportBillingDailySessions(formattedStartDate, formattedEndDate, timeZone)
                                }}
                            />
                    ) : null}
                    </div>





                </>

            )}

        </div>
    )
}
function roundToTwoDecimalPoints(number) {
    if (Number.isInteger(number)) {
        return number; // Return the number as it is if it's an integer
    } else {
        return parseFloat(number.toFixed(2)); // Round to 2 decimal points otherwise
    }
}
export default Billing;

const Card = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(46, 91, 255, 0.08);
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    `


const ChartTitle = styled.div`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 32px;
    text-align: center; 
    color: #2E384D;
    text-transform: capitalize;
    `

const FilterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ApplyButton = styled.div`
  background: #536EEC;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 75px;
  height: 35px;
  margin-left: 10px;
  cursor: pointer;
`

const ApplyText = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
`

