import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_TRANSLATIONS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_TRANSLATIONS_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_TRANSLATIONS_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_TRANSLATIONS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MEASURMENT_ID
};

console.log("init translation", firebaseConfig)

export const translationsAdmin = firebase.initializeApp(firebaseConfig, "secondary");

export const getTranslationsVersion = async () => {
    const snapshot = await translationsAdmin.database().ref('/translations/version').once('value');
    return snapshot.val();
}

export const getTranslations = async () => {
    const snapshot = await translationsAdmin.database().ref('/translations').once('value');
    return snapshot.val();
}

