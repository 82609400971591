import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { getAsset } from "../../../utils";

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

class DataBox extends PureComponent {
  render() {
    let { value, title, subTitle, trend, tooltip, disableRound } = this.props;

    value = value ? value : 0

    return (
      <DataBoxContainer {...this.props}>
        <Value>{!isNaN(value) ? disableRound ? value : Math.round(value) : value}</Value>
        <div style={{ flexDirection: 'column' }}>
          <Label>{title}</Label>
          <Label style={{ fontWeight: "400" }}>{subTitle}</Label>
        </div>
        {trend ? trend > 0 ? (<Trend src={getAsset("icons/trend_arrow_grey.svg")} />) : (
          <Trend style={{ transform: "rotate(90deg)" }} src={getAsset("icons/trend_arrow_grey.svg")} />) : null}
        {tooltip &&
          <Tippy content={tooltip}>
            <Tooltip src={getAsset("icons/i.svg")} style={{ width: 17, height: 17 }} />
          </Tippy>
        }
      </DataBoxContainer>
    );
  }
}

DataBox.propTypes = {
  value: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  trend: PropTypes.number,
  tooltip: PropTypes.string
};

const DataBoxContainer = styled.div`
  height: 77.48px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
 // margin: 5px;

  @media print {
    width: 125px;
    height: 72px;
    margin-right: 7px
  }
`

const Value = styled.div`
  font-family: "'OpenSans', sans-serif";
  font-style: normal;
  font-weight: bold;
  font-size: 2.5em;
  line-height: 1.25em;
  text-align: center;
  letter-spacing: -0.0375em;
  color: #2E384D;
  margin-right: 5px;
`

const Label = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 0.8125em;
  line-height: 1.125em;
  text-align: left;
  letter-spacing: 0.013125em;
  text-transform: uppercase;
  color: #909090;
`

const Trend = styled.img`
  position: absolute;
  bottom: 8px;
  left: 10px;
`

const Tooltip = styled.img`
  position: absolute;
  left: 7px;
  top: 7px;
`

export default DataBox;
