import React from 'react';
import Lottie from 'react-lottie';
import loader from '../lotties/residentsloader.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const TableLoader = ({}) => {
    return (
        <Lottie
            options={defaultOptions}
            width={"100%"}
            style={{ marginTop: 44 }}
        />
    )
}
