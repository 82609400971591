import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { EMOTIONS } from "../../../constants";
import { getAsset } from "../../../utils";

import loader from '../../../lotties/loaderhouse.json'

import Lottie from 'react-lottie';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import styled from "styled-components";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

class DonutChart extends Component {


    render() {
        const {
            value,
            color,
            icon,
            loading,
            label,
            valueFormatter,
            size,
            secondaryLabel,
            change,
            hideShadow,
            trailColor,
            strokeWidth,
            rotation,
        } = this.props
        console.log("DonutChart", strokeWidth, change)
        return (
            <Container size={size}>
                {size === "LG"  && change ? change > 0 ?
                    <TrendContainer size={size}>
                        <Trend size={size} src={getAsset("icons/trend_up.svg")} />
                    </TrendContainer>
                    : <TrendContainer size={size}>
                        <Trend size={size} src={getAsset("icons/trend_down.svg")} />
                    </TrendContainer> : null}
                <CircularProgressbarWithChildren
                    strokeWidth={strokeWidth}
                    value={value}
                    styles={buildStyles({
                        // Rotation of path and trail, in number of turns (0-1)
                        rotation,

                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',

                        // Text size
                        textSize: '16px',

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: color,
                        textColor: '#f88',
                        trailColor,
                        backgroundColor: color,
                    })}
                >
                    <Content hideShadow={hideShadow}>
                        {loading ? (
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        ) : (
                            <InnerContent>
                                {icon}
                                <Value size={size}>
                                    {valueFormatter ? valueFormatter(Math.round(value)) : Math.round(value)}
                                </Value>
                                {size === "SM" || size === "XL" && change ? change > 0 ?
                                        <TrendContainer size={size}>
                                            <Trend size={size} src={getAsset("icons/trend_up.svg")} />
                                        </TrendContainer> :
                                        <TrendContainer size={size}>
                                            <Trend size={size} src={getAsset("icons/trend_down.svg")} />
                                        </TrendContainer>
                                    : null}
                            </InnerContent>
                        )}


                    </Content>

                </CircularProgressbarWithChildren>
                {label && (<Label>{label}</Label>)}
                {secondaryLabel && (<Label style={{ marginTop: 0, fontWeight: 400 }}>{secondaryLabel}</Label>)}
            </Container>
        );
    }
}

DonutChart.propTypes = {
    value: PropTypes.number,
    color: PropTypes.string,
    icon: PropTypes.string,
    loading: PropTypes.bool,
    label: PropTypes.string,
    valueFormatter: PropTypes.func,
    size: PropTypes.oneOf(["XL", "LG", "SM"]),
    trailColor: PropTypes.string,
    strokeWidth: PropTypes.number,
    rotation: PropTypes.number,
};

DonutChart.defaultProps = {
    loading: false,
    size: "LG",
    trailColor: "transparent",
    strokeWidth: 5,
    rotation: 0.5
}

const Container = styled.div`
  width: ${props => props.size === "XL" ? "250px" : props.size === "LG" ? "140px" : "57px"};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  
  @media print{
        margin-left: 3px;
      }
`
const TrendContainer = styled.div`
  position: ${props => props.size === "LG" ? "absolute" : "relative"};
  top: ${props => props.size === "LG" ? "-0.9375em" : "-0.125em"};
  left: ${props => props.size === "LG" ? "0.3125em" : "0em"};
  margin-top: ${props => props.size === "LG" ? null : "0.4em"};
  margin-bottom: ${props => props.size === "LG" ? null : "0.5em"};
`
const Trend = styled.img`
  width: ${props => props.size === "LG" || props.size === "XL"? "1.25em" : "0.625em"};
  height: ${props => props.size === "LG" || props.size === "XL"? "1.25em" : "0.625em"};
`

const Content = styled.div`
  width: 90%;
  height: 90%;
  
  box-shadow: ${props => props.hideShadow ? "none" : "0em 0em 0.801725em rgba(0, 0, 0, 0.25)"};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  `

const Value = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: ${props => props.size === "LG" || props.size === "XL" ? "bold" : "normal"};
  font-size: ${props => props.size === "LG" || props.size === "XL" ? "2.75em" : "1.15em"};
  line-height: ${props => props.size === "LG" || props.size === "XL" ? "1.25em" : "0.25em"};
  text-align: center;
  letter-spacing: ${props => props.size === "LG" || props.size === "XL" ? "-0.656376px" : "0em"};
  color: #2E384D;
  
  @media print{
    font-size: ${props => props.size === "LG" || props.size === "XL" ? "32px" : "22px"};
  }
`

const Label = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 0.9375em;
  line-height: 1.25em;
  text-align: center;
  letter-spacing:  0.020625em;
  margin-top: 0.9375em;
  /* Dim */
  color: #909090;
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5em;
  height: 100%;
`


export default DonutChart;
