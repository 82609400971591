import styled from "styled-components";
import ProgressBar from "../../../components/Charts/ProgressBar";
import { getEmotionKey } from "./utils"
import { getTrend } from "../../../utils/charts";
import colors from "../../../styles/colors";

const Emotion = ({ value, color, item, trendKey }) => {
    let { seconds, sessions, trend } = item;

    let happinessKey = getEmotionKey("happiness");
    let calmnessKey = getEmotionKey("calmness");
    let sadKey = getEmotionKey("sad");
    let angryKey = getEmotionKey("angry");

    let wellbeingKey = getEmotionKey("wellbeing");
    let stressKey = getEmotionKey("stress");
    let engagementKey = getEmotionKey("engagement");
    let interestKey = getEmotionKey("interest");
    let valenceKey = getEmotionKey("valence");

    let results = {
        calmness: item[calmnessKey],
        happiness: item[happinessKey],
        sad: item[sadKey],
        angry: item[angryKey],
        wellbeing: item[wellbeingKey],
        stress: item[stressKey],
        engagement: item[engagementKey],
        interest: item[interestKey],
        valence: item[valenceKey],
        seconds,
        sessions,
    };

    const trendRes = getTrend(results, trend)
    let change = (value === 0 && trendRes[`${trendKey}_ps`] > 0) || trendRes[`${trendKey}_ps`] === 0 ? 0 : trendRes[`${trendKey}_ps`];
    let reverseProgress = false

    if (color === colors.tomato) {
        reverseProgress = true
    }

    return (
        <Wrapper>
            <ProgressBar
                percentage={value}
                progressColor={color}
                change={change}
                reverseProgress={reverseProgress} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default Emotion