import styled from "styled-components";
import { STRESS_HIGH, STRESS_MEDIUM, STRESS_LOW } from "../../../api/remoteConfig"
import { LEVELS } from "../../../constants";
import { getString } from "../../../utils/translations";
import colors from "../../../styles/colors";

const Stress = ({ value }) => {

    const getLevelColor = (level) => {
        switch (level) {
            case LEVELS.HIGH:
                return colors.secondary
            case LEVELS.MEDIUM:
                return colors.mild
            case LEVELS.LOW:
                return colors.calm
            default:
                return colors.warm_grey
        }
    }

    let stress = value / 100
    let level

    if (stress > STRESS_HIGH) {
        level = LEVELS.HIGH
    } else if (stress < STRESS_HIGH && stress > STRESS_MEDIUM) {
        level = LEVELS.MEDIUM
    } else if (stress < STRESS_MEDIUM && stress > STRESS_LOW) {
        level = LEVELS.LOW
    }
    else {
        level = LEVELS.NO
    }
    const color = getLevelColor(level)

    return (
        <StressValue color={color}>
            {getString(`${level}`)}
        </StressValue >
    );
}

const StressValue = styled.div`
    display: flex;
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 41px;
    text-transform: uppercase;
    color: ${props => props.color};
`

export default Stress