import {createSlice} from '@reduxjs/toolkit';

export const orgSlice = createSlice({
    name: 'organization',
    initialState: {
        currentOrg: null,
        clientApps: []
    },
    reducers: {
        setCurrentOrg: (state, action) => {
            state.currentOrg = action.payload
        },
        setClientApps: (state, action) => {
            state.clientApps = action.payload
        },
        updateClientApp: (state, action) => {
            const app = action.payload;
            const index = state.clientApps.findIndex(a => a.id === app.id);
            state.clientApps[index] = app;
        },
        addClientApp: (state, action) => {
            const app = action.payload;
            state.clientApps.push(app);
        },
        deleteClientApp: (state, action) => {
            const appId = action.payload;
            state.clientApps = state.clientApps.filter(a => a.id !== appId);
        }

    },
});


const {actions, reducer} = orgSlice;

export const {
    setCurrentOrg,
    setClientApps,
    addClientApp,
    updateClientApp,
    deleteClientApp
} = actions;
export default reducer;
