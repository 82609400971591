import { buildFeatureList } from "./featurs";

export const features = buildFeatureList({
    tags: true,
    exportList: true,
    contentLayer: true,
    generateApiKey: false,
    showType: true,
    showArtist: true,
    hasBaseline: true,
    exportContentTable: false,
    billingScreen: false,
    groupManagement: false,
})
