import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHouseResidentsReport, getHouseReport } from "../../api"
import _, { get, findIndex } from "lodash";
import { RESIDENT_TYPE, SCREENS } from "../../constants";
import { setMoodEnergyChartData, setPieChartData, setModulesAvgs, setTableInsights } from "../top"
import { parseUsersTableData, parseUsersTopData } from "../../parsers"

export const getUsersTopData = createAsyncThunk('users/getTopData', async (data) => {
    const { startDate, endDate, compareTrendKey, tags, tagFilteringMode } = data
    const res = await getHouseReport(startDate, endDate, compareTrendKey, tags, tagFilteringMode)
    console.log("res", res)
    const parsedTopData = parseUsersTopData(res.data)
    return {...parsedTopData,startDate, endDate}

})

export const getUsersTableData = createAsyncThunk('users/getTableData', async (data, { dispatch, getState }) => {//getState: for the whole state not only this slice
    const { startDate, endDate, compareTrendKey, tags, houseType, tagFilteringMode } = data
    const res = await getHouseResidentsReport(startDate, endDate, compareTrendKey, tags, tagFilteringMode, houseType)
    const users = handleUsersData(res.data, houseType)
    if (_.get(res, "data", null)) {
        dispatch(setMoodEnergyChartData({ data: users, context: SCREENS.USERS }))
      //  dispatch(setWellbeingMeterData(users))
        dispatch(setPieChartData({ data: users, context: SCREENS.USERS }))
        dispatch(setModulesAvgs({ data: users, context: SCREENS.USERS }))
        dispatch(setTableInsights({ data: users, context: SCREENS.USERS }))
       // dispatch(setStressMeterData(users))

    }
    return users
})

const handleUsersData = (data, houseType) => {
    const indexOfPublicModeResident = findIndex(data, (d) => {
        return get(d, "resident.type", "") === RESIDENT_TYPE.MULTI
    })
    if (indexOfPublicModeResident !== -1) {
        data.splice(indexOfPublicModeResident, 1)
    }
    if (houseType === "org") {
        data = _.cloneDeep(removeResidentsOfType(data))
    }
    data = parseUsersTableData(data)
    return data
}

const removeResidentsOfType = (data) => {
    return data.filter(d => get(d, "resident.type", "") !== RESIDENT_TYPE.GROUP && get(d, "resident.type", "") !== RESIDENT_TYPE.CARE)
}
