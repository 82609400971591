import React, {useState, useEffect} from 'react';
import PopUp from 'reactjs-popup';
import {getString} from "../../utils/translations";

import {Editable, Footer, FooterButton, PopupTitle} from "../Dashboard/VideoUploadPopup";
import colors from "../../styles/colors";
import Lottie from "react-lottie";
import {createGroupAdminData} from "../../redux/groups/api";
import {useDispatch} from "react-redux";
import {createGroupAdmin} from "../../api";
import {
    ValueBoxMain,
    ValueBoxSecondary,
    ValueBoxSecondaryInput
} from "../../components/ClientAppsWizard/styledComponents";


function CreateAdminPopup ({isOpen, closeHandler, groupId}) {

        const [email, setEmail] = useState("");
        const [emailFieldOpen, setEmailFieldOpen] = useState(false);
        const [emailError, setEmailError] = useState(false);
        const [serverError, setServerError] = useState(false);
        const [loading, setLoading] = useState(false);
        const [password, setPassword] = useState("")
        const [copied, setCopied] = useState(false)

        const dispatch = useDispatch()

      const verifyEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }

      const toggleEmailField = () => {
        setEmailFieldOpen(!emailFieldOpen);
      }

      const submit = async () => {
            setLoading(true)
        if (!verifyEmail()) {
          setEmailError(true);
          return;
        }
        setEmailError(false);
        const res = await dispatch(createGroupAdminData({groupId, email}))
         console.log(res);
        if(res && res.type === "groups/addGroupAdminData/fulfilled"){
            console.log("res", res);
            if(res.payload.error){
                setServerError(res.payload.error)
                setLoading(false)
                return;
            }
            setPassword(res.payload.password)
        }

        setLoading(false)

       // closeHandler();
      }


        return (
            <PopUp
                position="center"
                closeOnDocumentClick//={false}
                open={isOpen}
                onClose={closeHandler}
                contentStyle={{
                    width: 335,
                    borderRadius: 9,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingBottom: 75
                }}
            >
                <PopupTitle>{getString("create_admin_popup_title")}</PopupTitle>
                <div>{password ? getString("create_admin_popup_password_description") : getString("create_admin_popup_description")}</div>
                <ValueBoxMain>{getString("email")}</ValueBoxMain>
                <ValueBoxSecondaryInput autoFocus value={email} onChange={e => setEmail(e.target.value)} onBlur={verifyEmail}/>


                {password && (
                    <>
                        <ValueBoxMain>{getString("password")}</ValueBoxMain>
                        <ValueBoxMain onClick={()=>{
                            navigator.clipboard.writeText(password);
                            setCopied(true)

                        }} style={{cursor: 'pointer', height: 59, backgroundColor: copied ? colors.calm : null }}>{password}</ValueBoxMain>
                        {copied && <div style={{color: colors.blue, fontSize: 12, marginTop: 10}}>{getString("copied")}</div>}
                    </>

                )}

                {serverError && <div style={{color: colors.lipstick, fontSize: 12, marginTop: 10}}>{serverError}</div>}


                <Footer>
                    <FooterButton style={{background: colors.blue, color: "#fff"}}
                                  onClick={password ? closeHandler : submit}
                                  disabled={emailError || !email}
                    >
                        {password ? getString("done"): getString("create_admin")}
                    </FooterButton>
                </Footer>
            </PopUp>
        );
}


export default CreateAdminPopup;
