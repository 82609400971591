import { createSlice } from '@reduxjs/toolkit';
import { SCREENS } from '../../constants';
import {setHouseConfig} from "./api"
import {useTempConfig} from "solo-common/src/emotions-recognition-utils";

export const houseSlice = createSlice({
    name: 'house',
    initialState: {
        currentHouse: null,
        currentScreen: SCREENS.USERS,
        userId: null,
        sessionId: null,
        houseConfig: null
    },
    reducers: {
        setCurrentHouse: (state, action) => {
            state.currentHouse = action.payload
        },
        setCurrentScreen: (state, action) => {
            state.currentScreen = action.payload
        },
        setUserId: (state, action) => {
            state.userId = action.payload
        },
        setSessionId: (state, action) => {
            state.sessionId = action.payload
        },
        extraReducers: {
            [setHouseConfig.rejected]: (state, { payload }) => {
                console.error("error getting house config", payload)
            },
            [setHouseConfig.pending]: (state, { payload }) => {

            },
            [setHouseConfig.fulfilled]: (state, { payload }) => {
                state.houseConfig = payload
            }
        }
    },
});


const { actions, reducer } = houseSlice;

export const { setCurrentHouse, setCurrentScreen, setUserId, setSessionId } = actions;
export default reducer;
