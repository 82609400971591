import { SCREENS } from "../constants"

class userTopItem {
    constructor({ activeUsers, angry, calmness, costSaving, disgusted, energy, energy_ps,
        engagement, fearful, happiness, overTimeEmotions, overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth, overTimeEmotionsByHour, sad, seconds, sessions, stress,
        surprised, trend, valence, valence_ps, wellbeing, scores, lastMonthsOT, lastWeekOT
    }) {
        this.activeUsers = activeUsers
        this.angry = angry
        this.calmness = calmness
        this.costSaving = costSaving
        this.disgusted = disgusted
        this.energy = energy
        this.energy_ps = energy_ps
        this.engagement = engagement
        this.fearful = fearful
        this.happiness = happiness
        this.overTimeEmotions = overTimeEmotions
        this.overTimeEmotionsByDay = overTimeEmotionsByDay
        this.overTimeEmotionsByDayOfMonth = overTimeEmotionsByDayOfMonth
        this.overTimeEmotionsByHour = overTimeEmotionsByHour
        this.sad = sad
        this.seconds = seconds
        this.sessions = sessions
        this.stress = stress
        this.surprised = surprised
        this.trend = trend
        this.valence = valence
        this.valence_ps = valence_ps
        this.wellbeing = wellbeing
        this.scores = scores
        this.lastMonthsOT = lastMonthsOT
        this.lastWeekOT = lastWeekOT
    }
}

class sessionTopItem {
    constructor({ activeUsers, angry, calmness, costSaving, disgusted, energy, energy_ps,
        engagement, fearful, happiness, overTimeEmotions, overTimeEmotionsByDay,
        overTimeEmotionsByDayOfMonth, overTimeEmotionsByHour, user, sad, seconds, sessions,
        stress, surprised, trend, valence, valence_ps, wellbeing, userName,lastMonthsOT, lastWeekOT
    }) {
        this.activeUsers = activeUsers
        this.angry = angry
        this.calmness = calmness
        this.costSaving = costSaving
        this.disgusted = disgusted
        this.energy = energy
        this.energy_ps = energy_ps
        this.engagement = engagement
        this.fearful = fearful
        this.happiness = happiness
        this.overTimeEmotions = overTimeEmotions
        this.overTimeEmotionsByDay = overTimeEmotionsByDay
        this.overTimeEmotionsByDayOfMonth = overTimeEmotionsByDayOfMonth
        this.overTimeEmotionsByHour = overTimeEmotionsByHour
        this.user = user
        this.userName = userName
        this.sad = sad
        this.seconds = seconds
        this.sessions = sessions
        this.stress = stress
        this.surprised = surprised
        this.trend = surprised
        this.valence = valence
        this.valence_ps = valence_ps
        this.wellbeing = wellbeing
        this.lastMonthsOT = lastMonthsOT
        this.lastWeekOT = lastWeekOT
    }
}

class contentTopItem {
    constructor({ userId, activeUsers, angry, calmness, comment, costSaving, createdAt,
        disgusted, energy, energy_ps, fearful, happiness, contentType, overTimeEmotions, userName,
        sad, seconds, sessionId, sessions, stress, surprised, valence, valence_ps, wellbeing, lastMonthsOT, lastWeekOT }) {
        this.userId = userId
        this.userName = userName
        this.activeUsers = activeUsers
        this.angry = angry
        this.calmness = calmness
        this.comment = comment
        this.costSaving = costSaving
        this.createdAt = createdAt
        this.disgusted = disgusted
        this.energy = energy
        this.energy_ps = energy_ps
        this.fearful = fearful
        this.happiness = happiness
        this.contentType = contentType
        this.overTimeEmotions = overTimeEmotions
        this.sad = sad
        this.seconds = seconds
        this.sessionId = sessionId
        this.sessions = sessions
        this.stress = stress
        this.surprised = surprised
        this.valence = valence
        this.valence_ps = valence_ps
        this.wellbeing = wellbeing
        this.lastMonthsOT = lastMonthsOT
        this.lastWeekOT = lastWeekOT
    }
}

export const getTopItem = (item, context) => {
    switch (context) {
        case SCREENS.USERS:
            const user = new userTopItem(item)
            return user;
        case SCREENS.SESSIONS:
            const session = new sessionTopItem(item)
            return session;
        case SCREENS.CONTENT:
            const content = new contentTopItem(item)
            return content;
    }
}
