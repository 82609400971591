
import { apiRoutes } from "../constants/config";
import firebase from "./firebase"
import { getAuthHeader, cacheRequest } from "./api";

export const login = async (email, password, houseKey) => {
    try {

        let user = null;
        try {
            user = await firebase.auth().signInWithEmailAndPassword(email, password)
            const idToken = await user.getIdToken();
            localStorage.setItem("userToken", idToken)

            let headers = new Headers()
            headers.append("Content-Type", "application/json");
            headers.append("Authorization", `Bearer ${idToken}`)
            let init = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    email
                })
            };

            let response = await fetch(apiRoutes.loginValidation, init)
            let jsonRes = await response.json();
            if (!jsonRes.success) {
                return {
                    error: "bad credentials"
                }
            }
            return jsonRes
        } catch (error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            return {
                error: error.message
            }
        }
    } catch (e) {
        return {
            error: e
        }
    }
}

export const sendPasswordReset = async (email, redirectURL) => {
    try {
        let actionCodeSettings = {
            url: redirectURL,
            handleCodeInApp: false
        };
        await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
    } catch (error) {
        return {
            error: error.message
        }
    }
}

export const loginValidation = async () => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
        })
    };
    let response = await fetch(apiRoutes.loginValidation, init)
    let jsonRes = await response.json();
    if (!jsonRes.success) {
        return {
            error: "bad credentials"
        }
    }
    return jsonRes
}

export const getAdminHouses = async () => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let response = await fetch(apiRoutes.adminHouses, init)
    let json = await response.json();
    return json
}

export const getHouseReport = async (startDate, endDate, compareTrendKey, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.houseReport(startDate, endDate, compareTrendKey, tags, tagFilteringMode, timeZone)
    return await cacheRequest(route, init)
}

export const exportSessions = async (startDate, endDate, tags, tz, tagFilteringMode, userId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportSessions(startDate, endDate, tags, tz, tagFilteringMode, userId)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `sessions_export_${startDate}_${endDate}.csv`);
    a.click();
}

export const exportSessionOvertime = async (sessionId, tz) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportSessionData(sessionId, tz)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `session_export_${sessionId}.csv`);
    a.click();
}

export const exportBillingDailySessions = async (startDate, endDate, tz, clentAppId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportBillingDailySessions(startDate, endDate, tz, clentAppId)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `billing_sessions_export_${startDate}.csv`);
    a.click();
}

export const exportBillingTableSessions = async (startDate, endDate, clentAppId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.exportBillingTableSessions(startDate, endDate, timeZone, clentAppId)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", `billing_table_sessions_export_${startDate}-${endDate}.csv`);
    a.click();
}



export const getResidentReport = async (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.residentReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode, timeZone)
    return await cacheRequest(route, init)
}

export const getResidentSessionReport = async (startDate, endDate, compareTrendKey, residentId, sessionId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };

    let route = apiRoutes.residentSessionReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    return await cacheRequest(route, init)
}

export const getHouseResidentsReport = async (startDate, endDate, compareTrendKey, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.houseResidentsReport(startDate, endDate, compareTrendKey, tags, tagFilteringMode, timeZone)
    return await cacheRequest(route, init)
}

export const getResidentSessionsReport = async (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.residentSessionsReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode, timeZone)
    return await cacheRequest(route, init)
}

export const getSessionTracksReport = async (startDate, endDate, compareTrendKey, residentId, sessionId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };

    let route = apiRoutes.sessionTracksReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    return await cacheRequest(route, init)
}

export const tableOverTimeData = async (sessionId, contentId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.tableOverTimeData(sessionId, contentId)
    return await cacheRequest(route, init)
}

export const saveResidentComment = async (residentId, comment) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            residentId,
            comment
        })
    };

    let response = await fetch(apiRoutes.residentComment, init)
    return await response.json();
}

export const saveSessionComment = async (sessionId, comment) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            sessionId,
            comment
        })
    };
    let response = await fetch(apiRoutes.sessionComment, init)
    return await response.json();
}

export const getTags = async (context) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.dashboardTags(context)
    return await cacheRequest(route, init)
}

export const saveTag = async (id, tag, context) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            id,
            tag
        })
    };

    let response = await fetch(apiRoutes[`add${context}Tag`], init)
    return await response.json();
}

export const removeTag = async (id, tag, context) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({
            id,
            tag
        })
    };
    let response = await fetch(apiRoutes[`delete${context}Tag`], init)
    return await response.json();
}

export const houseLogin = async (orgGroupId) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            orgGroupId
        })
    };
    let response = await fetch(apiRoutes.houseLogin, init)
    return await response.json();
}

export const orgLogin = async (orgId) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            orgId
        })
    };
    let response = await fetch(apiRoutes.orgLogin, init)
    return await response.json();
}

export const houseLogout = async () => {
    let headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers
    };
    return fetch(apiRoutes.houseLogout, init).then((response) => response.json())
}

export const housePreLoginValidation = async (email, houseKey) => {
    try {
        let headers = await getAuthHeader()
        headers.append("Content-Type", "application/json")
        let init = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                email,
                houseKey
            })
        };
        let response = await fetch(apiRoutes.loginValidation, init)
        let validationRes = await response.json();
        if (!validationRes || !validationRes.admin) {
            return false
        } else {
            return true
        }
    } catch (e) {
        return false
    }

}

export const generateAPIKey = async ({clientAppType, clientAppId, appName, unitsLimit}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            clientAppType,
            clientAppId,
            appName,
            unitsLimit
        })
    };
    let response = await fetch(apiRoutes.generateAPIKey, init)
    return await response.json();
}

export const updateClientAppName = async ({clientAppId, name, unitsLimit}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            name,
            unitsLimit
        })
    };
    let response = await fetch(apiRoutes.updateClientApp(clientAppId), init)
    return await response.json();
}

export const deleteSDKApp = async (clientAppId) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers
    };
    let response = await fetch(apiRoutes.deleteClientApp(clientAppId), init)
    return await response.json();

}

export const getBillingSessions = async (startDate, endDate, clientAppId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let route = apiRoutes.getBillingCount(startDate, endDate, timeZone, clientAppId)
    return await cacheRequest(route, init)
}

export const saveAnalyzedVideo = () => { }
export const getHouseConfig = () => {}

export const exportVideoData = async (startDate, endDate, timeZone, row) => {
    const contentId = row.contentId
    const sessionId = row.sessionId

    const headers = await getAuthHeader();

    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let route = apiRoutes.exportContentData(sessionId, contentId, timeZone)
    let response = await fetch(route, init)
    let blob = await response.blob();
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    // a.setAttribute("download", `${videoName} data ${videoDate} V_${algoVersion}.csv`);
    a.setAttribute("download", `${sessionId}_${contentId}.csv`);
    a.click();
}


export const createGroup = async ({name, unitsLimit}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            name,
            unitsLimit
        })
    };
    let response = await fetch(apiRoutes.createGroup, init)
    let res = await response.json();
    console.log("createGroup", res)
    return res
}

export const deleteGroup = async (id) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
    };
    let response = await fetch(apiRoutes.deleteGroupSolo(id), init)
    return await response.json();
}

export const deleteGroupSdk = async (id) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
    };
    let response = await fetch(apiRoutes.deleteGroupSdk(id), init)
    return await response.json();

}


export const updateGroup = async ({id, name, unitsLimit, active}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
            name,
            unitsLimit,
            active
        })
    };
    let response = await fetch(apiRoutes.updateGroup(id), init)
    return await response.json();
}

export const getGroupAdmins = async (groupId) => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let response = await fetch(apiRoutes.getGroupAdmins(groupId), init)
    return await response.json();

}
export const createGroupAdmin = async ({groupId, email}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            email,
            name: email
        })
    };
    let response = await fetch(apiRoutes.createGroupAdmin(groupId), init)
    return await response.json();
}
export const deleteGroupAdmin = async ({groupId, adminId}) => {
    let headers = await getAuthHeader()
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'DELETE',
        headers: headers,
    };
    let response = await fetch(apiRoutes.deleteGroupAdmin(adminId), init)
    return await response.json();
}
export const getGroups = async () => {
    const headers = await getAuthHeader();
    headers.append("Content-Type", "application/json")
    let init = {
        method: 'GET',
        headers: headers
    };
    let response = await fetch(apiRoutes.getGroups, init)
    return await response.json();
}
