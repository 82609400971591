import React, { useRef, useEffect, useState } from 'react';
import {OverTimeButton} from "../../components/solo-styled-components";
import {getString} from "../../utils/translations";
import {deleteGroupAdminData} from "../../redux/groups/api";
import colors from "../../styles/colors";
import styled from "styled-components";

const AdminList = ({ groupAdmins, originalRow, dispatch }) => {
    const containerRef = useRef(null);
    const [columnCount, setColumnCount] = useState(1);

    const checkOverflow = () => {
        const container = containerRef.current;
        const children = container.children;
        let childrenHeight = 0;
        for (let i = 0; i < children.length; i++) {
            childrenHeight += children[i].offsetHeight;
        }
        const columnsNeeded = Math.ceil(childrenHeight / container.clientHeight);
        setColumnCount(columnsNeeded);
    };

    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <div ref={containerRef} style={{ marginTop: 20, maxHeight: '100%',width: "100%" ,overflowY: 'auto', overflowX: "hidden", columnCount: columnCount }}>
            {
                groupAdmins && groupAdmins[originalRow.id] && groupAdmins[originalRow.id].map((admin, index) => {
                    return <div key={index} style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: 250,
                        breakInside: 'avoid' // Avoid breaking inside admin items

                    }}>
                        <Email>{admin.email}</Email>
                        <OverTimeButton
                            style={{backgroundColor: colors.black, height: 20, fontSize: 13,  width: 60, marginLeft: 10,}}
                            onClick={() => {
                                console.log("delete admin", admin)
                                dispatch(deleteGroupAdminData({groupId: originalRow.id, adminId: admin.id}))
                            }}
                        >{getString("delete_admin_button")}</OverTimeButton>
                    </div>
                })
            }
        </div>
    );
};

const Email = styled.div`
    color: #2E384D;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export default AdminList;
