import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import styled from "styled-components";
import { connect } from "react-redux";

class DatePicker extends Component {

    constructor() {
        super();
        this.state = {
            focusedInput: null,
            startDate: null,
            endDate: null
        }
    }

    componentDidMount() {
        const {
            startDate,
            endDate
        } = this.props;

        this.setState({
            startDate,
            endDate
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            startDate,
            endDate
        } = this.props;

        if (!startDate.isSame(prevProps.startDate) || !endDate.isSame(prevProps.endDate)) {
            this.setState({
                startDate,
                endDate
            })
        }
    }

    submit = () => {
        const {
            startDate,
            endDate,
        } = this.state;

        const {
            onDatesChange
        } = this.props;

        onDatesChange({
            startDate,
            endDate,
        })
    }


    render() {
        const {
            startDate,
            endDate,
        } = this.state;

        const { wrapperStyle } = this.props;

        return (
            <div style={wrapperStyle}>
                <DatePickerStyle>
                    <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => this.setState({
                            startDate,
                            endDate
                        }, () => {
                            if (endDate) {
                                this.submit()
                            }
                        })} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        isOutsideRange={(day) => {
                            return moment("2021-01-01").isAfter(day)
                        }}
                    />
                </DatePickerStyle>
            </div >


        );
    }
}


const mapStateToProps = ({ date }) => ({
    endDate: date.endDate,
    startDate: date.startDate,
});

export default connect(mapStateToProps)(DatePicker);


const DatePickerStyle = styled.div`
    @media (max-width: 1200px){
        width: 18.75em;
    }
    @media (max-width: 992px){
        margin-left: 0.9375em;
        width: 18.125em;
        margin-top: 0.125em;
        margin-right: -2.3125em;
    }
`


DatePicker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onDatesChange: PropTypes.func,
    wrapperStyle: PropTypes.object
};