import styled from "styled-components";
import GaugeChart from 'react-gauge-chart'
import colors from "../../../styles/colors";

const Wellbeing = ({ value }) => {
    let gaugeValue = value / 100
    return (
        <GaugeWrapper>
            <GaugeChart id="gauge-chart-wellbeing"
                hideText={true}
                textColor={colors.black}
                percent={gaugeValue}
                nrOfLevels={10}
                arcWidth={0.2}
                cornerRadius={2}
                colors={["#F45A52", "#ecae02", "#67ac47"]}
                style={{ width: 100 }}
            />
            <GaugeValue>{`${Math.round(gaugeValue * 100)}%`}</GaugeValue>
        </GaugeWrapper>

    );
}

const GaugeWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const GaugeValue = styled.div`
font-family: Open Sans;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 22px;

text-align: center;
margin-top: -5px;

color: #2E384D;
`

export default Wellbeing