
import * as sdkApis from "./sdk";
import * as soloApis from "./solo";

export let apis;
export const isSdk = () => {
    let app = process.env.REACT_APP_ENV;
    return app && app.includes("sdk");
}

if (isSdk()) {
    apis = sdkApis
} else {
    apis = soloApis
}

export const {
    login,
    sendPasswordReset,
    loginValidation,
    getAdminHouses,
    getHouseReport,
    exportSessions,
    exportTableVideos,
    exportVideoData,
    tableOverTimeData,
    getVideoOverTimeData,
    tableVideoData,
    getVideoProcessedData,
    getVideosReport,
    getVideosTableReport,
    getResidentReport,
    getResidentSessionReport,
    getHouseResidentsReport,
    getResidentSessionsReport,
    getSessionTracksReport,
    saveResidentComment,
    saveSessionComment,
    getTags,
    getAnnotations,
    editAnnotation,
    saveAnnotation,
    deleteAnnotation,
    saveTag,
    removeTag,
    houseLogin,
    orgLogin,
    houseLogout,
    housePreLoginValidation,
    generateAPIKey,
    deleteSDKApp,
    getResidentId,
    uploadVideo,
    deleteVideo,
    editVideo,
    getHouseConfig,
    exportSessionOvertime,
    getBillingSessions,
    getGroups,
    updateGroup,
    getGroupAdmins,
    createGroupAdmin,
    deleteGroupAdmin,
    deleteGroup
} = apis

