import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from "styled-components";

import { getString } from "../../utils/translations";

import { TIME_FILTERS } from "../../constants";
import colors from "../../styles/colors";
import { ToggleButton } from 'react-bootstrap';

class QuickDateFilter extends Component {

    constructor() {
        super();
        this.state = {
            isOpen: false
        }
    }

    handleOnClickValue = (currentValue) => {
        const { onChange } = this.props;
        onChange(currentValue)
        this.setState({ isOpen: false })
    }

    toggle = (isOpen) => {
        this.setState({ isOpen })
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleClickOutside = (e) => {
        if (!this.node.contains(e.target)) {
            this.setState({ isOpen: false })
        }
    }

    render() {

        const {
            value,
            onChange,
            isClickable,
            currentLang
        } = this.props;

        const { isOpen } = this.state
        const containerStyle = isOpen ? { borderRadius: '10px 10px 0 0', backgroundColor: colors.bg } : {};
        const dropDownHeaderStyle = isOpen ? { backgroundColor: colors.lipstick } : {}

        if (!isClickable) {
            containerStyle["backgroundColor"] = colors.bg
            dropDownHeaderStyle["color"] = colors.black
        }

        return (
            <div>
                <DropDownContainer tabIndex={0} onClick={() => isClickable ? this.toggle(!isOpen) : null}
                    ref={node => this.node = node}
                    style={containerStyle}>
                    <DropDownHeader
                        style={dropDownHeaderStyle}>
                        {getString(`${value}`)}
                    </DropDownHeader>
                    {isOpen && (
                        <div>
                            {Object.keys(TIME_FILTERS).map((key, i) => {
                                let currentValue = TIME_FILTERS[key];
                                if (currentValue === TIME_FILTERS.CUSTOM || value === currentValue) {
                                    return null;
                                }
                                return <DropDownContent onClick={() => this.handleOnClickValue(currentValue)}
                                    style={i === Object.keys(TIME_FILTERS).length - 3 ? { borderRadius: '0 0 10px 10px' } : {}}
                                    key={i}>
                                    <Value>{getString(`${currentValue}`)}</Value>
                                </DropDownContent>
                            })}
                        </div>
                    )}
                </DropDownContainer>

                <Container>
                    {Object.keys(TIME_FILTERS).map((key, i) => {
                        let currentValue = TIME_FILTERS[key];
                        if (currentValue === TIME_FILTERS.CUSTOM) {
                            return null;
                        }
                        if (value === currentValue && isClickable) {
                            return <SelectedValue key={i}>{getString(`${currentValue}`)}</SelectedValue>
                        }
                        return <Value
                            key={i}
                            onClick={() => isClickable && onChange(currentValue)}>{getString(`${currentValue}`)}</Value>
                    })
                    }
                </Container>
            </div>
        );
    }
}

QuickDateFilter.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};

const Container = styled.div`
  background: #EFEFEF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.25em;
  height: 2.5em;

  @media print {
    display: none;
  }

  @media (max-width: 1200px) {
    margin-right: -3em;
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const DropDownContainer = styled.div`
  background: ${colors.lipstick};
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 7.3125em;
  height: 2.5em;
  margin-right: -2.7em;
  margin-left: 0.7em;
  cursor: pointer;

  @media print {
    display: none;
  }

  @media (min-width: 992px) {
    display: none;
  }
`

const DropDownHeader = styled.div`
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375em;
  line-height: 1.25em;
  letter-spacing: 0.026475625em;
  color: #FFFFFF;
`

const DropDownContent = styled.div`
  height: 2.5em;
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.026475625em;

  background: #EFEFEF;
  cursor: pointer;
`

const Value = styled.div`
  height: 100%;
  padding-left: 1.9em;
  padding-right: 1.9em;

  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 0.9375em;
  line-height: 1.25em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.026475625em;
  cursor: pointer;

  color: #000000;
`

const SelectedValue = styled.div`
  background: ${colors.lipstick};
  border-radius: 10px;
  height: 100%;
  padding-left: 1.875em;
  padding-right: 1.875em;

  display: flex;
  align-items: center;
  text-align: center;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9375em;
  line-height: 1.25em;
  letter-spacing: 0.026475625em;

  color: #FFFFFF;
`

export default QuickDateFilter;
