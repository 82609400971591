import React, { Component } from 'react';
import PopUp from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../../styles/colors";
import Lottie from 'react-lottie';
import { getString } from '../../utils/translations';
import DatePicker from 'react-date-picker'

class AddAnnotationPopup extends Component {

    constructor() {
        super();
        this.state = {
            name: null,
            startDate: new Date(),
            endDate: new Date(),
            nameFieldOpen: false,
        }
    }

    componentDidMount() {
        const { name, startDate, endDate } = this.props

        console.log({startDate, endDate})

        let start, end;
        if (startDate) {
            if(typeof startDate === 'string'){
                start = new Date(startDate)
            }else {
                start = startDate
            }
        }else {
            start = new Date()
        }

        if (endDate) {
            if(typeof endDate === 'string'){
                end = new Date(endDate)
            }else {
                end = endDate
            }
        }else {
            end = new Date()
        }

        this.setState({
            name: name || null,
            startDate: start,
            endDate: end,
            nameFieldOpen: name ? true : false
        })
    }

    updateInputValue(evt) {
        const val = evt.target.value;
        this.setState({
            name: val
        });
    }

    handleNameClick = () => {
        const { name, nameFieldOpen } = this.state
        if (name && nameFieldOpen) {
            return
        }
        this.setState({ nameFieldOpen: !nameFieldOpen })
    }

    render() {

        const { isOpen, closeHandler, title, loading, loader, action } = this.props
        const { name, startDate, endDate, nameFieldOpen } = this.state

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div>
                <PopUp
                    position="center"
                    open={isOpen}
                    onClose={closeHandler}
                    closeOnDocumentClick={false}
                    contentStyle={{
                        width: 335,
                        borderRadius: 9,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingBottom: 75
                    }}
                >
                    <PopupTitle>{title}</PopupTitle>

                    <Editable onClick={this.handleNameClick} style={{ cursor: 'pointer', height: 59 }} >
                        {`${getString("mail5")}`}
                        {nameFieldOpen &&
                            <input value={name}
                                onChange={evt => this.updateInputValue(evt)}
                                onClickCapture={e => e.nativeEvent.stopPropagation()}
                                style={{ height: 40, width: 170, background: "rgba(0, 0, 0, 0)", marginBottom: 5, border: 'none', display: 'flex' }}
                                autoFocus />}
                    </Editable>

                    <Editable style={{ height: 67 }}>
                        {`${getString("start_date")}:`}
                        <DatePicker onChange={(e) => { this.setState({ startDate: e }) }} value={startDate} clearIcon={null} />
                    </Editable>

                    <Editable style={{ height: 67 }}>
                        {`${getString("end_date")}:`}
                        <DatePicker onChange={(e) => { this.setState({ endDate: e }) }} value={endDate} clearIcon={null} />
                    </Editable>

                    <Footer>
                        <FooterButton style={{ background: colors.green, color: "#fff", borderBottomRightRadius: 0 }} onClick={() => { closeHandler(name, startDate, endDate, action) }}>
                            {loading ? (
                                <Lottie
                                    options={defaultOptions}
                                    height={100}
                                    width={100}
                                />) : (
                                getString("save")
                            )}
                        </FooterButton>
                        <FooterButton style={{ background: colors.lipstick, color: "#fff", borderBottomLeftRadius: 0}} onClick={closeHandler}>
                            {getString("cancel")}
                        </FooterButton>
                    </Footer>
                </PopUp>
            </div >
        );
    }
}

const PopupTitle = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 1.6875em;
  line-height: 2.3125em;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0190110625em;
  text-transform: capitalize; 
  /* Main 1 */
  color: #536EEC;
`

const Editable = styled.div`
    margin-bottom: 25px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 304px;
    height: 52px;
    background: rgba(224, 231, 255, 0.2);
    border: 0.948611px solid #536EEC;
    box-sizing: border-box;
    border-radius: 4.74306px;
`

const Footer = styled.div`
    display: flex;
    position: absolute;
    bottom: -0.125em;
    left: -0.0625em;
    right: -0.0625em;
    height: 3.125em;
    box-shadow: 0em 0.070974375em 0.88718125em rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 9px 9px;
`

const FooterButton = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 2.6em;


    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.19643125em;
    line-height: 1.625em;
    text-align: center;
    letter-spacing: 0.03351575em;

    text-transform: capitalize; 
    border-radius: 0px 0px 9px 9px;

    flex: 1
`

export default AddAnnotationPopup;
