import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { getAsset } from "../utils";

class Input extends PureComponent {
    render() {
        const {
            type,
            value,
            onChange,
            placeholder,
            style,
            placeholderIcon
        } = this.props;
        return (
            <div style={{ position: 'relative' }}>
                {placeholderIcon &&
                    <img src={getAsset(`${placeholderIcon}`)}
                        style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)' }} />}
                <StyledInput type={type}
                    value={value}
                    onChange={(e) => { onChange(e.target.value) }}
                    placeholder={placeholder}
                    style={style} />
            </div>
        );
    }
}

Input.propTypes = {
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.string
};

Input.defaultProps = {
    type: PropTypes.string
}

const StyledInput = styled.input`
  background: #FFFFFF;
  height: 2.7em;
  /* Dim */

  border: 1px solid #909090;
  box-sizing: border-box;
  border-radius: 10px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.3125em;
  /* identical to box height, or 140% */

  text-align: center;

  /* Dim */

  color: #909090;
  outline: none;

  @media print{
    display: none;
  }
`

export default Input;