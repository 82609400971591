import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGroups, updateGroup, getGroupAdmins, createGroupAdmin, deleteGroupAdmin} from "../../api"
import {createGroup, deleteGroup, deleteGroupSdk} from "../../api/sdk";

export const getGroupsData = createAsyncThunk("groups/getGroupsData", async (data) => {
    const res = await getGroups()
    return res
})

export const createGroupData = createAsyncThunk("groups/createGroupData", async (data) => {
    const res = await createGroup(data)
    return res
})


export const updateGroupData = createAsyncThunk("groups/updateGroupData", async (data) => {
    const  {id, name, unitsLimit, active} = data
    const res = await updateGroup({id, name, unitsLimit, active})
    return res
})

export const getGroupAdminsData = createAsyncThunk("groups/getGroupAdminsData", async (groupId) => {
    const res = await getGroupAdmins(groupId)
    return {groupId, data: res.data}
})

export const createGroupAdminData = createAsyncThunk("groups/addGroupAdminData", async ({groupId, email}) => {
    const res = await createGroupAdmin({groupId, email})
    console.log("createGroupAdminData", res)
    return {...res, groupId}
})

export const deleteGroupAdminData = createAsyncThunk("groups/deleteGroupAdminData", async ({groupId, adminId}) => {
    const res = await deleteGroupAdmin({groupId, adminId})
    return {...res, groupId, adminId}
})

export const deleteGroupData = createAsyncThunk("groups/deleteGroupData", async (groupId) => {
    const res = await deleteGroup(groupId)
    const res2 = await deleteGroupSdk(groupId)
    return {...res, groupId}
})
