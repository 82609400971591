import { createListenerMiddleware } from '@reduxjs/toolkit'
import {setCurrentOrg, setClientApps} from "./index";
import {get} from "lodash";

export const orgListenerMiddleware = createListenerMiddleware()


orgListenerMiddleware.startListening({
    actionCreator: setCurrentOrg,
    effect: async (action, {dispatch, getState}) => {
        console.log('SET current org: ', action.payload)
        const org = action.payload;
        if(org){
            let orgClientApps = get(org, "OrgClientApps", null);
            if (orgClientApps && orgClientApps.length) {
                console.log("setting client apps: ", orgClientApps)
                dispatch(setClientApps(orgClientApps));
            }
        }
    }
})
