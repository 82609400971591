import React, {useState, useEffect} from 'react';
import PopUp from 'reactjs-popup';
import {getString} from "../../utils/translations";

import {Editable, Footer, FooterButton, PopupTitle} from "../Dashboard/VideoUploadPopup";
import colors from "../../styles/colors";
import Lottie from "react-lottie";
import {createGroupData, updateGroupData} from "../../redux/groups/api";
import {useDispatch, useSelector} from "react-redux";
import {createGroup} from "../../api";
import {
    ValueBoxMain,
    ValueBoxSecondary,
    ValueBoxSecondaryInput
} from "../../components/ClientAppsWizard/styledComponents";
import _ from "lodash";


function CreateGroupPopup ({isOpen, closeHandler, groupData}) {

    const [name, setName] = useState(groupData ? groupData.name :"");
    const [unitsLimit, setUnitsLimit] = useState(groupData ? _.get(groupData, "AIUnitsLimits[0].unitsLimit", null) : null);
    const [nameError, setNameError] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const groups = useSelector(state => state.groups.groups)

    const verifyName = () => {
        const nameExists = groups.some(group => {
            if(groupData && group.id === groupData.id) {
                return false;
            }
            return group.name === name
        });
        return !nameExists;
    }

    const submit = async () => {
        setLoading(true)
        if (!verifyName()) {
            setNameError(true);
            return;
        }
        setNameError(false);

        if(groupData){
            let res = await dispatch(updateGroupData({id: groupData.id, name, unitsLimit, active: groupData.active}))
            console.log(res);
        }else{
            const res = await dispatch(createGroupData({name, unitsLimit}))
            console.log(res);
            if(res && res.type === "groups/addGroupData/fulfilled"){
                console.log("res", res);
                if(res.payload.error){
                    setServerError(res.payload.error)
                    setLoading(false)
                    return;
                }
            }
        }

        setLoading(false)
        closeHandler();
    }


    return (
        <PopUp
            position="center"
            closeOnDocumentClick//={false}
            open={isOpen}
            onClose={closeHandler}
            contentStyle={{
                width: 335,
                borderRadius: 9,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: 75
            }}
        >
            <PopupTitle>{getString(groupData ? "update_group_popup_title" :"create_group_popup_title")}</PopupTitle>
            <div>{getString(groupData ? "update_group_popup_description" : "create_group_popup_description")}</div>
            <ValueBoxMain>{getString("group_name")}</ValueBoxMain>
            <ValueBoxSecondaryInput autoFocus value={name} onChange={e => setName(e.target.value)} onBlur={verifyName}/>

            <ValueBoxMain>{getString("ai_units_limit")}</ValueBoxMain>
            <ValueBoxSecondaryInput type={"number"} value={unitsLimit} onChange={e => setUnitsLimit(e.target.value)}/>

            {nameError && <div style={{color: colors.lipstick, fontSize: 12, marginTop: 10}}>{getString("name_error")}</div>}
            {serverError && <div style={{color: colors.lipstick, fontSize: 12, marginTop: 10}}>{serverError}</div>}

            <Footer>
                <FooterButton style={{background: colors.blue, color: "#fff"}}
                              onClick={submit}
                              disabled={nameError || !name}
                >
                    {getString(groupData ? "update_group" : "create_group")}
                </FooterButton>
            </Footer>
        </PopUp>
    );
}


export default CreateGroupPopup;
