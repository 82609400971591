import styled from "styled-components";
import { connect } from "react-redux";
import { get } from "lodash";
import { Fragment } from "react";
import moment from "moment"
import CreatableSelect from "../../../components/Tags/CreatableMultiSelect";
import { calcLastSeen, calcArrayOfTags } from "./utils"
import { getString } from "../../../utils/translations";
import Comment from "./Comment"
import { HOUSE_TYPES } from "../../../constants";

const Name = ({ row, showLastSeen, value, pinned, hasTagsInput, tagContext,
    updateTags, updateTableData, rows, currentHouse, contextId }) => {
    let lastSeen = ""
    let tags = []
    const id = get(row, contextId, "")
    let comment, renderComment//, id

    // if (get(row, "id", "") !== 'btn' &&
    //     (get(currentHouse, "type", "") === HOUSE_TYPES.upload
    //         || get(currentHouse, "type", "") === HOUSE_TYPES.realtime_video)) {
    //     renderComment = true
    //     comment = get(row, "comment", null)
    //     id = get(row, contextId, null)
    // }

    if (showLastSeen) {
        const lastSeenDate = get(row, "lastSeen", "")
        let sufficient = get(row, "sufficientData", null)
        if(!sufficient){
            lastSeen = moment(lastSeenDate).format("DD.MM.YYYY HH:mm:ss")
        }else{
            const days = moment().diff(lastSeenDate, 'days')
            if (days === 0 || days) {
                lastSeen = calcLastSeen(lastSeenDate, days)
            }
        }

    }

    if (hasTagsInput) {
        const tagsObj = get(row, "tags", null)
        if (tagsObj) {
            tags = calcArrayOfTags(tagsObj)
        }
    }

    return (
        <Fragment>
            <CellContent style={pinned ? { color: 'white' } : {}}>{value} </CellContent>
            {showLastSeen && (<LastSeen style={pinned ? { color: 'white' } : {}}>{lastSeen}</LastSeen>)}
            {!pinned && hasTagsInput
                && <div style={{ width: 170, marginLeft: 14 }} onClickCapture={e => e.nativeEvent.stopPropagation()}>
                    <CreatableSelect tags={tags} id={id} updateTags={updateTags} tagContext={tagContext} />
                </div>}
            {renderComment && <CommentContainer>
                <Comment id={id} comment={comment} rows={rows} trail={"comment"} updateTableData={updateTableData} />
            </CommentContainer>}
        </Fragment>
    );
}

const CellContent = styled.span`
    color: #2E384D;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9375em;
    line-height: 1.25em;
    display: flex;
    align-items: center;
    margin-left: 0.9375em;

  @media print{
    width: 55px;
    height: 55px;
    display: block;
    margin-left: 40px;
    margin-top: -30px;
    margin-right: -38px;
  }
`

const CommentContainer = styled.div`
    margin-left: 0.8em;
    margin-top: 0.1875em;
`

const LastSeen = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6875em;
    line-height: 1.5em;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-left: 1.25em;
    color: #2E384D;
    margin-top: 0.1875em;
`

const mapStateToProps = ({ table, house }) => ({
    showLastSeen: table.showLastSeen,
    hasTagsInput: table.hasTagsInput,
    currentHouse: house.currentHouse,
    contextId: table.contextId,
    tagContext: table.tagContext
});

export default connect(mapStateToProps)(Name);
