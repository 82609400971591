import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSessionTracksReport, getResidentSessionReport } from "../../api"
import _ from "lodash";
import { SCREENS } from "../../constants";
import { setMoodEnergyChartData, setStressMeterData, setWellbeingMeterData } from "../top"
import { parseContentTableData, parseContentTopData } from "../../parsers"

export const getContentTopData = createAsyncThunk('content/getTopData', async (data) => {
    const { startDate, endDate, compareTrendKey, residentId, sessionId } = data
    const res = await getResidentSessionReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    const parsedData = parseContentTopData(res.data)
    return parsedData
})

export const getContentTableData = createAsyncThunk('content/getTableData', async (data, { dispatch }) => {
    const { startDate, endDate, compareTrendKey, residentId, sessionId } = data
    const res = await getSessionTracksReport(startDate, endDate, compareTrendKey, residentId, sessionId)
    console.log("getContentTableData", res)
    const content = parseContentTableData(res.data)
    if (_.get(res, "data", null)) {
        dispatch(setMoodEnergyChartData({ data: content, context: SCREENS.CONTENT }))
        dispatch(setWellbeingMeterData(content))
        dispatch(setStressMeterData(content))
    }
    return content
})
