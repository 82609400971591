import styled from "styled-components";
import { connect } from "react-redux";
import Comment from "./Comment"
import { get } from "lodash";
import { getString } from "../../../utils/translations";
import CreatableSelect from "../../../components/Tags/CreatableMultiSelect";
import { calcArrayOfTags } from "./utils"
import { CONVERSION_FACTOR_CALMNESS } from "../../../api/remoteConfig";

const Type = ({ contextId, row, updateTableData, rows, hasTagsInput, updateTags, tagContext }) => {
    const comment = get(row, "comment", null)
    const id = get(row, contextId, null)
    const contentType = getString(`${get(row, "contentType", "")}`)

    let tags = []

    if (hasTagsInput) {
        const tagsObj = get(row, "tags", null)
        if (tagsObj) {
            tags = calcArrayOfTags(tagsObj)
        }
    }

    return (
        <CellContainer marginLeft={'25px'}>
            <div>
                <MediaType>
                    {contentType}
                </MediaType >
                {hasTagsInput
                    && <div style={{ width: 170 }} onClickCapture={e => e.nativeEvent.stopPropagation()}>
                        <CreatableSelect tags={tags} id={id} updateTags={updateTags} tagContext={tagContext} />
                    </div>}
                {/* <Comment id={id} comment={comment} rows={rows} trail={"comment"} updateTableData={updateTableData} /> */}
            </div>
        </CellContainer>
    );
}

const MediaType = styled.div`
    font-family: OpenSans-Bold;
    font-weight: 800;
    font-size: 18.4px;
    line-height: 25px;
    display: flex;
    text-transform: uppercase;

    color: #CC1D6C;
`

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.marginLeft};
  justify-content: ${props => props.contentPositioning};

  @media print{
    display: initial;
    }
`

const mapStateToProps = ({ table }) => ({
    contextId: table.contextId,
    hasTagsInput: table.hasTagsInput,
    tagContext: table.tagContext,
});

export default connect(mapStateToProps)(Type);