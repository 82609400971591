import styled from "styled-components";
import { getString } from "../../../utils/translations";

const Processing = ({ width }) => {
    return (
        <div
            style={{
                position: "absolute",
                width,
                height: 57,
                marginTop: -27,
            }}
        >
            <ProcessingData>{getString("processing_data")}</ProcessingData>
        </div>
    );
}

const ProcessingData = styled.span`
    justify-content: center;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 21px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #CC1D6C;
    margin-top: 5px;
`

export default Processing