import styled from "styled-components";
import moment from "moment"
import Session from "./Session"
import DateCell from "./DateCell"

const SecondCell = ({ row, value, data }) => {
    return (
        data.header === "date" ? <div style={{ display: 'flex', justifyContent: data.justifyContent }}>
            <DateCell value={moment(value).format("DD.MM.YYYY")} /></div>
            : (data.header === "capital_sessions" ? <Session row={row} value={value} data={data} />
                : <SimpleValue style={{ textAlign: data.textAlign }}>
                    <div style={{ fontSize: 15 }} >{data.header === "hour" ? moment(value).format("hh:mm A") : value}</div>
                </SimpleValue >)
    );
}

const SimpleValue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.375em;
  line-height: 1.375em;
  /* identical to box height, or 100% */

  text-align: center;
  color: #2E384D;
  position: relative;
`

export default SecondCell