import React, { Component } from 'react';

import CreatableSelect from 'react-select/creatable';
import { getString } from '../../utils/translations';
import { TAG_ACTIONS } from "../../constants";

import { components } from "react-select";
import { get } from "lodash";

import { saveTag, removeTag } from "../../api"

const MultiValueRemove = props => {
    return (
        <components.MultiValueRemove
            {...props}
            innerProps={{
                ...props.innerProps,
                onClickCapture: () => {
                    props.innerProps.onClick()
                }
            }}
        />
    );
};

export default class CreatableMulti extends Component {

    constructor() {
        super();
        this.state = {
            tags: null,
            defaultTags: []
        }
    }

    handleChange = async (newValue, actionMeta) => {
        const { tags } = this.state
        const { id, updateTags, tagContext } = this.props
        if (actionMeta.action === TAG_ACTIONS.ADD) {
            const addedValueObj = newValue.filter(n => tags.every(t => !t.label.includes(n.label)))
            this.setState({ tags: newValue })
            const addedValue = get(addedValueObj[0], "label", null)
            let res = await saveTag(id, addedValue, tagContext)
            let tagId = get(res, "TagId", null);
            updateTags(id, { label: addedValue, value: tagId }, actionMeta.action)
        }

        if (actionMeta.action === TAG_ACTIONS.REMOVE) {
            const { tagContext } = this.props
            const removedValueObj = tags.filter(t => newValue.every(n => !n.label.includes(t.label)))
            const removedValue = get(removedValueObj[0], "label", null)
            this.setState({ tags: newValue })
            let res = await removeTag(id, removedValue, tagContext)
            updateTags(id, { label: removedValue, value: res.id }, actionMeta.action)
        }
    };

    calcTagsArr = () => {
        const { tags } = this.props
        const defaultValues = []
        for (let t of tags) {
            defaultValues.push({ label: t.label, value: t.value })
        }
        return defaultValues
    }

    componentDidMount = () => {
        let defaultTags = this.calcTagsArr()
        this.setState({ tags: defaultTags, defaultTags })
    }

    styles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '25px',
            height: '30px',
            border: !state.isFocused && "none",
        }),

        container: (provided, state) => ({
            ...provided,
            fontSize: 11,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            padding: 0
        }),

        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '25px',
        }),

        clearIndicator: (provided, state) => ({
            ...provided,
            display: !state.isFocused && "none"
        }),
    };

    render() {

        const { defaultTags } = this.state

        return (
            <CreatableSelect
                placeholder={getString("add_tags")}
                value={defaultTags}
                isMulti
                onChange={this.handleChange}
                noOptionsMessage={() => null}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    MultiValueRemove
                }}
                styles={this.styles}
            />
        );
    }
}
