export const buildFeatureList = ({
                                     tags = false,
                                     exportList = false,
                                     contentLayer = false,
                                     generateApiKey = false,
                                     showType = true,
                                     showArtist = true,
                                     hasBaseline = true,
                                     exportContentTable = false,
                                     billingScreen= false,
                                     groupManagement = false
}) => {
    return {
        tags,
        exportList,
        contentLayer,
        generateApiKey,
        showType,
        showArtist,
        hasBaseline,
        exportContentTable,
        billingScreen,
        groupManagement
    }
}

