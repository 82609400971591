import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Lottie from 'react-lottie';

import InputWithLabel from "../../components/InputWithLabel";
import Button from "../../components/Button";
import typography from "../../styles/typography";

import { isMobile, getAsset, validateEmail } from "../../utils";

import { login, sendPasswordReset } from "../../api"

import solo from '../../lotties/solo_waving.json'

import Popup from "../../components/Popup";
import BlockedPopup from "../../components/BlockedPopup";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: solo,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid'
    }
};

const isMobileDevice = isMobile()

class Login extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            passwordError: "",
            emailError: "",
            loginError: "",
            loading: false,
            isForgotPassword: false,
            resetPasswordOpen: false
        }
    }

    set = (key, value) => {
        this.setState({ [key]: value })
    }

    validateForm = () => {
        const {
            email,
            password,
            isForgotPassword,
        } = this.state;
        let isValid = true
        if (!email) {
            isValid = false;
            this.setState({ emailError: "Required" })
        } else if (!validateEmail(email)) {
            isValid = false;
            this.setState({ emailError: "Invalid email address" })
        }
        if (!isForgotPassword) {
            if (!password) {
                isValid = false;
                this.setState({ passwordError: "Required" })
            }
        }
        return isValid
    }

    submit = async () => {
        const {
            email,
            password,
            isForgotPassword
        } = this.state;

        if (!this.validateForm()) {
            return;
        }

        this.setState({ loading: true })

        if (isForgotPassword) {
            const redirectURL = window.location.origin
            await sendPasswordReset(email, redirectURL)
            this.setState({
                loading: false,
                resetPasswordOpen: true
            })
        }

        else {
            let res = await login(email, password);
            this.setState({ loading: false })
            if (!(res && res.success)) {
                let error = res && res.error ? res.error : "login error";
                this.setState({ error })
                if (res.error && res.error === "The password is invalid or the user does not have a password.") {
                    this.setState({ loginError: "Wrong username or password!" })
                }
            } else {
                this.props.onLoginValidation(res)
            }
        }
    }

    forgotPassword = () => {
        const {
            isForgotPassword
        } = this.state;

        const currentState = isForgotPassword;
        this.setState({ isForgotPassword: !currentState })
    }

    renderMobileLayout = () => {
        //TODO
        return this.renderDesktopLayout(true)
    }

    closeResetPasswordPopup = () => {
        this.setState({ resetPasswordOpen: false })
    }

    renderDesktopLayout = (hideBG = false) => {
        const {
            email,
            password,
            emailError,
            loginError,
            loading,
            passwordError,
            isForgotPassword,
            resetPasswordOpen
        } = this.state;

        const {ipError} = this.props;


        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <DesktopFormContainer style={{ flex: 1 }}>

                    <img style={{ marginLeft: "10%", alignSelf: "flex-start" }} src={getAsset("images/solo_logo.svg")} />

                    <div style={{ marginLeft: "10%", marginBottom: 26, alignSelf: "flex-start" }}>
                        <Slogen>emotional wellbeing</Slogen>
                        <SlogenBold>dashboard</SlogenBold>
                    </div>

                    <LoginForm
                        email={email}
                        setEmail={(value) => { this.set("email", value); this.setState({ emailError: "", loginError: "" }) }}
                        password={password}
                        setPassword={(value) => { this.set("password", value); this.setState({ passwordError: "", loginError: "" }) }}
                        error={loginError}
                        emailError={emailError}
                        passwordError={passwordError || ipError ? "You don't have access from this device" : ""}
                        loading={loading}
                        submit={this.submit}
                        forgotPassword={this.forgotPassword}
                        isForgotPassword={isForgotPassword}
                    />
                </DesktopFormContainer>
                {hideBG ? null : (
                    <BG>

                        <Lottie
                            options={defaultOptions}
                            width={"80%"}
                        />
                    </BG>

                )}

                {resetPasswordOpen ? (
                    <Popup
                        isOpen={resetPasswordOpen}
                        closeHandler={this.closeResetPasswordPopup}
                        closeOnDocumentClick={false}
                        title={"Reset Your Password"}
                        description={"Check your email for a reset password link!"}
                        footerButtons={[{ label: "Okay", onPress: this.closeResetPasswordPopup }]}
                    />
                ) : null}

                <BlockedPopup
                    open={this.props.aiUnitsLimitReached}
                    closeHandler={()=>{}}
                />
            </div>
        )

    }

    render() {

        if (isMobileDevice) {
            return this.renderMobileLayout()
        }

        return this.renderDesktopLayout()
    }
}

const LoginForm = ({ email, setEmail, emailError, password, passwordError, setPassword, submit, loading, error, forgotPassword, isForgotPassword }) => {

    return (
        <div style={{ width: "80%" }}>
            <InputWithLabel
                wrapperStyle={{ width: "100%", marginTop: 23 }}
                inputStyle={{ width: "100%", border: "1px solid #e0e7ff", backgroundColor: "rgba(224, 231, 255, 0.2)", borderRadius: 4.4 }}
                type={"email"}
                value={email}
                onChange={setEmail}
                label={"E-MAIL"}
                error={emailError}
            />

            {!isForgotPassword && <InputWithLabel
                wrapperStyle={{ width: "100%", marginTop: 23 }}
                inputStyle={{ width: "100%", border: "1px solid #e0e7ff", backgroundColor: "rgba(224, 231, 255, 0.2)", borderRadius: 4.4 }}
                label={"PASSWORD"}
                value={password}
                onChange={setPassword}
                type={"password"}
                error={passwordError}
            />}

            {error && (<div style={typography.error}>{error}</div>)}

            {isForgotPassword ? <Button style={{ width: "100%", marginTop: 38 }} onClick={submit} loading={loading}>
                RESET PASSWORD
            </Button> :
                <Button style={{ width: "100%", marginTop: 38 }} onClick={submit} loading={loading}>
                    Login
                </Button>
            }

            <div style={{ marginTop: 22 }}>
                {isForgotPassword ? <BtnTitle> Want to sign in? </BtnTitle> : <BtnTitle> Forgot your password? </BtnTitle>}
                <ClickHereBtn onClick={forgotPassword}>click here</ClickHereBtn>
            </div>

        </div >
    )
}

const BG = styled.div`
  background: linear-gradient(180deg, #516BE6 19.79%, #BECAFF 100%);
  border-radius: 200px 0px 0px 0px;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`

const RobotBG = styled.div`
  width: 28.1875em;
  height: 27.375em;
  background: rgba(245, 246, 250, 0.25);
  box-shadow: 0em 0em 6.25em #F5F6FA;
  border-radius: 50%;,
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
`

const DesktopFormContainer = styled.div`
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

const Slogen = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 2.0158em;
  line-height: 1.25em;
  /* or 113% */
  
  color: #2E384D;
`
const SlogenBold = styled.div`
  font-family: Open Sans;
  font-weight: 800;
  font-size: 2.0158em;
  line-height: 1.25em;
  /* or 113% */

  color: #2E384D;
`
const BtnTitle = styled.span`
    color: #B0BAC9;
    margin-left: 1.375em;
    margin-right: 2.0625em;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 0.889325em;
    line-height: 1.3125em;
`

const ClickHereBtn = styled.span`
    color: #2E5BFF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 0.889325em;
    line-height: 1.1875em;
    cursor: pointer;
`

Login.propTypes = {
    onLoginValidation: PropTypes.func
};

export default Login;
