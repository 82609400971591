import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from "styled-components";

import QuickDateFilter from "../../components/QuickDateFilter"
import DatePicker from "../../components/DatePicker";
import colors from "../../styles/colors";
import Button from "../../components/Button";
import { SCREENS } from "../../constants"

import { getAsset } from "../../utils";
import { translations, getString } from "../../utils/translations";

import { connect } from "react-redux";
import {isSdk} from "../../api";

class Header extends Component {

  translate = () => {
    const { translate } = this.props
    translate()
  }

  currentLangVal = () => {
    let otherLocale = translations.supportedLanguages.find(l => l.key !== translations.selectedLocale);
    return otherLocale ? otherLocale.name : translations.fallbackLocale;
  }

  render() {
    const {
      selectedTimeFilter,
      onTimeFilterChange,
      onDateRangeChange,
      onLogout,
      onSettings,
      isClickable,
      onScreenChange,
      currentLanguage,
        context
    } = this.props;



    return (
      <Container>
        <RowContainer>
          <Solo onClick={() => onScreenChange(SCREENS.USERS)}>SOLO</Solo>
          <Slogen>{isSdk() ? getString("sdk_dashboard_slogan") : context === SCREENS.VIDEO ? getString("video_dashboard_slogan") : getString("dashboard_slogan")}</Slogen>
        </RowContainer>


        <RowContainer style={{ alignItems: "center" }}>
          <DatePicker
            onDatesChange={onDateRangeChange}
            wrapperStyle={{ marginRight: 50, cursor: "pointer" }}
          />
          <QuickDateFilter value={selectedTimeFilter} onChange={onTimeFilterChange} isClickable={isClickable} currentLang={currentLanguage} />

          {/* <Button style={buttonStyle}
            onClick={this.translate}>
            <ButtonTitle>{this.currentLangVal()}</ButtonTitle>
          </Button> */}

          <div style={{ marginLeft: 68 }}>
            <IconButton src={getAsset("icons/settings.svg")} onClick={onSettings} />
          </div>
          {/* <IconButton src={getAsset("icons/logout.svg")} onClick={onLogout} /> */}

        </RowContainer>
      </Container >
    );
  }
}

const mapStateToProps = ({ translations, date }) => ({
  currentLanguage: translations.currentLanguage,
  selectedTimeFilter: date.selectedTimeFilter
});

export default connect(mapStateToProps)(Header);

Header.propTypes = {
  selectedTimeFilter: PropTypes.string,
  onTimeFilterChange: PropTypes.func,
  onDateRangeChange: PropTypes.func
};

const buttonStyle = {
  width: 63,
  marginRight: -5,
  borderRadius: 10,
  marginLeft: 68,
  padding: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const Container = styled.div`
  background: #FFFFFF;
  height: 5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.9375em;
  padding-left: 0.9375em;
  border-bottom: 1px solid #e4e8f0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;

  @media print{
    position: relative;
  }
`

const RowContainer = styled.div`
  display: flex;
  align-items: baseline;

  @media print{
    display: initial;
    margin: 7px;
  }

  @media (max-width: 1086px){
    align-items: center;
  }
`

const Solo = styled.div`
  font-family: OpenSans;
  font-style: normal;
  font-weight: 800;
  font-size: 2.1875em;
  line-height: 1.8125em;
  /* or 83% */

  display: flex;
  align-items: center;
  letter-spacing: -0.06em;
  color: ${colors.lipstick};
  cursor: pointer;

  @media (max-width: 1086px){
    line-height: 5em;
  }
`

const Slogen = styled.div`
  font-family: OpenSans;
  font-style: normal;
  font-weight: 800;
  font-size: 1.1875em;
  line-height: 1.625em;
  
  margin-left: 0.4em;
  letter-spacing: -0.0296875em;

  color: #2E384D;

  @media (max-width: 1086px){
    // line-height: 1em;
    display: none;
  }

  @media (max-width: 1200px){
    line-height: 1em;
  }
  `

const IconButton = styled.img`
  cursor: pointer;
  margin-left: 1.5625em;

  @media print{
    display: none;
  }

  @media (max-width: 1200px){
    margin-left: 0.7em;
    margin-right: -0.4em;
  }
`

const ButtonTitle = styled.div`
  font-weight: bold;
  line-height: 1.25em;
  letter-spacing: 0.026475625em;
  color: #FFFFFF;
`
