const apiUrl = process.env.REACT_APP_API_URL
const soloAPIUrl = process.env.REACT_APP_SOLO_API_URL

console.log("process.env.REACT_APP_SOLO_API_URL", process.env.REACT_APP_SOLO_API_URL)
export const routes = {

    adminHouses: apiUrl + "/api/v1/dashboard/admin-groups",
    houseReport: (startDate, endDate, compareTrendKey, tags, tagFilteringMode, timeZone) => apiUrl + `/api/v1/dashboard/group?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}&tz=${timeZone}`,
    houseResidentsReport: (startDate, endDate, compareTrendKey, tags, tagFilteringMode, timeZone) => apiUrl + `/api/v1/dashboard/users?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}&tz=${timeZone}`,
    residentReport: (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode, timeZone) => apiUrl + `/api/v1/dashboard/user/${residentId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}&tz=${timeZone}`,
    residentSessionsReport: (startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode, timeZone) => apiUrl + `/api/v1/dashboard/user-sessions/${residentId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}&tags=${JSON.stringify(tags)}&tagFilteringMode=${tagFilteringMode}&tz=${timeZone}`,
    residentSessionReport: (startDate, endDate, compareTrendKey, residentId, sessionId) => apiUrl + `/api/v1/dashboard/user-session/${residentId}/${sessionId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}`,
    sessionTracksReport: (startDate, endDate, compareTrendKey, residentId, sessionId) => apiUrl + `/api/v1/dashboard/session-tracks/${residentId}/${sessionId}?startDate=${startDate}&endDate=${endDate}&compareTrendKey=${compareTrendKey}`,
    tableOverTimeData: (sessionId, contentId) => apiUrl + `/api/v1/dashboard/table-row/overtime/${sessionId}/${contentId}`,

    houseLogin: apiUrl + "/api/v1/auth/group-admin-login",
    houseLogout: apiUrl + "/api/v1/auth/group-admin-logout",
    orgLogin: apiUrl + "/api/v1/auth/org-admin-login",
    generateAPIKey: apiUrl + `/api/v1/auth/create-client`,
    updateClientApp:  (id) => apiUrl + `/api/v1/auth/update-client-app/${id}`,
    deleteClientApp: (id) => apiUrl + `/api/v1/auth/delete-client/${id}`,

    exportSessions: (startDate, endDate, tags, tz, tagFilteringMode, userId) => apiUrl + `/api/v1/dashboard/export-sessions?startDate=${startDate}&endDate=${endDate}&tags=${JSON.stringify(tags)}&tz=${tz}${userId ? `&userId=${userId}` : ""}&tagFilteringMode=${tagFilteringMode}`,
    exportSessionData: (sessionId, tz) => apiUrl + `/api/v1/dashboard/export-session-data?sessionId=${sessionId}&tz=${tz}`,
    exportContentData: (sessionId, contentId, tz) => apiUrl + `/api/v1/dashboard/export-content-data?sessionId=${sessionId}&contentId=${contentId}&tz=${tz}`,
    exportBillingDailySessions: (startDate, endDate, tz, clientAppId) => apiUrl + `/api/v1/dashboard/billing/export-daily-sessions?startDate=${startDate}&endDate=${endDate}&tz=${tz}&clientAppId=${clientAppId}`,
    exportBillingTableSessions: (startDate, endDate, tz, clientAppId) => apiUrl + `/api/v1/dashboard/billing/export-table?startDate=${startDate}&endDate=${endDate}&tz=${tz}&clientAppId=${clientAppId}`,
    dashboardTags: (context) => apiUrl + `/api/v1/dashboard/tags/${context}`,


    addUserTag: apiUrl + "/api/v1/dashboard/add-user-tag",
    addSessionTag: apiUrl + "/api/v1/dashboard/add-session-tag",

    deleteUserTag: apiUrl + "/api/v1/dashboard/delete-user-tag",
    deleteSessionTag: apiUrl + "/api/v1/dashboard/delete-session-tag",

    //TODO
    residentComment: apiUrl + "/api/v1/dashboard/resident-comment",
    sessionComment: apiUrl + "/api/v1/dashboard/session-comment",
    loginValidation: apiUrl + `/api/v1/auth/loginValidation`,

    getBillingCount: (startDate, endDate, timeZone, clientAppId) => apiUrl + `/api/v1/dashboard/billing/monthly-sessions?startDate=${startDate}&endDate=${endDate}&tz=${timeZone}&clientAppId=${clientAppId}`,

    updateGroup: (id) => apiUrl + `/api/v1/dashboard/org-group/${id}`,
    getGroups:  apiUrl + "/api/v1/dashboard/org-groups",
    deleteGroupSdk: (id) => apiUrl + `/api/v1/dashboard/org-group/${id}`,

    createGroup:  soloAPIUrl + "/api/sdk/house",
    deleteGroupSolo:  (id) => soloAPIUrl + `/api/sdk/house/${id}`,
    getGroupAdmins: (id) => soloAPIUrl + `/api/sdk/house/${id}/admins`,
    createGroupAdmin: (id) => soloAPIUrl + `/api/sdk/house/${id}/admin`,
    deleteGroupAdmin:  (id) => soloAPIUrl + `/api/sdk/house/admin/${id}`,


}
