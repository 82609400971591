import React, {Component, useRef, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTable, useSortBy, useGlobalFilter, useAsyncDebounce, useExpanded} from 'react-table'
import memoize from 'memoize-one';import _, {get, findIndex} from "lodash";
import styled from "styled-components";
import {getAsset} from "../../utils";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import moment from "moment"

import {getString, translations} from "../../utils/translations";
import {useSelector} from 'react-redux';
import Input from "../../components/Input";


import {
    RESIDENT_TYPE, REQUEST_DATE_FORMAT, BILLING_UNIT_TYPES,
} from "../../constants";

import {calcSufficientData} from "../../redux/table/utils"
import {connect} from "react-redux";

import features from "../../features";
import {getTableOverTimeData, getTableVideoData} from '../../redux/table/api';
import {
    setSelectedOvertimeButtons, setTableOvertime, removeFromTable, editTableRow,
    setShowInsufficientData, pushToTableData
} from "../../redux/table"

import {formatMinutes, normalizedPercentage, getEmotionKey, isCheckup, getColor, format} from "../Dashboard/TableData/utils"


import Minutes from '../Dashboard/TableData/Minutes';
import Icon from '../Dashboard/TableData/Icon';
import Name from '../Dashboard/TableData/Name';

import DateCell from '../Dashboard/TableData/DateCell';
import {Fragment} from 'react';

import Button from "../../components/Button";


function GlobalFilter({
                          preGlobalFilteredRows,
                          globalFilter,
                          setGlobalFilter,
                          currPlaceholder
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)
    return (
        <div style={{display: "flex"}}>
            <Input
                type={"text"}
                value={value}
                onChange={(value) => {
                    setValue(value);
                    onChange(value);
                }}
                placeholder={getString(`${currPlaceholder}`)}
                placeholderIcon={"icons/search.svg"}
                style={{width: 200}}
            />
        </div>
    )
}


const TableData = ({
                       data,
                       headerTitle,
                       currPlaceholder,
                       rowsIcon,
                       colData,
                       isShowSeconds,
                       isDesc,
                       sortBy,
                       onExportDailySessions,
                       type,
                       unitType
                   }) => {

    const [tableRows, setTableRows] = useState([])

    console.log("TableData", type)


    let COLUMNS = [
        {
            id: "date",
            Header: type === "month" ? getString(`month`) : getString(`date`),
            Cell: ({row, value}) => {
                let pinned = null

                // console.log("date", value)
                let formattedDate = moment(value).format("DD.MM.YYYY");
                if(type === "month"){
                    formattedDate = value
                }


                return (
                    <CellContainer contentPositioning={rowsIcon ? "start" : "center"}
                                   marginLeft={rowsIcon ? '10px' : null}>
                        <Icon pinned={pinned} row={row}/>
                        <div style={{maxWidth: '75%'}}>
                            <DateCell value={formattedDate}/>

                        </div>
                    </CellContainer>
                )
            },
            accessor: (originalRow) => {
                //  console.log("date accessor", originalRow, get(originalRow, "date", 0))
                return get(originalRow, "date", 0)
            },
            sortType: (rowA, rowB, columnId) => {
                if(type === "month"){
                    let dateA = rowA.original.realDate
                    let dateB = rowB.original.realDate

                    if (dateA > dateB) {
                        return 1
                    }
                    if (dateA < dateB) {
                        return -1
                    }
                    return 0
                }else {
                    let dateA = new Date(rowA.original.date)
                    let dateB = new Date(rowB.original.date)
                    if (dateA > dateB) {
                        return 1
                    }
                    if (dateA < dateB) {
                        return -1
                    }
                    return 0
                }
            }
        },
        {
            id: "SESSIONS",
            Header: getString("sessions"),
            accessor: (originalRow, rowIndex) => {
                const sessions = get(originalRow, "sessions", 0)
                return sessions
            },
            Cell: ({row, value}) => {
                return (
                    <Minutes value={value} item={row.original}/>
                )
            }
        },
        {
            id: "AI_UNITS",
            Header: getString(`${unitType.toLowerCase()}_unit_type`),
            accessor: (originalRow, rowIndex) => {
                const duration = get(originalRow, "pricingUnits", 0)
                return duration
            },
            Cell: ({row, value}) => {
                return (
                    <Minutes value={value} item={row.original}/>
                )
            }
        },
    ];
    if(type === "month"){
       /* COLUMNS.push({
            id: "unitPrice",
            Header: getString("unitPrice"),
            accessor: (originalRow, rowIndex) => {
                const unitPrice = get(originalRow, "unitPrice", 0)
                return unitPrice
            },
            Cell: ({row, value}) => {
                return (
                    <Minutes value={value} item={row.original}/>
                )
            }
        })*/

        COLUMNS.push({
            id: "totalPrice",
            Header: getString("totalPrice"),
            accessor: (originalRow, rowIndex) => {
                const totalPrice = get(originalRow, "totalPrice", 0)
                return totalPrice ? totalPrice.toFixed(2) : 0
            },
            Cell: ({row, value}) => {
                const currency = get(row, "original.currency", "") === "jpy" ? "¥" : "$"
                return (
                    <Minutes value={`${currency}${value}`} item={row.original}/>
                )
            }
        })
    }
    const columns = React.useMemo(
        () => COLUMNS, []
    )



    const tableData = React.useMemo(
        () => data,
        [data]
    )

    const tableInstance = useTable({
        columns, data: tableData, initialState:
            sortBy ? {
                sortBy: [
                    {
                        id: sortBy,
                        desc: isDesc
                    }
                ]
            } : {}
    }, useGlobalFilter, useSortBy, useExpanded)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        preGlobalFilteredRows,
        setGlobalFilter,
        state
    } = tableInstance

    useEffect(() => {
         setTableRows(rows)
    }, [rows])

    const TableHeaderWrapper = () => {
        return (
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}
                >
                    {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                                textAlign: 'center',
                                border: 'none',
                                color: column.isSorted ? colors.lipstick : '#909090',
                                fontWeight: column.isSorted ? "800" : "700",
                                fontFamily: fonts.OpenSans,
                                fontSize: 13,
                                letterSpacing: 1.12,
                                cursor: "pointer"
                            }}
                        >
                            {column.render('Header')}
                            {/* Add a sort direction indicator */}
                            <div style={column.id === colData[1].header ? {
                                textAlign: colData[1].textAlign,
                                margin: colData[1].margin
                            } : {}}>
                                {
                                    column.id === 'TYPE' || column.id === 'EXPANDER' ? null : column.isSorted
                                        ? column.isSortedDesc
                                            ? <img src={getAsset("icons/arrow_blue.svg")}/>
                                            : <img style={{transform: "rotate(180deg)"}}
                                                   src={getAsset("icons/arrow_blue.svg")}/>
                                        : <img src={getAsset("icons/arrow_blue.svg")}/>
                                }
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
        )
    }

    const getCellStyle = (cell) => {
        let cellStyle = {}
        return cellStyle
    }


    const onRowClicked = (row) => {
        let rowId = get(row, "original.id", "");
        console.log("onRowClicked", rowId)
    }

    const TableBodyWrapper = () => {
       // console.log("TableBodyWrapper", rows)
        return (
            <tbody {...getTableBodyProps()}>

            {rows.map((row, i) => {
                let rowId = get(row, "original.id", "");


                prepareRow(row)
                return (
                    <Fragment key={i}>
                        <tr className="tbl-row" {...row.getRowProps()}
                            onClick={() => onRowClicked(row)}
                            style={{padding: 15}}>
                            {row.cells.map(cell => {
                                const cellStyle = getCellStyle(cell)
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={cellStyle}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                            <td style={{textAlign: 'right', padding: 0, paddingRight: 15}}>
                                <Button
                                    style={{
                                        background: colors.lipstick,
                                        paddingLeft: 15,
                                        paddingRight: 15,
                                        height: 35,
                                        borderRadius: 10,
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                    }}
                                    onClick={()=>{onExportDailySessions(row.original.date)}}>
                                    <span>{getString("export")}</span>
                                </Button>
                            </td>
                        </tr>

                    </Fragment>
                )
            })}
            </tbody>
        )
    }

    return (
        <Styles>
            <Header>
                <div style={{display: "flex", alignItems: "baseline"}}>
                    <Title>{getString(`${headerTitle}`)}</Title>
                  {/*  <Count>{data.length} {getString("total")}</Count>*/}
                </div>

                <div style={{display: "flex", flexDirection: 'row'}}>

                    <div>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            currPlaceholder={currPlaceholder}
                        />
                    </div>
                </div>
            </Header>
            <table {...getTableProps()} style={{
                borderCollapse: "separate",
                borderSpacing: "0 1em"
            }}>
                <TableHeaderWrapper/>
                <TableBodyWrapper/>
            </table>


        </Styles>
    )
}

const mapDispatchToProps = {
    setSelectedOvertimeButtons,
    setShowInsufficientData,
    setTableOvertime,
    getTableOverTimeData,
    getTableVideoData,
    removeFromTable,

    pushToTableData,
    editTableRow,


};

const mapStateToProps = ({table, house, date, video}) => ({
    currentHouse: house.currentHouse,

    showInsufficientData: table.showInsufficientData,
    currPlaceholder: table.currPlaceholder,
    hasNext: table.hasNext,
    columnValue: table.columnValue,
    rowsIcon: table.rowsIcon,
    colData: table.colData,
    isShowSeconds: table.isShowSeconds,
    isDesc: table.isDesc,
    normalizeDataBySeconds: table.normalizeDataBySeconds,
    sortBy: table.sortBy,
    contentType: table.contentType,
    showLastSeen: table.showLastSeen,
    pinnedResidents: table.pinnedResidents,
    tableOvertime: table.tableOvertime,
    tableVideo: table.tableVideo,
    selectedOvertimeButtons: table.selectedOvertimeButtons,
    loadingRowOvertime: table.loadingRowOvertime,
    uploadButton: table.uploadButton,
    videoInExpander: table.videoInExpander,
    subRowControls: table.subRowControls,
    exportInRow: table.exportInRow,
    startDate: date.startDate,
    endDate: date.endDate,
    useValencePS: table.useValencePS,
    videoReady: video.videoReady
});

export default connect(mapStateToProps, mapDispatchToProps)(TableData);

TableData.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        calmness: PropTypes.number,
        emotional: PropTypes.number,
        energy: PropTypes.number,
        happiness: PropTypes.number,
        sessions: PropTypes.number,
        valence: PropTypes.number,
        resident: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            photoUrl: PropTypes.string
        })
    }))
};


const BlackVIcon = styled.img`
    width: 17px;
    height: 17px;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
`

const Header = styled.div`
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            `

const SubRowContainer = styled.div`
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: white;
            border-radius: 8.44px;
            display: flex;
            justify-content: center;
            `

const VideoNameContainer = styled.div`
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
            `

const VideoName = styled.div`
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;

            color: #2E384D;
            `

const DeleteVideo = styled.div`
            margin-top: 5px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            text-transform: uppercase;
            justify-content: center;
            color: #CC1D6C;
            cursor: pointer;
            text-transform: uppercase;
            `

const Title = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: 300;
            font-size: 1.75em;
            line-height: 1.25em;
            /* identical to box height, or 114% */

            color: #2E384D;
            `

const Count = styled.div`
            font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 0.9375em;
            line-height: 1.375em;
            /* identical to box height, or 147% */
            color: #8798AD;
            margin-left: 0.625em;
            `

const CellContainer = styled.div`
            display: flex;
            align-items: center;
            margin-left: ${props => props.marginLeft};
            justify-content: ${props => props.contentPositioning};

            @media print{
                display: initial;
    }
            `

const CellContent = styled.span`
            //   font-family: Rubik;
            //   font-style: normal;
            //   font-weight: 500;
            //   font-size: 15px;
            //   line-height: 18px;
            //   margin-left: 22px;

            color: #2E384D;

            font-family: Open Sans;
            font-style: normal;
            font-weight: 800;
            font-size: 0.9375em;
            line-height: 1.25em;
            display: flex;
            align-items: center;
            margin-left: 0.9375em;

            @media print{
                width: 55px;
            height: 55px;
            display: block;
            margin-left: 40px;
            margin-top: -30px;
            margin-right: -38px;
  }
            `

const OverTimeButton = styled.button`
            background: #909090;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            padding-left: 13px;
            padding-right: 13px;

            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            width: 98px;
            height: 35px;

            color: #FFFFFF;
            cursor: pointer;
            border: 0;
            outline: 0;
            `

const NextScreenArrow = styled.img`
            margin-right: 1.25em;
            margin-left: -1.5625em;
            vertical-align: middle;
            `


const Styles = styled.div`
            margin-top: 2.75em;

            /* This is required to make the table full-width */
            display: block;
            max-width: 100%;

            /* This will make the table scrollable when it gets too small */

            .tableWrap {
                display: block;
            max-width: 100%;
            //overflow-x: scroll;
            // overflow-y: hidden;
            border-bottom: 1px solid black;
  }

            table {
                /* Make sure the inner table is always as wide as needed */
                width: 100%;
            border-spacing: 0 18px;
            margin-top: 1.4375em;
            //max-height: 6.25em;
            position: relative;
            //   border-collapse: collapse;
            //border-collapse: collapse !important;
            tbody {
                tr {
                background: #fff;
            border-radius: 10px;
            border: 1px solid rgba(46, 91, 255, 0.08);
            padding: 1.25em;
      }
    }

            th {
                //position: sticky;
                //top: 0; /* Don't forget this, required for the stickiness */
                background - color: #f4f6fc;
            z-index: 50;
    }

            th,
            td {
                margin: 0;
            padding: 0.5rem;

            /* The secret sauce */
            /* Each cell should grow equally */
            width: 1%;
            /* But "collapsed" cells should be as small as possible */

            &.collapse {
                width: 0.0000000001%;
    }

            :last-child {
                border - right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
      }

            :first-child {
                border - top - left - radius: 10px;
            border-bottom-left-radius: 10px;
      }
    }
  }

            .pagination {
                padding: 0.5rem;
  }

            .tbl-row {
                transition: box-shadow .3s;
            cursor: pointer;
  }
            .tbl-row:hover {
                box - shadow: 0 0 0.6875em rgba(33,33,33,.2);
  }

            @media print {
    .tbl - head {
                display: table-header-group;
    }
            .tbl-body {
                display: table-row-group;
    }
            .tbl-row {
                display: inline-block;
            width: 100%;
            page-break-inside: avoid;
            padding: 10px !important;
            position: relative;
            right: 0.625em;
    }
  }
            `

//get array of hh:mm:ss:xx strings from duration and fps
const getTimeArray = (duration, fps) => {
    let timeArray = [];
    let time = 0;
    let fpsDuration = 1 / fps;
    while (time < duration) {
        timeArray.push(formatTime(time));
        time += fpsDuration;
    }
    return timeArray;
}

// format seconds to hh:mm:ss.xxx string
const formatTime = (seconds) => {
    let h = Math.floor(seconds / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    let x = Math.floor((seconds % 1) * 100);
    return `${h}:${m}:${s}.${x}`;
}

//convert hh:mm:ss.xxx string to seconds
const stringToSeconds = (string) => {
    let parts = string.split('.');
    let milliseconds = parts[parts.length - 1]
    parts = parts[0].split(":")
    let seconds = 0;
    seconds += parseInt(parts[0]) * 3600;
    seconds += parseInt(parts[1]) * 60;
    seconds += parseInt(parts[2]);
    seconds += parseInt(milliseconds) / 100;
    return seconds;
}
