import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

class Button extends PureComponent {
  render() {

    const { onClick, disabled, loading, loader, style } = this.props;
    return (
      <StyledButton disabled={loading || disabled} onClick={onClick} style={style}>
        {loading ? (loader) : this.props.children}
      </StyledButton>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loader: PropTypes.any
};

Button.defaultProps = {
  disabled: false,
  loading: false
}

const StyledButton = styled.button`
  background: #536EEC;
  border-radius: 4px;
  border: 0;
  outline: 0;
  height: 2.5em;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9375em;
  line-height: 1.3125em;
  /* identical to box height, or 140% */

  text-align: center;

  color: #FFFFFF;
  padding-left: 1.1875em;
  padding-right: 1.1875em;

  cursor: pointer;
  
  @media print{
    display: none;
  }
`

export default Button;
