import { createSlice } from '@reduxjs/toolkit';
import {
    getGroupAdminsData,
    getGroupsData,
    createGroupAdminData,
    createGroupData,
    deleteGroupAdminData,
    updateGroupData, deleteGroupData
} from "./api";


export const groupsSlice = createSlice({
    name: 'groups',
    initialState: {
        loading: false,
        groups: [],
        groupsAdmins: {}
    },
    reducers: {

    },
    extraReducers: {
        [getGroupsData.pending]: (state, { payload }) => {
            console.log("getGroupsData.pending")
            state.loading = true;
        },
        [getGroupsData.rejected]: (state, { payload }) => {
            console.log("getGroupsData.rejected")
            state.loading = false;
        },
        [getGroupsData.fulfilled]: (state, { payload }) => {
            console.log("getBillingData.fulfilled")
            state.loading = false;
            state.groups = payload.data;
        },
        [getGroupAdminsData.fulfilled]: (state, { payload }) => {
            console.log("getGroupAdminsData.fulfilled")
            state.groupsAdmins[payload.groupId] = payload.data;
        },
        [createGroupAdminData.fulfilled]: (state, { payload }) => {
            console.log("createGroupAdminData.fulfilled")
            if(!state.groupsAdmins[payload.groupId]){
                state.groupsAdmins[payload.groupId] = [];
            }
            state.groupsAdmins[payload.groupId].push(payload);
        },
        [deleteGroupAdminData.fulfilled]: (state, { payload }) => {
            console.log("deleteGroupAdminData.fulfilled")
            if(payload.success){
                state.groupsAdmins[payload.groupId] = state.groupsAdmins[payload.groupId].filter(admin => admin.id !== payload.adminId);
            }
        },
        [createGroupData.fulfilled]: (state, { payload }) => {
            console.log("createGroupData.fulfilled")
            state.groups.push(payload.sdkGroup);
        },
        [updateGroupData.fulfilled]: (state, { payload }) => {
            console.log("updateGroupData.fulfilled")
            state.groups = state.groups.map(group => {
                if(group.id === payload.data.id){
                    return payload.data;
                }
                return group;
            });
        },
        [deleteGroupData.fulfilled]: (state, { payload }) => {
            console.log("deleteGroupData.fulfilled")
            if(payload.success){
                state.groups = state.groups.filter(group => group.id !== payload.groupId);
            }
        }

    }
});

const { actions, reducer } = groupsSlice;

export default reducer;
