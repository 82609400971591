import { createSlice } from '@reduxjs/toolkit';

export const aiUnitsLimitSlice = createSlice({
    name: 'aiUnitsLimit',
    initialState: {
        limitReached: false,
    },
    reducers: {
        setLimitReached: (state, action) => {
            state.limitReached = action.payload;
        }
    }
});

const { actions, reducer } = aiUnitsLimitSlice;

export const {
    setLimitReached
} = actions
export default reducer;
