import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import _, {get, findIndex, find} from "lodash"

import features from '../../features';
import GaugeChart from 'react-gauge-chart'

import DataBox from "../../components/Charts/DataBox"
import styled from "styled-components";
import colors from "../../styles/colors";
import {getAsset, abbreviateNumber} from "../../utils";
import {getString} from "../../utils/translations";
import {formatByHour, formatByDay} from "../../utils";

import {getNormalizedEmotionsMinutes, calcValence, calcEnergy, getTrend} from "../../utils/charts";
import MultiToggle from "../../components/MultiToggle";

import {OVER_TIME_FILTERS, SCREENS, OVER_TIME_MODULES_BUTTONS, TABLE_COLUMNS} from "../../constants";

import {setOverTimeData} from "../../redux/top";
import {setAnnotations} from "../../redux/table";

import ValenceEnergyScatter from "../../components/Charts/ValenceEnergyScatter"
import PieMeter from "../../components/Charts/PieMeter"
import OverTimeChart, {overTimeChartShape} from "../../components/Charts/OverTime";
import Popup from "../../components/Popup"

import AddAnnotationPopup from "./AddAnnotationPopup"

import {emptyTopData} from "../../redux/top"

import {saveAnnotation, deleteAnnotation, editAnnotation} from "../../api"

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import loader from '../../lotties/dataLoader.json'
import Lottie from 'react-lottie';

import {connect} from "react-redux";
import {value} from "lodash/seq";
import Donut from "../../components/Charts/Donut";
import {getColor} from "./TableData/utils";
import {Insights} from "../../components/Insights";
import {
    ENGAGEMENT_HIGH,
    ENGAGEMENT_MEDIUM,
    INTEREST_HIGH,
    INTEREST_MEDIUM,
    MOOD_HIGH,
    ENERGY_HIGH,
    ENERGY_MEDIUM,
    MOOD_MEDIUM, STRESS_HIGH, STRESS_LOW, STRESS_MEDIUM, WELLBEING_HIGH, WELLBEING_MEDIUM
} from "../../api/remoteConfig";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid'
    }
};

// const OVER_TIME_EMOTIONS = {
//     HAPPY: "happy",
//     CALM: "calm",
//     SAD: "sad",
//     ANGRY: "angry"
// }

export const TOP_DATA_MODULES_OPTIONS = {
    MOOD: OVER_TIME_MODULES_BUTTONS.MOOD,
    ENERGY: OVER_TIME_MODULES_BUTTONS.ENERGY,
    STRESS: OVER_TIME_MODULES_BUTTONS.STRESS,
    WELLBEING: OVER_TIME_MODULES_BUTTONS.WELLBEING,
}

const OVER_TIME_MOOD = {
    MOOD: "mood"
}

const OVER_TIME_ENGAGEMENT = {
    ENGAGEMENT: "engagement"
}

const OVER_TIME_INTEREST = {
    INTEREST: "interest"
}

const OVER_TIME_WELLBEING = {
    WELLBEING: "wellbeing"
}

const OVER_TIME_STRESS = {
    STRESS: "stress"
}

class TopData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedEmotions: this.initSelectedEmotions(),
            selectedEmotionsData: [],
            extraOvertimeData: [],
            lastWeekData: [],
            lastMonthsData: [],
            zoomedOverTime: [],
            selectedOverTimeFilter: OVER_TIME_FILTERS.OVER_TIME,
            hiddenAnnotations: false,
            addAnnotationPopup: false,
            deleteAnnotationPopup: false,
            annotationLoader: false,
            currentAnnotation: {},
            zoomStartIndex: null,
            zoomEndIndex: null,
            selectedModule: TOP_DATA_MODULES_OPTIONS.STRESS,
        }
        this.gaugeChartsCard = React.createRef()
        this.overTimeCard = React.createRef()
    }

    componentDidMount() {
        const {emotionsOverTime, lastWeekOT, lastMonthOT, selectedModule} = this.props;
        this.onEmotionClick(selectedModule, emotionsOverTime, lastWeekOT, lastMonthOT)
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {emotionsOverTime, startDate, endDate, lastWeekOT, lastMonthOT, selectedModule} = this.props;
        if (!_.isEqual(emotionsOverTime, prevProps.emotionsOverTime) || !_.isEqual(selectedModule, prevProps.selectedModule)) {
            this.onEmotionClick(selectedModule, emotionsOverTime, lastWeekOT, lastMonthOT)
        }

        if (!_.isEqual(startDate, prevProps.startDate) || !_.isEqual(endDate, prevProps.endDate)) {
            this.setState({selectedOverTimeFilter: OVER_TIME_FILTERS.OVER_TIME})
        }
    }

    onEmotionClick = (emotion, emotionsOverTime, lastWeekOT, lastMonthOT) => {
        let selectedEmotionsData = this.normalizeOvertimeData([emotion], emotionsOverTime);
        let extraEmotionsData = this.normalizeOvertimeData(["onlyDates"], emotionsOverTime);
        let lastWeekData = this.normalizeOvertimeData([emotion], lastWeekOT, true);
        let lastMonthsData = this.normalizeOvertimeData([emotion], lastMonthOT, true);
        console.log("lastWeekData", {emotion, lastWeekOT, lastMonthOT, lastWeekData, lastMonthsData})
        this.setState({
            selectedEmotionsData: selectedEmotionsData,
            extraOvertimeData: extraEmotionsData,
            lastWeekData: lastWeekData,
            lastMonthsData: lastMonthsData,
        })
    }


    initSelectedEmotions = () => {
        let array = Object.keys(OVER_TIME_WELLBEING).map(function (key) {
            return OVER_TIME_WELLBEING[key];
        });
        return array
    }

    getOverTimeDataKeyForContext = (customFilter) => {
        const {context,} = this.props;
        const {selectedOverTimeFilter} = this.state

        switch (context) {
            case SCREENS.USERS:
            case SCREENS.SESSIONS:
            case SCREENS.VIDEO:
                return {
                    key: "date", formatter: (date) => {
                        switch (customFilter || selectedOverTimeFilter) {
                            case OVER_TIME_FILTERS.OVER_TIME:
                                return moment(date).format("D MMM");
                            case OVER_TIME_FILTERS.OVER_DAY:
                                return formatByHour(date);
                            case OVER_TIME_FILTERS.OVER_WEEK:
                                return formatByDay(date);
                            case OVER_TIME_FILTERS.OVER_MONTH:
                                return date;
                            case "month":
                                return date;
                        }
                    }
                }
            case SCREENS.CONTENT:
                return {
                    key: "index", formatter: (index) => {
                        return index === 0 && features.hasBaseline ? "Baseline" : index
                    }
                }

            default:
                return {key: "date"}
        }
    }

    tooltipLabelFormatter = (payload, data, x) => {
        if (x.formatter) {
            return x.formatter(payload)
        } else if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        return payload;
    }

    getOverTimePercentData = () => {
        return {key: "value"}
    }




    normalizeOvertimeData = (selectedEmotions, overTimeData, skipSort = false) => {

      //  console.log("normalizeOvertimeData", selectedEmotions, overTimeData)

        if(_.isEmpty(overTimeData) || _.isEmpty(overTimeData["happy"])) {
            return []
        }

        const emotionsOverTime = overTimeData

        let selectedEmotionsData = [];
        let x = this.getOverTimeDataKeyForContext()

        for (let i = 0; i < selectedEmotions.length; i++) {
            let emotion = selectedEmotions[i];
            if (i === 0) {
                selectedEmotionsData = emotionsOverTime && emotionsOverTime[emotion] && emotionsOverTime[emotion].map(data => {
                    return {
                        ...data,
                        [x.key]: data[x.key],
                        [emotion]: Math.round(_.get(data, "value", 0) * 100),
                    }
                })
            } else {
                selectedEmotionsData = selectedEmotionsData && selectedEmotionsData.map(data => {
                    return {
                        ...data,
                        [emotion]: Math.round(_.get(find(emotionsOverTime[emotion], {[x.key]: data[x.key]}), "value", 0) * 100)
                    }
                })
            }
        }
        if(skipSort){
            return selectedEmotionsData
        }

        selectedEmotionsData = _.sortBy(selectedEmotionsData, [x.key])

        return selectedEmotionsData
    }

    updateOverTimeData = (filter) => {
        const {topData, setOverTimeData} = this.props
        let overTimeData

        switch (filter) {
            case OVER_TIME_FILTERS.OVER_TIME:
                overTimeData = topData.overTimeEmotions
                break;
            case OVER_TIME_FILTERS.OVER_DAY:
                overTimeData = topData.overTimeEmotionsByHour
                break;
            case OVER_TIME_FILTERS.OVER_WEEK:
                overTimeData = topData.overTimeEmotionsByDay
                break;
            case OVER_TIME_FILTERS.OVER_MONTH:
                overTimeData = topData.overTimeEmotionsByDayOfMonth
                break;
            default:
                overTimeData = emptyTopData.overTimeEmotions
                break;
        }

        setOverTimeData(overTimeData)
        this.setState({selectedOverTimeFilter: filter})
    }

    onOverTimeFilterClick = (filter) => {
        this.updateOverTimeData(filter)
    }

    updateAnnotations = ({name, startDate, endDate, action, annotationType, relationId}) => {

        const {annotations, setAnnotations} = this.props
        const {currentAnnotation} = this.state
        const updatedAnnotations = _.cloneDeep(annotations)

        switch (action) {
            case "add":
                updatedAnnotations.push({name, startDate, endDate, annotationType, relationId})
                setAnnotations(updatedAnnotations)
                break;
            case "delete":
                const update = updatedAnnotations.filter(a => (a.id !== currentAnnotation.id));
                setAnnotations(update)
                break;
            case "edit":
                const i = updatedAnnotations.findIndex((a => (a.id == currentAnnotation.id)));
                name && (updatedAnnotations[i].name = name)
                startDate && (updatedAnnotations[i].startDate = startDate)
                endDate && (updatedAnnotations[i].endDate = endDate)
                setAnnotations(updatedAnnotations)
                break;
        }
    }

    closeAddAnnotationPopup = async (name, startDate, endDate, action) => {
        const {annotationType, relationId} = this.props


        try {
            if (name && startDate && endDate) {
                this.setState({annotationLoader: true})
                if (action === "add") {
                    this.updateAnnotations({name, startDate, endDate, action: "add", annotationType, relationId})
                    await saveAnnotation({name, startDate, endDate, annotationType, relationId})
                } else {
                    this.updateAnnotations({name, startDate, endDate, action: "edit", annotationType, relationId})
                    await editAnnotation({name, startDate, endDate, annotationType, relationId})
                }
                this.setState({annotationLoader: false})
            }
            this.setState({addAnnotationPopup: false})
        } catch (e) {
            console.log("error saving/editing annotation", e)
        }
    }

    closeDeleteAnnotationPopup = () => {
        this.setState({deleteAnnotationPopup: false})
    }

    deleteAnnotation = async () => {
        try {
            const {currentAnnotation} = this.state
            console.log("currentAnnotation", currentAnnotation)
            this.updateAnnotations({action: "delete"})
            await deleteAnnotation(currentAnnotation)
            this.setState({deleteAnnotationPopup: false})
        } catch (e) {
            console.log("error deleting annotation", e)
        }
    }

    openDeleteAnnotationPopup = (annotation) => {
        this.setState({currentAnnotation: annotation})
        this.setState({deleteAnnotationPopup: true})
    }

    openEditAnnotationPopup = (annotation) => {
        this.setState({currentAnnotation: annotation})
        this.setState({addAnnotationPopup: true})
    }

    getValuesByContext = () => {
   //     const {} = this.state
        const {
            wellbeingAvg,
            moodAvg,
            energyAvg,
            stressAvg,
            wellbeingMeterData,
            stressMeterData,
            moodMeterData,
            energyMeterData,
            insights,
            selectedModule
        } = this.props
        let highColor;
        let lowColor;
        let avgScoreColor;


        let avgScore;
        let pieData;
        let lowValue, mediumValue, highValue;


        switch (selectedModule) {
            case TOP_DATA_MODULES_OPTIONS.STRESS:
                highColor = colors.tomato
                lowColor = colors.interest
                avgScore = stressAvg
                pieData = stressMeterData
                lowValue = `${STRESS_LOW}-${STRESS_MEDIUM * 100}`
                mediumValue = `${STRESS_MEDIUM * 100}-${STRESS_HIGH * 100}`
                highValue = `${STRESS_HIGH * 100}-${100}`
                break;
            case TOP_DATA_MODULES_OPTIONS.ENERGY:
                avgScore = energyAvg
                pieData = energyMeterData
                lowValue = `${0}-${ENERGY_MEDIUM * 100}`
                mediumValue = `${ENERGY_MEDIUM * 100}-${ENERGY_HIGH * 100}`
                highValue = `${ENERGY_HIGH * 100}-${100}`
                break;
            case TOP_DATA_MODULES_OPTIONS.MOOD:
                avgScore = moodAvg
                pieData = moodMeterData
                lowValue = `${0}-${MOOD_MEDIUM * 100}`
                mediumValue = `${MOOD_MEDIUM * 100}-${MOOD_HIGH * 100}`
                highValue = `${MOOD_HIGH * 100}-${100}`
                break;
            case TOP_DATA_MODULES_OPTIONS.WELLBEING:
                avgScore = wellbeingAvg
                pieData = wellbeingMeterData
                lowValue = `${0} - ${WELLBEING_MEDIUM * 100}`
                mediumValue = `${WELLBEING_MEDIUM * 100} - ${WELLBEING_HIGH * 100}`
                highValue = `${WELLBEING_HIGH * 100} - ${100}`
                break;
        }

        let monthNeg= _.get(insights, `month[${selectedModule}].min.label`, getString("N/A"))
        let dayNeg = _.get(insights, `day[${selectedModule}].min.label`, getString("N/A"))
        let hourNeg = _.get(insights, `hour[${selectedModule}].min.label`, getString("N/A"))
        let userNeg = _.get(insights, `user[${selectedModule}].min.label`, getString("N/A"))
        let monthPos = _.get(insights, `month[${selectedModule}].max.label`, getString("N/A"))
        let dayPos = _.get(insights, `day[${selectedModule}].max.label`, getString("N/A"))
        let hourPos = _.get(insights, `hour[${selectedModule}].max.label`, getString("N/A"))
        let userPos = _.get(insights, `user[${selectedModule}].max.label`, getString("N/A"))

        avgScoreColor = getColor(selectedModule, avgScore, false)

        return {avgScore, avgScoreColor, pieData, monthNeg,
            dayNeg,
            hourNeg,
            userNeg,
            monthPos,
            dayPos,
            hourPos,
            userPos,
            lowValue,
            mediumValue,
            highValue
        }

    }


    render() {
        const {
            loadingTop,
            loadingCharts,
            activeUsers,
            currentLanguage,
            energyMoodChart,
            hasOvertimeFilters,
            topData,
            cardType,
            showSamples,
            perMinute,
            annotations,
            annotationLoader,
            scores,
            selectedModule,
            wellbeingAvgTrend,
            moodAvgTrend,
            energyAvgTrend,
            stressAvgTrend,
            context
        } = this.props;

        const {seconds, sessions, trend, costSaving, samples} = topData

        const {
            selectedEmotionsData, currentAnnotation,
            selectedOverTimeFilter,
            addAnnotationPopup, deleteAnnotationPopup, extraOvertimeData, lastWeekData, lastMonthsData
        } = this.state

        const avgTime = get(topData, cardType, 0) ? (seconds / 60) / get(topData, cardType, 0) : 0;


        const {avgScore, avgScoreColor, pieData,  monthNeg,
            dayNeg,
            hourNeg,
            userNeg,
            monthPos,
            dayPos,
            hourPos,
            userPos,
            lowValue,
            mediumValue,
            highValue
        } = this.getValuesByContext()

        let modified, shouldModify = false, domain = [0, 100], brokenOvertimeData = []
        let currentResults = {
            seconds,
            sessions,
            activeUsers,
            avgTime,
            costSaving,
            stress: avgScore,
            energy: avgScore,
            mood: avgScore,
            wellbeing: avgScore,
        };


        let trendForCompare = null
        if (trend) {
            let trendAvgTime = (get(trend, "seconds", 0) / 60) / get(trend, cardType, 0)
            trendForCompare = {
                ...trend,
                avgTime: trendAvgTime,
                stress: stressAvgTrend,
                energy: energyAvgTrend,
                mood: moodAvgTrend,
                wellbeing: wellbeingAvgTrend,
            }
        }
        const trendRes = getTrend(currentResults, trendForCompare)


        let secondRowHeight = 234
        let cardStyle = {height: 340, padding: 29, marginBottom: 12, display: "flex", flexDirection: "column", alignItems: "center"}
        let overtimeCardStyle = {height: secondRowHeight, padding: "29px 0 10px", marginBottom: 12, display: "flex", flexDirection: "column", alignItems: "center"}



        let data = energyMoodChart//this.normalizePoints(energyMoodChart, 125)

        let avgScoreKey = context === SCREENS.SESSIONS ? "avg_score_sessions" : "avg_score";
        let userLevelsKey = context === SCREENS.SESSIONS ? "user_levels_sessions" :"user_levels";

        return (
            <Fragment>

                <Row style={{justifyContent: 'space-between'}}>
                    {activeUsers || activeUsers === 0 ? <Col md={2}>
                        {loadingTop ? <Lottie
                                options={defaultOptions}/> :
                            <DataBox
                                title={getString("active_users")}
                                subTitle={getString("active")}
                                value={activeUsers}
                                trend={trendRes.activeUsers}
                            />
                        }
                    </Col> : null}
                    <Col md={2}>
                        {loadingTop ? <Lottie
                                options={defaultOptions}/> :
                            <DataBox
                                title={getString(cardType)}
                                subTitle={getString("total")}
                                value={get(topData, cardType, 0)}
                                trend={get(trendRes, cardType, null)}
                            />
                        }
                    </Col>
                    <Col md={2}>
                        {loadingTop ? <Lottie
                                options={defaultOptions}/> :
                            <DataBox
                                title={getString("capital_minutes")}
                                subTitle={getString("total")}
                                value={abbreviateNumber(seconds / 60)}
                                trend={trendRes.seconds}
                            />
                        }
                    </Col>
                    <Col md={2}>
                        {loadingTop ? <Lottie
                                options={defaultOptions}/> :
                            <DataBox
                                title={getString(perMinute)}
                                subTitle={getString("average")}
                                value={avgTime}
                                trend={trendRes.avgTime}
                            />
                        }
                    </Col>
                    {showSamples ? <Col md={2}>
                        {loadingTop ? <Lottie
                                options={defaultOptions}/> :
                            <DataBox
                                title={getString("samples")}
                                subTitle={getString("total")}
                                value={samples}
                            />
                        }
                    </Col> : null}
                </Row>

                <Row style={{marginTop: 20}}>

                    <Col md={4}>
                        <Card style={cardStyle}>
                            <ChartTitle>{getString(avgScoreKey)}</ChartTitle>
                            {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                                <Donut
                                    size={"XL"}
                                    value={avgScore}
                                    change={trendRes[selectedModule]}
                                    color={avgScoreColor}
                                    trailColor={"#c4c4c4"}
                                    hideShadow={true}
                                    rotation={1}
                                    strokeWidth={10}
                                    valueFormatter={(value)=>{
                                    return (
                                        <>
                                            <div style={{
                                                fontSize: 45,
                                                fontWeight: 800,
                                                letterSpacing: -0.3,
                                                color: "#000",
                                                marginTop: 37
                                            }}>{value}</div>
                                            <div style={{
                                                fontSize: 25,
                                                fontWeight: 400,
                                                letterSpacing: -0.3,
                                                color: "#000"
                                            }}>{getString("out_of_100")}</div>
                                        </>
                                    )
                                }}/>
                            )}
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={cardStyle}>
                            <ChartTitle>{getString(userLevelsKey)}</ChartTitle>
                            {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                                <>
                                    <PieMeter useFullPie={true} data={pieData} currentLanguage={currentLanguage}/>
                                    <div style={{position: "absolute", left: 17, bottom: 20}}>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "right", lineHeight: "15px"}}>
                                            <div>{getString("low")}:</div>
                                            <div style={{marginLeft: 5}}>{lowValue}</div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "right", lineHeight: "15px"}}>
                                            <div>{getString("medium")}:</div>
                                            <div style={{marginLeft: 5}}>{mediumValue}</div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", alignItems: "right", lineHeight: "15px"}}>
                                            <div>{getString("high")}:</div>
                                            <div style={{marginLeft: 5}}>{highValue}</div>
                                        </div>
                                    </div>
                                </>

                            )}
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card style={cardStyle}>
                            <Tippy content={getString("energy_mood_chart_tooltip")}>
                                <Tooltip src={getAsset("icons/i.svg")} style={{position: "absolute", left: 25, top: 15}}/>
                            </Tippy>
                            <ChartTitle>{getString("circumplex_model")}</ChartTitle>
                            {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                                <ValenceEnergyScatter
                                    data={data}
                                    chartStyle={{marginLeft: currentLanguage === "ja" ? -10 : 0}}
                                    specialFormat={true}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>

                {/* todo: gray out all chart for "today"*/}
                <Row style={{marginTop: 15}}>


                    <Col md={5}>
                        <Card style={{...cardStyle, height: secondRowHeight}}>
                            <ChartTitle>{getString("insights")}</ChartTitle>
                            {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                                <Insights
                                    context={selectedModule}
                                    monthNeg={monthNeg}
                                    dayNeg={dayNeg}
                                    hourNeg={hourNeg}
                                    userNeg={userNeg}
                                    monthPos={monthPos}
                                    dayPos={dayPos}
                                    hourPos={hourPos}
                                    userPos={userPos}
                                />
                            )}
                        </Card>
                    </Col>

                    <Col md={5}>
                        <Card style={overtimeCardStyle}>
                            <ChartTitle>{getString("last_7_days")}</ChartTitle>
                            {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                                <div style={{width: "calc(100% + 25px)", height: "100%", marginLeft: -25}}>
                                    <OverTimeChart
                                        x={this.getOverTimeDataKeyForContext(OVER_TIME_FILTERS.OVER_WEEK)}
                                        y={this.getOverTimePercentData()}
                                        data={lastWeekData}
                                        selectedEmotions={[selectedModule]}
                                        tooltipLabelFormatter={this.tooltipLabelFormatter}
                                        domain={domain}
                                        showBar={true}
                                        showRangePicker={false}
                                    />
                                </div>

                            )}
                        </Card>
                    </Col>

                    <Col md={2}>
                        <Card style={overtimeCardStyle}>
                            <ChartTitle>{getString("last_3_months")}</ChartTitle>
                       {loadingCharts ? (
                                <Lottie
                                    options={defaultOptions}/>
                            ) : (
                           <div style={{width: "calc(100% + 25px)", height: "100%", marginLeft: -25}}>
                                <OverTimeChart
                                    x={this.getOverTimeDataKeyForContext("month")}
                                    y={this.getOverTimePercentData()}
                                    data={lastMonthsData}
                                    selectedEmotions={[selectedModule]}
                                    tooltipLabelFormatter={this.tooltipLabelFormatter}
                                    domain={domain}
                                    showBar={true}
                                    showRangePicker={false}
                                />
                           </div>
                            )}
                        </Card>
                    </Col>


                </Row>

                <Row style={{marginTop: 45, justifyContent: 'center'}}>
                    {
                        hasOvertimeFilters ? <OverTimeFilterContainer>
                                {Object.keys(OVER_TIME_FILTERS).map((filter, i) => {
                                    return <Fragment key={i}>
                                        <ChartTitle onClick={() => this.onOverTimeFilterClick(OVER_TIME_FILTERS[filter])}
                                                    style={OVER_TIME_FILTERS[filter] === selectedOverTimeFilter ? {fontWeight: '900'} : {}}>
                                            {getString(OVER_TIME_FILTERS[filter])}</ChartTitle>
                                        {i !== Object.keys(OVER_TIME_FILTERS).length - 1 && <Slash>/</Slash>}
                                    </Fragment>
                                })}
                            </OverTimeFilterContainer> :
                            <OverTimeFilterContainer>
                                <ChartTitle style={{fontWeight: '900'}}>
                                    {getString(OVER_TIME_FILTERS.OVER_TIME)}
                                </ChartTitle>
                            </OverTimeFilterContainer>
                    }


                    <Card ref={this.overTimeCard} style={{
                            height: 395, paddingLeft: 39, marginBottom: 12, width: '100%',
                            paddingBottom: 20, paddingRight: 29, paddingTop: 29
                        }}>
                            <div style={{
                                height: '100%',
                                width: this.overTimeCard.current && this.overTimeCard.current.offsetWidth - 17,
                                marginLeft: -49,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                {loadingTop ?
                                    <Lottie
                                        options={defaultOptions}/>
                                    :
                                    <Fragment>
                                        <OverTimeChart
                                            x={this.getOverTimeDataKeyForContext()}
                                            y={this.getOverTimePercentData()}
                                            data={shouldModify ? modified : selectedEmotionsData}
                                            selectedEmotions={[selectedModule]}
                                            tooltipLabelFormatter={this.tooltipLabelFormatter}
                                            domain={domain}
                                            showAnnotations={false}
                                            annotations={annotations}
                                            scores={scores}
                                            extraData={extraOvertimeData}
                                            openDeleteAnnotationPopup={this.openDeleteAnnotationPopup}
                                            openEditAnnotationPopup={this.openEditAnnotationPopup}
                                            showBar={true}
                                        />

                                    </Fragment>
                                }
                            </div>
                        </Card>
                </Row>

                {addAnnotationPopup && <AddAnnotationPopup
                    isOpen={addAnnotationPopup}
                    closeHandler={this.closeAddAnnotationPopup}
                    title={`${getString("annotation")}:`}
                    loading={annotationLoader}
                    name={currentAnnotation.name}
                    startDate={currentAnnotation.startDate}
                    endDate={currentAnnotation.endDate}
                    action={_.isEmpty(currentAnnotation) ? "add" : "edit"}
                />}

                {deleteAnnotationPopup && <Popup
                    isOpen={deleteAnnotationPopup}
                    closeHandler={this.closeDeleteAnnotationPopup}
                    closeOnDocumentClick={false}
                    title={getString("delete_annotation")}
                    description={getString("delete_annotation_msg")}
                    footerButtons={[{
                        label: getString("delete"),
                        onPress: this.deleteAnnotation,
                        background: colors.lipstick
                    },
                        {label: getString("keep"), onPress: this.closeDeleteAnnotationPopup, background: colors.green}]}
                />}

            </Fragment>
        );
    }
}

const mapStateToProps = ({translations, top, table, date}) => ({
    currentLanguage: translations.currentLanguage,
    activeUsers: top.activeUsers,
    cardType: top.cardType,
    perMinute: top.perMinute,
    showCostSaving: top.showCostSaving,
    showSamples: top.showSamples,
    loadingTop: top.loadingTopData,
    energyMoodChart: top.moodEnergyChartData,
    topData: top.topData,
    emotionsOverTime: top.overTimeData,
    wellbeingMeterData: top.wellbeingMeterData,
    stressMeterData: top.stressMeterData,
    moodMeterData: top.moodMeterData,
    energyMeterData: top.energyMeterData,
    wellbeingAvg: top.wellbeingAvg,
    moodAvg: top.moodAvg,
    energyAvg: top.energyAvg,
    stressAvg: top.stressAvg,
    wellbeingAvgTrend: top.wellbeingAvgTrend,
    moodAvgTrend: top.moodAvgTrend,
    energyAvgTrend: top.energyAvgTrend,
    stressAvgTrend: top.stressAvgTrend,
    lastWeekOT: top.lastWeekOT,
    lastMonthOT: top.lastMonthOT,
    insights: top.insights,
    breakOvertime: top.breakOvertime,
    annotations: table.annotations,
    scores: top.scores,
    annotationType: table.annotationType,
    relationId: table.relationId,
    startDate: date.startDate,
    endDate: date.endDate
});

const mapDispatchToProps = {
    setOverTimeData,
    setAnnotations
};

export default connect(mapStateToProps, mapDispatchToProps)(TopData);

const MiniOvertimeContainer = styled.div`
    width: 100%;
    height: 215px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`

const MiniOvertime = styled.div`
    height: 100%;
    width: 95%;
    background: white;
    margin-left: 45px;
`

const MiniOverTimeButton = styled.div`
    position: absolute;
    top: 65px;
    left: -20px;
    height: 27px;
    width: 115px;
    background: #536EEC;
    transform: rotate(270deg);
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    color: white;
    border-radius: 9px;
`

const EmotionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    `

const VerticalSeparator = styled.div`
    background-color: #909090;
    width: 0.03125em;
    height: 10em;

    @media print {
    margin: 10px
    }
    `

const Card = styled.div`
    background: #FFFFFF;
    border: 1px solid rgba(46, 91, 255, 0.08);
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    `

const OverTimeButton = styled.button`
    background: #909090;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-left: 13px;
    padding-right: 13px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    width: 98px;
    height: 35px;

    color: #FFFFFF;
    cursor: pointer;
    border: 0;
    outline: 0;
    `

const GaugeTitle = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;

    /* Dim */

    color: #909090;
    `

const OverTimeFilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 20px;
    `

const OvertimeVerticalLine = styled.div`
    width: 17px;
    margin-top: 7px;
    margin-bottom: 7px;
    border: 1px solid #909090;
    transform: rotate(90deg);
`

const Slash = styled.div`
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 15px;
    margin-Top: -15px;
    font-family: Rubik;
    font-size: 28px;
    line-height: 32px;
    color: #2E384D;
    `

const ChartTitle = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-Top: -15px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 32px;
    text-align: center; 
    color: #2E384D;
    text-transform: capitalize;
    `

const Tooltip = styled.img`
    position: absolute;
    width: 17px;
    height: 17px;
    `
