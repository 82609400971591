import styled from "styled-components";
import { connect } from "react-redux";
import { Fragment } from "react";
import { getAsset } from "../../../utils";
import { get } from "lodash";
import { getString } from "../../../utils/translations";
import Tippy from '@tippyjs/react';

const Icon = ({ pinned, row, showAttentionAlert, rowsIcon }) => {
    let imgSource = ""

    let attentionAlert = false
    let attentionAlertTooltip = ""
    let attentionAlertSrc = ""

    if (get(row, "alerts.sadAlert", null)) {
        attentionAlert = true
        attentionAlertTooltip = getString("sad_attention_alert_tooltip")
        attentionAlertSrc = getAsset(`icons/sad_alert.svg`)
    }

    if (get(row, "alerts.stressAlert", null)) {
        attentionAlert = true
        attentionAlertTooltip = getString("stress_attention_alert_tooltip")
        attentionAlertSrc = getAsset(`icons/angry_alert.svg`)
    }

    return (
        <Fragment>
            {
                pinned ? <img src={getAsset(get(row, "original.icon", ""))} /> :
                    attentionAlert && showAttentionAlert ? <AttentionAlertContainer>
                        <Tippy content={attentionAlertTooltip}>
                            <Tooltip src={getAsset("icons/i.svg")} style={{ width: 13, height: 13 }} />
                        </Tippy>
                        <img width={35} height={35} src={attentionAlertSrc} />
                    </AttentionAlertContainer> :
                        (imgSource ? (<UserImg src={imgSource} />) : (
                            rowsIcon && <IconContainer>
                                {rowsIcon !== 'index' ?
                                    <img src={getAsset(`icons/${rowsIcon}_white.svg`)} />
                                    : <ContentIndex>
                                        {row.index + 1}
                                    </ContentIndex>
                                }
                            </IconContainer>
                        ))
            }
        </Fragment>
    );
}

const AttentionAlertContainer = styled.div`
  position: relative;
`

const IconContainer = styled.div`
  width: 2.125em;
  height: 2.125em;
  background: #BDBDBD;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;

  @media print{
    width: 29px;
    height: 29px;
    margin-top: 32px;
  }
`

const UserImg = styled.img`
  width: 2.125em;
  height: 2.125em;
  border-radius: 50%;
`

const ContentIndex = styled.div`
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 25px;
/* identical to box height */

display: flex;
align-items: center;

color: #FFFFFF;
`

const Tooltip = styled.img`
  position: absolute;
  top: -8px;
  left: -8px;
`

const mapStateToProps = ({ table }) => ({
    showAttentionAlert: table.showAttentionAlert,
    rowsIcon: table.rowsIcon
});

export default connect(mapStateToProps)(Icon);