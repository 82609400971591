import styled from "styled-components";
import { connect } from "react-redux";
import { getTrend } from "../../../utils/charts";
import { RESIDENT_TYPE } from "../../../constants";
import { get } from "lodash";
import { getAsset } from "../../../utils";

const Session = ({ row, pinnedResidents, value, data }) => {
    let pinned = null

    const { happiness, calmness, emotional, seconds, sessions, trend, sad, angry } = row;
    const trendRes = getTrend({ calmness, emotional, happiness, seconds, sessions, sad, angry }, trend)

    if (pinnedResidents && get(row, "type", "") === RESIDENT_TYPE.GROUP
        || pinnedResidents && get(row, "type", "") === RESIDENT_TYPE.CARE) {
        pinned = true
    }

    return (
        <SimpleValue style={{ textAlign: data.textAlign, margin: '0 30px' }}>
            <div style={pinned ? { color: 'white' } : {}}>
                <div>{value}</div>
                {trendRes.sessions === 0 ? (null) : trendRes.sessions < value ? <Trend src={getAsset("icons/trend_up.svg")} />
                    : <Trend src={getAsset("icons/trend_down.svg")} />}
            </div>
        </SimpleValue>
    );
}

const SimpleValue = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 1.375em;
  line-height: 1.375em;
  /* identical to box height, or 100% */

  text-align: center;
  color: #2E384D;
  position: relative;
`

const Trend = styled.img`
  width: 0.625em;
  height: 0.625em;
`

const mapStateToProps = ({ table }) => ({
    pinnedResidents: table.pinnedResidents,
});

export default connect(mapStateToProps)(Session);
