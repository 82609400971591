import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./App.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import store from './redux/store';
import {Provider} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import {appVersion} from "./appVersion";

Bugsnag.start({
    apiKey: '1388351448584e212a1a1b1a431e54b9',
    plugins: [new BugsnagPluginReact()],
    appVersion: appVersion,
    releaseStage: 'PRODUCTION',
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <Provider store={store}>
                <App/>
                <ToastContainer/>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
