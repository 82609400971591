import { Fragment } from "react";
import styled from "styled-components";
import CreatableSelect from "../../../components/Tags/CreatableMultiSelect";
import { get } from "lodash";
import { calcArrayOfTags } from "./utils"
import { connect } from "react-redux";

const DateCell = ({ value, showAddTag, contextId, row, hasTagsInput, updateTags, tagContext }) => {

  const id = get(row, contextId, null)
  let tags = []

  if (hasTagsInput) {
    const tagsObj = get(row, "tags", null)
    if (tagsObj) {
      tags = calcArrayOfTags(tagsObj)
    }
  }

  return (
    <Fragment>
      <CellContent>
        {value}
      </CellContent>
      {hasTagsInput && showAddTag
        && <div style={{ width: 170, marginLeft: 12 }} onClickCapture={e => e.nativeEvent.stopPropagation()}>
          <CreatableSelect tags={tags} id={id} updateTags={updateTags} tagContext={tagContext} />
        </div>}
    </Fragment>
  );
}

const CellContent = styled.span`
    color: #2E384D;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 800;
    font-size: 0.9375em;
    line-height: 1.25em;
    display: flex;
    align-items: center;
    margin-left: 0.9375em;

  @media print{
    width: 55px;
    height: 55px;
    display: block;
    margin-left: 40px;
    margin-top: -30px;
    margin-right: -38px;
  }
`

const mapStateToProps = ({ table }) => ({
  contextId: table.contextId,
  hasTagsInput: table.hasTagsInput,
  tagContext: table.tagContext,
});

export default connect(mapStateToProps)(DateCell);