let parsers = {};
let app = process.env.REACT_APP_ENV;
if (app && app.includes("sdk")) {
    parsers = require("./sdk")
} else {
    parsers = require("./solo")
}

export const {
    parseUsersTableData,
    parseSessionsTableData,
    parseContentTableData,
    parseUsersTopData,
    parseSessionsTopData,
    parseContentTopData,
    parseVideosTopData,
    parseVideosTableData
} = parsers
