import { createAsyncThunk } from '@reduxjs/toolkit';
import { getResidentSessionsReport, getResidentReport } from "../../api"
import _ from "lodash";
import { SCREENS } from "../../constants";
import { setMoodEnergyChartData, setPieChartData, setModulesAvgs, setTableInsights } from "../top"
import { parseSessionsTableData, parseSessionsTopData } from "../../parsers"

export const getSessionsTopData = createAsyncThunk('sessions/getTopData', async (data) => {
    const { startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode } = data
    try {
        const res = await getResidentReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode)
        const parsedData = parseSessionsTopData(res.data)
        return {...parsedData,startDate, endDate}
    } catch (e) {
        console.log("getSessionsTopData ERROR", e)
    }
})

export const getSessionsTableData = createAsyncThunk('sessions/getTableData', async (data, { dispatch }) => {
    const { startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode } = data
    const res = await getResidentSessionsReport(startDate, endDate, compareTrendKey, residentId, tags, tagFilteringMode)

    const sessions = parseSessionsTableData(res.data)
    if (_.get(res, "data", null)) {
        dispatch(setMoodEnergyChartData({ data: sessions, context: SCREENS.SESSIONS }))

        dispatch(setPieChartData({ data: sessions, context: SCREENS.SESSIONS }))
        dispatch(setModulesAvgs({ data: sessions, context: SCREENS.SESSIONS }))
        dispatch(setTableInsights({ data: sessions, context: SCREENS.SESSIONS }))
    }
    return sessions
})
