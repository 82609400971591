import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAsset } from "../utils";
import { InputGroup } from 'react-bootstrap';

class EditableField extends Component {

    constructor(props) {
        super();
        this.state = {
            focused: false,
            editValue: props.value
        }
    }

    setEditMode = (e) => {
        if (!this.state.focused) {
            this.setState({ focused: true })
        }
        e.nativeEvent.stopPropagation()
    }

    cancel = () => {
        this.setState({
            focused: false,
            editValue: this.props.value,
            hover: false,
        })
    }

    save = () => {
        const { editValue } = this.state
        let res = this.props.onSave(editValue)
        if (res === false) {
            this.setState({
                editValue: this.props.value,
            })
        }
        this.setState({
            focused: false,
            hover: false,
        })
    }

    handleKeyDown = (event) => {
        const { focused } = this.state
        if (focused) {
            if (event.key === 'Escape') {
                this.cancel()
            }
            if (event.key === 'Enter') {
                this.save()
            }
        }
    }

    render() {
        const { editValue, focused } = this.state
        const { renderInput, showButtons } = this.props;

        const input = focused && renderInput({ onSave: this.save, value: editValue, setValue: (value) => { this.setState({ editValue: value }) } })

        return (
            <div
                style={{ display: "flex", cursor: "text" }}
                onKeyDown={this.handleKeyDown}>
                {!focused && (<span onClickCapture={e => this.setEditMode(e)}>{editValue}</span>)}
                {input}
                {focused && showButtons && <img src={getAsset("icons/black_v_icon.svg")} onClick={this.save}
                    style={{ margin: 5, cursor: 'pointer' }} />}
                {focused && showButtons && <img src={getAsset("icons/black_x.svg")} onClick={this.cancel}
                    style={{ width: 13, margin: 2, cursor: 'pointer' }} />}
            </div >
        );
    }
}

EditableField.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    onSave: PropTypes.func,
    renderInput: PropTypes.func,
};

export default EditableField;
