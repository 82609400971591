import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createGroup} from "../../api/sdk";
import {getGroupsData, updateGroupData} from "../../redux/groups/api";

import Table from "./table"

export default function GroupsMgmt({}) {

    const dispatch = useDispatch()
    const groups = useSelector(state => state.groups.groups)
    const loading = useSelector(state => state.groups.loading)

    useEffect(()=>{
        dispatch(getGroupsData())
    }, [])



    return (
        <div>
            <Table data={groups} loading={loading}/>
        </div>
    )
}
