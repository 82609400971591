import moment from "moment";
import { TIME_FILTERS } from "../../constants";

export const getStartDate = (payload) => {
    if (payload.timeFilter) {
        const { requestStartDate } = getDateRange(payload.timeFilter)
        return requestStartDate
    } else {
        return payload
    }
}

export const getDateRange = (timeFilter) => {
    let requestStartDate;
    let requestEndDate;

    switch (timeFilter) {
        case TIME_FILTERS.TODAY:
            requestStartDate = moment().hour(0).minute(0).second(0).millisecond(0);
            requestEndDate = moment().hour(23).minute(59).second(59).millisecond(0);
            break;
        case TIME_FILTERS.WEEK:
            requestStartDate = moment().weekday(0).hour(0).minute(0).second(0).millisecond(0);
            requestEndDate = moment().hour(23).minute(59).second(59).millisecond(0);
            break;
        case TIME_FILTERS.MONTH:
            requestStartDate = moment().startOf('month').hour(0).minute(0).second(0).millisecond(0);
            requestEndDate = moment().hour(23).minute(59).second(59).millisecond(0);
            break;
        case TIME_FILTERS.ALL_TIME:
            requestStartDate = moment("2021-01-01").hour(0).minute(0).second(0).millisecond(0);
            requestEndDate = moment().hour(23).minute(59).second(59).millisecond(0);
            break;
        default:
            console.error("no time frame selected")
            return null;
    }

    return {
        requestStartDate,
        requestEndDate
    }
}