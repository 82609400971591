import React  from "react";
import {getAsset} from "../../utils";
import styled from "styled-components";
import {getString} from "../../utils/translations";

export const Insights = ({dayNeg, hourNeg, monthNeg, userNeg, dayPos, hourPos, monthPos, userPos, context}) => {

    let positives = [
        {
            title: dayPos,
            icon: getAsset("icons/insights_day_positive.svg")
        },{
            title: hourPos,
            icon: getAsset("icons/insights_hour_positive.svg")
        },{
            title: monthPos,
            icon: getAsset("icons/insights_calendar_positive.svg")
        },{
            title: userPos,
            icon: getAsset("icons/insights_user_positive2.svg")
        },
    ]
    let negatives = [{
        title: dayNeg,
        icon: getAsset("icons/insights_day_neg.svg")
    },{
        title: hourNeg,
        icon: getAsset("icons/insights_hour_neg.svg")
    },{
        title: monthNeg,
        icon: getAsset("icons/insights_calendar_neg.svg")
    },{
        title: userNeg,
        icon: getAsset("icons/insights_user_neg.svg")
    },
    ]

    if(context === "stress") {
        // swap negatives and positives
        let temp = negatives
        negatives = positives
        positives = temp
    }

    return (
        <Container>
            <div style={{flex: 1}}>
                <Title>{context === "stress" ? getString("positive_insights") : getString("negative_insights")}</Title>
                {negatives.map((item, index) => {
                    return (
                        <Insight title={item.title} icon={item.icon} />
                    )
                })}
            </div>
            <div style={{flex: 1}}>
                <Title>{context === "stress" ? getString("negative_insights") : getString("positive_insights")}</Title>
                {positives.map((item, index) => {
                    return (
                        <Insight title={item.title} icon={item.icon} />
                    )
                })}
            </div>
        </Container>
    )
}

const Insight = ({title, icon}) => {
    return (
        <RowContainer>
            <img src={icon}/>
            <Label>{title}</Label>
        </RowContainer>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    `

const RowContainer = styled.div`
     display: flex;
     flex-direction: row;
`

const Title = styled.div`
text-align: center;
font-family: 'Rubik';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 31px;
color: #2E384D;
`

const Icon = styled.img``
const Label = styled.div`
margin-left: 10px;
font-family: 'Rubik';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 31px;
/* or 227% */


color: #2E384D;`
