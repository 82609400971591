import { createSlice } from '@reduxjs/toolkit';
import { translations } from "../../utils/translations";

export const translationsSlice = createSlice({
    name: 'translations',
    initialState: {
        currentLanguage: translations.selectedLocale,
        translationsLoaded: false
    },
    reducers: {
        setCurrentLanguage: (state, action) => {
            state.currentLanguage = action.payload
        },
        setTranslationsLoaded: (state, action) => {
            state.translationsLoaded = action.payload
        }
    },
});

const { actions, reducer } = translationsSlice;

export const { setCurrentLanguage, setTranslationsLoaded } = actions;
export default reducer;