import firebase from "./firebase";

export const apiCache = {}

export const cacheRequest = async (route, init) => {
    if (apiCache[route]) {
        return apiCache[route]
    } else {
        let response = await fetch(route, init)
        let json = await response.json();
        apiCache[route] = json;
        return json;
    }
}

export const getAuthHeader = async () => {
    const currentUser = firebase.auth().currentUser
    if (currentUser) {
        const token = await currentUser.getIdToken()
        return new Headers({
            'Authorization': `Bearer ${token}`
        })
    }
    return null
}


