import styled from "styled-components";
import EditableField from "../../../components/EditableField";
import { connect } from "react-redux";
import { get } from "lodash";

const Comment = ({ id, comment, rows, trail, saveCommentFunction, contextId, updateTableData }) => {

    const renderCommentInput = ({ value, setValue }) => {
        return <div onClickCapture={e => e.nativeEvent.stopPropagation()}>
            <input
                value={typeof value === 'object' ? "" : value}
                onChange={event => {
                    setValue(event.target.value);
                }}
                type="text"
                autoFocus
            />
        </div>
    }

    const saveCommentInTableData = (rows, id, comment, trail) => {
        let originalRows = rows.map(r => r.original)
        let rowIndex = originalRows.findIndex(originalRow => get(originalRow, contextId, null) === id)
        originalRows[rowIndex][trail] = comment
        updateTableData(originalRows)
    }

    const updateComment = async (id, comment, rows, trail) => {
        saveCommentInTableData(rows, id, comment, trail)
        await saveCommentFunction(id, comment)
        console.log("comment saved")
    }

    return (
        <CommentContainer>
            <Hashtag>#</Hashtag>
            <CommentField>
                <EditableField
                    value={comment ? comment : <div style={{ width: 100, height: 20, marginLeft: -10 }}></div>}
                    renderInput={renderCommentInput}
                    onSave={(value) => {
                        updateComment(id, value, rows, trail)
                    }} />
            </CommentField>
        </CommentContainer>
    );
}

const CommentContainer = styled.span`
    display: flex;
    flex-direction: row;
`

const CommentField = styled.span`
    font-size: 11px;
    color: #CC1D6C;
`

const Hashtag = styled.span`
    font-family: Open Sans;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    display: flex;
    margin-right: 3px;

    color: #CC1D6C;
`

const mapStateToProps = ({ table }) => ({
    saveCommentFunction: table.saveCommentFunction,
    contextId: table.contextId,
});

export default connect(mapStateToProps)(Comment);