import { createSlice } from '@reduxjs/toolkit';
import { processVideo, setVideoReady, saveVideoData } from "./api";

export const videoSlice = createSlice({
    name: 'video',
    initialState: {
        tracker: [],
        videoReady: null,
        savingVideo: false,
        savingVideoError: null,
    },
    reducers: {
        setVideoTracker: (state, action) => {
            state.tracker = action.payload
        },
        initVideoReady: (state, action) => {
            state.videoReady = null
        }
    },
    extraReducers: {
        [processVideo.fulfilled]: (state, { payload }) => {
            console.log("processVideo.fulfilled")
        },
        [setVideoReady.fulfilled]: (state, { payload }) => {
            console.log("setVideoReady.fulfilled", payload)
            state.videoReady = payload
        },
        [saveVideoData.pending]: (state, { payload }) => {
            console.log("saveVideoData.pending")
            state.savingVideo = true;
        },
        [saveVideoData.rejected]: (state, { payload }) => {
            console.log("saveVideoData.rejected")
            state.savingVideo = false;
            state.savingVideoError = payload;
        },
        [saveVideoData.fulfilled]: (state, { payload }) => {
            console.log("saveVideoData.fulfilled")
            state.savingVideo = false;
        },
    }
});

const { actions, reducer } = videoSlice;

export const { setVideoTracker, initVideoReady } = actions;
export default reducer;
