import { AsyncStorage } from 'AsyncStorage';
import { ASYNC_STORAGE_KEYS } from "../constants";

export const storeTranslations = async ({ locales, version }) => {
    try {
        const jsonValue = JSON.stringify(locales)
        await AsyncStorage.setItem(ASYNC_STORAGE_KEYS.TRANSLATIONS, jsonValue)
        await AsyncStorage.setItem(ASYNC_STORAGE_KEYS.TRANSLATIONS_VERSION, version)
    } catch (e) {
        console.error("error saving Translations", e)
    }
}

export const storeTranslationsSelectedLocale = async (locale) => {
    try {
        await AsyncStorage.setItem(ASYNC_STORAGE_KEYS.TRANSLATIONS_SELECTED_LOCALE, locale)
    } catch (e) {
        console.error("error saving storeTranslationsSelectedLocale", e)
    }
}

export const loadTranslations = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem(ASYNC_STORAGE_KEYS.TRANSLATIONS)
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        console.error("error reading Translations", e)
    }
}

export const getCurrentStoredTranslationsVersion = async () => {
    try {
        const value = await AsyncStorage.getItem(ASYNC_STORAGE_KEYS.TRANSLATIONS_VERSION)
        return value
    } catch (e) {
        console.error("error reading getCurrentStoredTranslationsVersion", e)
        return null;
    }
}

export const getSelectedLocale = async () => {
    try {
        const value = await AsyncStorage.getItem(ASYNC_STORAGE_KEYS.TRANSLATIONS_SELECTED_LOCALE)
        return value
    } catch (e) {
        console.error("error reading getSelectedLocale", e)
        return null;
    }
}

export const setInsufficientDataMode = async (value) => {
    try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem(ASYNC_STORAGE_KEYS.SELECTED_INSUFFICIENT_MODE, jsonValue)
    } catch (e) {
        console.error("error saving insufficient data mode", e)
    }
}

export const getInsufficientDataMode = async () => {
    try {
        const jsonValue = await AsyncStorage.getItem(ASYNC_STORAGE_KEYS.SELECTED_INSUFFICIENT_MODE)
        return jsonValue != null ? JSON.parse(jsonValue) : false;
    } catch (e) {
        console.error("error reading insufficient data mode", e)
        return null;
    }
}
