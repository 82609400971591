import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {ValueBoxSecondary, ValueBoxSecondaryInput, ValueBoxMain} from "./styledComponents";
import CreateApp from "./CreateApp";
import _ from "lodash";


import {getString} from "../../utils/translations";
import loader from "../../lotties/loaderhouse.json";
import Popup from "../Popup";
import {generateAPIKey, deleteSDKApp} from "../../api";
import {updateClientApp, deleteClientApp} from "../../redux/organization";
import colors from "../../styles/colors";
import {CLIENT_APP_TYPES} from "../../constants";
import {updateClientAppName} from "../../api/sdk";
const WIZARD_STEPS = {
    SELECT_APP: "SELECT_APP",
    CREATE_APP: "CREATE_APP",
    CONFIGURE_APP: "CONFIGURE_APP",
    DELETE_CONFIRMATION: "REVIEW",
}



export default function ClientAppWizard({isOpen, closeHandler}) {


    const [step, setStep] = useState(WIZARD_STEPS.SELECT_APP);
    const [selectedApp, setSelectedApp] = useState(null);
    const [title, setTitle] = useState("my_apps");
    const [popupDescription, setPopupDescription] = useState("my_apps_popup_description");
    const dispatch = useDispatch();
    const clientApps = useSelector(state => state.organization.clientApps);
    const appLevelUnitsLimitEnabled = useSelector(state => state.organization.currentOrg.appLevelUnitsLimitEnabled);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [footerButtons, setFooterButtons] = useState([{label: getString("close"), onPress: closeHandler}]);

    useEffect(() => {
        switch (step) {
            case WIZARD_STEPS.SELECT_APP:
                setTitle("my_apps");
                setPopupDescription("my_apps_popup_description");
                setFooterButtons([{label: getString("close"), onPress: closeHandler}] )
                break;
            case WIZARD_STEPS.CONFIGURE_APP:
                setTitle("configure_sdk_app");
                setPopupDescription("configure_sdk_app_description");
                setFooterButtons([{label: getString("close"), onPress: closeHandler}] )
                break;
            case WIZARD_STEPS.DELETE_CONFIRMATION:
                setTitle("delete_sdk_app");
                setPopupDescription("delete_sdk_app_description");
                setFooterButtons([{label: getString("delete"), onPress: handleDeleteApp, background: colors.lipstick}, {
                    label: getString("keep"),
                    onPress: () => {
                        setStep(WIZARD_STEPS.CONFIGURE_APP)
                    },
                    background: colors.green
                }] )

                break;
        }
    }, [step]);


    const handleSelectApp = (app) => {
        setSelectedApp(app);
        setStep(WIZARD_STEPS.CONFIGURE_APP);
    };


    const handleUpdateApp = async ({name, unitsLimit}) => {
        await updateClientAppName({ clientAppId: selectedApp.id, name, unitsLimit });
        dispatch(updateClientApp({...selectedApp, name, unitsLimit})); // Assuming you have an action to update an app name
    };

    const handleDeleteApp = async () => {
        try {
            setLoading(true);
            await deleteSDKApp(selectedApp.id);
            setLoading(false);
            dispatch(deleteClientApp(selectedApp.id))
            closeHandler();
        }catch (e) {
            console.error(e)
            setLoading(false);
            setError("failed to delete app")
        }
    };

    let toRender = null;

    switch (step) {
        case WIZARD_STEPS.SELECT_APP:
            toRender = <SelectApp apps={clientApps} onSelectApp={handleSelectApp} onCreateApp={()=>{setStep(WIZARD_STEPS.CREATE_APP)}} />;
            break;
        case WIZARD_STEPS.CREATE_APP:
            return <CreateApp
                isOpen={isOpen}
                closeHandler={closeHandler}
                onCancel={()=>{setStep(WIZARD_STEPS.SELECT_APP)}}
                appLevelUnitsLimitEnabled={appLevelUnitsLimitEnabled}
            />;
        case WIZARD_STEPS.CONFIGURE_APP:
            toRender = <ConfigureApp
                app={selectedApp}
                onUpdateApp={handleUpdateApp}
                onDeleteApp={()=>{
                setStep(WIZARD_STEPS.DELETE_CONFIRMATION)}}
                setLoading={setLoading}
                setError={setError}
                appLevelUnitsLimitEnabled={appLevelUnitsLimitEnabled}
            />;
            break;
        case WIZARD_STEPS.DELETE_CONFIRMATION:
            toRender = <DeleteConfirmation onDeleteApp={handleDeleteApp} />;
            break;
        default:
            toRender = null;
            break;
    }

    return (
        <Popup
            isOpen={isOpen}
            closeHandler={closeHandler}
            title={getString(title)}
            closeOnDocumentClick={step !== WIZARD_STEPS.DELETE_CONFIRMATION}
            description={getString(popupDescription)}
            content={toRender}
            error={error}
            footerButtons={footerButtons}
            loading={loading}
            loader={loader}
            showAll={false}
        />
    )
}


function SelectApp({apps, onSelectApp, onCreateApp}) {

    // filter out the apps that app.clientApp not in the CLIENT_APP_TYPES list
    apps = apps.filter(app => Object.values(CLIENT_APP_TYPES).map(value => value.toLowerCase()).includes(app.appClient.toLowerCase()))
    // filter out apps that deletedAt is not null
    apps = apps.filter(app => {
        return !app.deletedAt
    })
    return (
        <div style={{ maxHeight: 400, overflowY: 'auto' }}>
            {apps.map(app => (
                <ValueBoxMain key={app.id} onClick={() => onSelectApp(app)}>
                    {app.name || app.appClient}
                </ValueBoxMain>
            ))}
            <ValueBoxMain style={{borderColor: colors.calm, borderWidth: 2}} onClick={onCreateApp}>
                {getString("create_app")}
            </ValueBoxMain>
        </div>
    );
}



function ConfigureApp({app, onUpdateApp, onDeleteApp, setError, setLoading, appLevelUnitsLimitEnabled}) {

    const [name, setName] = useState(app.name || app.appClient);
    const [apiKey, setApiKey] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [unitsLimit, setUnitsLimit] = useState(_.get(app, "AIUnitsLimits[0].unitsLimit", null));

    const dispatch = useDispatch();

    const handleUpdateAppName = () => {
        if(!name) {
            setNameError("name is required")
        }else {
            setNameError(null)
            if(name !== app.name) {
                onUpdateApp({name});
            }
        }
    };

    const handleUpdateAppUnitsLimit = () => {
        onUpdateApp({unitsLimit});
    };

    const handleDeleteApp = () => {
        onDeleteApp();
    };

    const generateNewKey = async () =>{
        try {
            setLoading(true);
            setError(null);
            let res = await generateAPIKey({clientAppType: app.appClient, clientAppId: app.id, appName: app.name})
            setLoading(false)

            if (res && res.data) {
               // dispatch(addClientApp(res.data))

                dispatch(updateClientApp(res.data))
                setApiKey(res.data.apiKey)

               // setStep(CREATE_APP_STEPS.REVIEW);
            }
        } catch (e) {
            console.error(e)
            setLoading(false)
            setError("failed to generate api key for app")
        }
    }

    return (
        <div>
            <ValueBoxSecondaryInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={handleUpdateAppName}
            />
            {nameError && <div style={{color: colors.lipstick}}>{nameError}</div>}
            <ValueBoxMain>{getString("app_id")}</ValueBoxMain>
            <ValueBoxSecondary>{app.appId}</ValueBoxSecondary>
            <ValueBoxMain onClick={generateNewKey}>{apiKey ? "API KEY:" : getString("generate_api_key")}</ValueBoxMain>
            <ValueBoxSecondary>{apiKey}</ValueBoxSecondary>
            {appLevelUnitsLimitEnabled && (
                <>
                    <ValueBoxMain>{getString("units_limit")}</ValueBoxMain>
                    <ValueBoxSecondaryInput
                        value={unitsLimit}
                        onChange={(e) => setUnitsLimit(e.target.value)}
                        type={"number"}
                        onBlur={handleUpdateAppUnitsLimit}
                    />
                </>
            )}

            <ValueBoxMain
                style={{borderColor: colors.lipstick}} onClick={handleDeleteApp}>{getString("delete_app_button")}</ValueBoxMain>
        </div>
    );
}

function DeleteConfirmation({onDeleteApp}) {
    return null
}


