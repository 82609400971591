import {SCREENS, TABLE_COLUMNS, COLUMN_VALUE_OPTION, LEVELS} from "../../constants";
import moment from "moment";
import _, {get} from "lodash";
import colors from "../../styles/colors";
import {getColor} from "../../views/Dashboard/TableData/utils";
import {calcSufficientData} from "../table/utils"
import {getString} from "../../utils/translations";

export function normalizeDataPoints(data) {
    // Calculate the center of the circle
    const centerX = 0.5;
    const centerY = 0.5;

    // Calculate the radius of the circle
    const radius = 0.5;


    // Normalize each data point
    const normalizedData = data.map(point => {
        // Calculate the distance from the center of the circle to the data point
        const dx = point.x - centerX;
        const dy = point.y - centerY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        const originalX = (point.x);
        const originalY = (point.y);


        // If the distance is greater than the radius, normalize the point
        if (distance > radius) {
            // Calculate the normalized coordinates
            const normalizedX = dx * radius / distance;
            const normalizedY = dy * radius / distance;

            // Update the data point with the normalized coordinates
            return {
                ...point,
                x: centerX + normalizedX,
                y: centerY + normalizedY,
                originalX,
                originalY
            };
        }

        // If the distance is within the radius, the point is already normalized
        return point;
    });

    return normalizedData;
}

export const normalizePoints = (points, radius) => {
    const normalizedPoints = [];

    // Calculate the center of the square
    const centerX = radius;
    const centerY = radius;

    // Calculate the maximum distance from the center to the circle
    const maxDistance = radius - 2;
    const normalizeOriginal = (original) => {
        //   console.log("normalizeOriginal", original)
        if (original < 0) {
            return 0;
        } else if (original > 1) {
            return 1
        }
        return original
    }
    // Iterate through each point
    for (const point of points) {
        // Convert the relative coordinates to absolute coordinates within the square
        const originalX = normalizeOriginal(point.x);
        const originalY = normalizeOriginal(point.y);

        //     console.log("originalX", originalX)
        //   console.log("originalY", originalY)


        const x = originalX * radius * 2;
        const y = originalY * radius * 2;

        // Calculate the distance from the center to the point
        const distance = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));

        if (distance <= maxDistance) {
            // Point is already inside the circle, no need to normalize
            normalizedPoints.push({...point, x, y, originalX, originalY});
        } else {
            // Point is outside the circle, normalize it
            const normalizedX = centerX + ((x - centerX) * maxDistance) / distance;
            const normalizedY = centerY + ((y - centerY) * maxDistance) / distance;

            normalizedPoints.push({...point, x: normalizedX, y: normalizedY, originalX, originalY});
        }
    }

    return normalizedPoints;
}
export const extractEnergyMoodChartDataFromTable = (data = [], context) => {
    //console.log("extractEnergyMoodChartDataFromTable", context, data)
    let map = [];

    data.forEach((item, index) => {

            let metadataKey = null;
            let metadataFormatter = null;
            let addSuffix = true;

            switch (context) {
                case SCREENS.USERS:
                    metadataKey = "name"
                    break;
                case SCREENS.SESSIONS:
                    metadataKey = "updatedAt"
                    metadataFormatter = (value) => {
                        return moment(value).format("D MMM, h:mm:ss a")
                    }
                    break;
                case SCREENS.CONTENT:
                    metadataKey = "title"
                    addSuffix = false
                    break;
                case SCREENS.VIDEO:
                    metadataKey = "name"
                    addSuffix = false
                    break;
            }

            let energy = normalizeValue(get(item, `energy${addSuffix ? "_ps" : ""}`, 0))
            let mood = normalizeValue(get(item, `valence${addSuffix ? "_ps" : ""}`, 0))
            if (energy < .5 && mood > .55) {
                if (mood > .7) {
                    mood = mood * 1.3
                } else if (mood > .66) {
                    mood = mood * 1.25
                }else if (mood > .63) {
                    mood = mood * 1.19
                } else if (mood > .6) {
                    mood = mood * 1.08
                }else if (mood > .58) {
                    mood = mood * 1.03
                } /*else if (mood > .55) {
                    mood = mood * 1.105
                }else if (mood > .53) {
                    mood = mood * 1.108
                } else if (mood > .51) {
                    mood = mood * 1.101
                }else if (mood > .508) {
                    mood = mood * 1.09
                } else if (mood > .505) {
                    mood = mood * 1.103
                }else if (mood > .503) {
                    mood = mood * 1.101
                } else if (mood > .501) {
                    mood = mood * 1.08
                } else {
                    mood = mood * 1.03
                }*/
            }

            if (mood && energy || mood === 0 && energy || mood && energy === 0) {
                let mapped = {
                    x: mood,
                    y: energy,
                }

                if (item.sessionId) {
                    mapped["sessionId"] = item.sessionId
                }

                if (metadataKey) {
                    mapped["metadata"] = get(item, metadataKey, index + 1)
                    mapped["metadataFormatter"] = metadataFormatter;
                }

                map.push(mapped)
            }
        }
    )

    return map;
}

export const normalizedData = (data) => {
    if (get(data, "wellbeing", null)) {
        data.wellbeing = normalizeValue(data.wellbeing)
    }
    if (get(data, "valence", null)) {
        data.valence = normalizeValue(data.valence)
    }
    return data
}

const normalizeValue = (value) => {
    if (!value || value < 0) {
        return 0;
    } else if (value > 1) {
        return 1;
    }
    return value
}

export const extractPieChartDataFromTable = (data = [], context, key) => {

    let highColor, lowColor, meterValues
    switch (context) {
        case TABLE_COLUMNS.STRESS:
            highColor = colors.tomato
            lowColor = colors.interest
            meterValues = calcMeterValues(data, context, highColor)
            break;
        default:
            highColor = colors.interest
            lowColor = colors.tomato
            meterValues = calcMeterValues(data, context, highColor, key)
            break;
    }

    const result = []
    const {high, medium, low, none} = meterValues

    if (high.value) {
        result.push({name: LEVELS.HIGH, value: high.percent, color: highColor, total: high.value})
    }
    if (medium.value) {
        result.push({name: LEVELS.MEDIUM, value: medium.percent, color: colors.mild, total: medium.value})
    }
    if (low.value) {
        result.push({name: LEVELS.LOW, value: low.percent, color: lowColor, total: low.value})
    }
    if (none.value) {
        result.push({name: LEVELS.NO, value: none.percent, color: colors.warm_grey, total: none.value})
    }

    return result
}


export const calcMeterValues = (data, context, highColor, key) => {
    let high = 0, medium = 0, low = 0, none = 0, sum = 0

    data.map(d => {
        let sufficient = calcSufficientData(d, COLUMN_VALUE_OPTION.METER)
        if (sufficient) {
            sum++

            let value = context === TABLE_COLUMNS.MOOD ? key ? d[key] : d["valence_ps"] : get(d, context, 0)
            // bypass energy color for calculation - this is very bad code. need to redo this whole function to calculate based on percent and not on color
            const contextColor = context === "energy" ? TABLE_COLUMNS.MOOD : context
            let color = getColor(contextColor, value, true)


            switch (color) {
                case colors.tomato:
                    color === highColor ? high++ : low++
                    break;
                case colors.interest:
                    color === highColor ? high++ : low++
                    break;
                case colors.mild:
                    medium++
                    break;
                case colors.warm_grey:
                    none++
                    break;
            }
        }
    })

    const highPercentage = calcPercent(sum, high)
    const mediumPercentage = calcPercent(sum, medium)
    const lowPercentage = calcPercent(sum, low)
    const nonePercentage = calcPercent(sum, none)


    return {
        high: {value: high, percent: highPercentage},
        medium: {value: medium, percent: mediumPercentage},
        low: {value: low, percent: lowPercentage},
        none: {value: none, percent: nonePercentage}
    }
}

export const calcModulesAVG = (data, moodKey = "valence_ps") => {

    let wellbeing = []
    let stress = []
    let mood = []
    let energy = []
    data.map(d => {
        let sufficient = calcSufficientData(d, COLUMN_VALUE_OPTION.METER)
        if (sufficient) {
            wellbeing.push(get(d, "wellbeing", 0))
            stress.push(get(d, "stress", 0))
            mood.push(get(d, moodKey, 0))
            energy.push(get(d, "energy", 0))
        }
    })

    const wellbeingAVG = _.mean(wellbeing)
    const stressAVG = _.mean(stress)
    const moodAVG = _.mean(mood)
    const energyAVG = _.mean(energy)

    return {
        wellbeing: wellbeingAVG,
        stress: stressAVG,
        mood: moodAVG,
        energy: energyAVG
    }
}

const calcPercent = (sum, value) => {
    if (!sum || !value) {
        return 0
    }
    return Math.round((value / sum) * 100)
}
