import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopUp from 'reactjs-popup';

import 'reactjs-popup/dist/index.css';

import styled from "styled-components";
import colors from "../styles/colors";
import Lottie from 'react-lottie';

import _, { get, findIndex } from "lodash";
import { getAsset } from "../utils"
import { getString } from "../utils/translations"
import { Fragment } from 'react';
import { STATUS } from '../constants';

import Video from "../components/Video"
import Button from "../components/Button";

import {exportButtonStyle} from "../styles/layout";
import {ExportText} from "../styles/typography";
import EditableField from "./EditableField";

class VideoPreviewPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleCloseButton = () => {
        this.props.closeHandler()
    }

    renderHeader = () => {
        const { name, exportClickHandler, deleteClickHandler, editName, renderRowNameInput } = this.props
        return (<Header>
            <ImgButton src={getAsset("icons/xIcon.svg")} onClick={this.handleCloseButton} width={25} />
            <Title>
                <VideoNameContainer>
                    <EditableField
                        value={name}
                        renderInput={renderRowNameInput}
                        onSave={(value) => {
                            editName(value)
                        }}
                        showButtons={true}/>
                    <img src={getAsset("icons/edit_icon.svg")} style={{margin: 5}}/>
                </VideoNameContainer>
                {}</Title>
            <div style={{display: "flex", flexDirection: "row", zIndex: 5}}>
                <Button
                    style={{...exportButtonStyle, backgroundColor: colors.black, marginRight: 10}}
                    onClick={deleteClickHandler}>
                    <ExportText>{getString("delete")}</ExportText>
                </Button>
                <Button
                    style={{...exportButtonStyle, backgroundColor: colors.black, marginRight: 10}}
                    onClick={()=>deleteClickHandler(true)}>
                    <ExportText>{getString("delete_file")}</ExportText>
                </Button>
            <Button
                    style={exportButtonStyle}
                    onClick={exportClickHandler}>
                    <ExportText>{getString("export")}</ExportText>
                </Button>


            </div>
        </Header>)
    }

    renderBody = (height) => {
        return (<Body>
            <Video
                videoUrl={this.props.videoFile}
                overTimeChart={true}
                height={height}
                previewMode={true}
                detectionInterval={200}
            />
        </Body>)
    }

    render() {

        const height = window.screen.height * 77 / 100
        const width = height * 1.15

        const { isOpen, closeHandler, closeOnDocumentClick, } = this.props

        console.log("VideoPreviewPopup", this.props)

        return (
            <PopUp
                position="center"
                closeOnDocumentClick={closeOnDocumentClick}
                open={isOpen}
                onClose={closeHandler}
                contentStyle={{
                    width,
                    height,
                    padding: 0,
                    borderRadius: 9,
                    boxShadow: "0px 1.13559px 14.1949px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {this.renderHeader()}

                {this.renderBody(height - headerHeight)}

            </PopUp>
        );
    }
}


export default VideoPreviewPopup;

const Title = styled.div`
            font-family: Open Sans;
            font-weight: 900;
            font-size: 27px;
            color: #536EEC;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.3px;
            position: absolute;
            width: 100%;
            height: 100%;
          
            z-index: 1;
            align-items: center;
            justify-content: center;
                display: flex;
            flex-direction: row;
            `

let headerHeight = 60;
const Header = styled.div `
            height: ${headerHeight}px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 7px;
            padding-right: 7px;
            position: relative;
            `

const Body = styled.div`
            flex: 1;
            display: flex;
            flex-direction: column;
            `

const ImgButton = styled.img`
    cursor: pointer;
    z-index: 5;
`

const VideoNameContainer = styled.div`
            display: flex;
            flex-direction: row;
      
            `
