
import colors from "./colors";

export const exportButtonStyle = {
    background: colors.blue,
    padding: 15,
    height: 40,
    borderRadius: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default {

}
