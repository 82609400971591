import React, { Component } from 'react';
import styled from 'styled-components'
import { getAsset } from "../../../utils";
import _ from 'lodash'
import { EMOTION_PROGRESS_PERCENT } from "../../../api/remoteConfig"

class ProgressBar extends Component {

    progressLimit = (min, value, max) => {
        return Math.min(Math.max(min, value), max);
    }

    render() {
        let { progressColor, percentage, change, width, backgroundColor, label, height, reverseProgress } = this.props

        height = height ? height : width ? width / 6 : null

        let progressValue = percentage
        if (reverseProgress && percentage < 60) {
            progressValue = 100 - percentage
        }

        return (
            <Tracker style={{ display: 'flex', justifyContent: reverseProgress ? 'flex-end' : 'flex-start' }}
                height={height ? height : 27} color={backgroundColor ? backgroundColor : '#D7D7D7'} width={width ? width : 83}>
                <ProgressInTracker
                    progress={`${(this.progressLimit(0, progressValue, 100) / 100) * 100}%`}
                    color={progressColor}
                />

                {label && <LabelContainer>
                    <Value>{label}</Value>
                </LabelContainer>}

                <ValueContainer>
                    {change ? change > 0 ? <Trend src={percentage > EMOTION_PROGRESS_PERCENT ? getAsset("icons/vertical_trend_up_white.svg") : getAsset("icons/vertical_trend_up.svg")} /> :
                        <Trend src={percentage > EMOTION_PROGRESS_PERCENT ? getAsset("icons/vertical_trend_down_white.svg") : getAsset("icons/vertical_trend_down.svg")} /> : null}
                    <Value color={reverseProgress ? "#FFFFFF" : percentage > EMOTION_PROGRESS_PERCENT ? "#FFFFFF" : "#2E384D"}>{percentage}</Value>
                </ValueContainer>
            </Tracker >
        );
    }
}

const Tracker = styled.div`
    display: flex;
    background: ${props => props.color};
    height: ${props => props.height}px;
    width: ${props => props.width}px;
    position: relative;
`

const LabelContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    top: 0;
    margin-left: 5px;
    `

const ProgressInTracker = styled.div`
    width: ${props => props.progress};
    height: 27px;
    background: ${props => props.color};
    transition: width 0.05s;
`

const ValueContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 5px;
    `

const Value = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 183% */

    text-align: center;

    color: ${props => props.color};
`

const Trend = styled.img`
  width: 11px;
  height: 11px;
`

export default ProgressBar;
