import { buildFeatureList } from "./featurs";

export const features = buildFeatureList({
    tags: true,
    exportList: true,
    contentLayer: true,
    generateApiKey: true,
    showType: false,
    showArtist: false,
    hasBaseline: false,
    exportContentTable: true,
    billingScreen: true,
    groupManagement: true,
})
